import React, { SVGProps } from 'react';
type Props = {} & SVGProps<SVGSVGElement>;
export default function PlusIcon(props: Props) {
    return (
        <svg
            className="svg-icon-fill svg-icon-stroke"
            xmlns="http://www.w3.org/2000/svg"
            fill={'currentColor'}
            width="24"
            height="24"
            viewBox="0 0 24 24"
        >
            <path d="M24 10h-10v-10h-4v10h-10v4h10v10h4v-10h10z" />
        </svg>
    );
}
