import { Box } from '@mui/material';

import { LocationWithZones } from '../../../../models/scope/Location';
import VLSelectLocationOfCompany from '../VLSelectLocationOfCompany';

type Props = {
    currentLocation?: LocationWithZones | null;
    allLocation: LocationWithZones[];
    handleAddLocation: (location: LocationWithZones) => void;
    disabled?: boolean;
};

export default function LocationAndZoneSelectItemFake({
    currentLocation,
    allLocation,
    handleAddLocation,
    disabled,
}: Props) {
    return (
        <Box minWidth={'400px'}>
            <VLSelectLocationOfCompany
                locations={allLocation}
                handleChange={(e) => {
                    handleAddLocation(e!);
                }}
                label={'Select location'}
                disabled={disabled}
            />
        </Box>
    );
}
