/* eslint-disable react-hooks/exhaustive-deps */
import { InteractionStatus } from '@azure/msal-browser';
import { AuthenticatedTemplate, UnauthenticatedTemplate, useMsal } from '@azure/msal-react';
import { Box, Stack } from '@mui/material';
import React, { createContext, ReactNode, useEffect, useLayoutEffect } from 'react';
import { Outlet } from 'react-router-dom';
import { loginRequest, msalConfig } from '../auth/authConfig';

import { pushError } from '../components/toast';
import { axiosClient } from '../controllers';
import LoadingScreen from '../pages/loading/LoadingScreen';
import { ServiceProvider } from '../providers/ServiceProvider';

export enum AuthPermission {
    READ_ONLY = 1,
    EDITABLE = 2,
}
export const AuthContext = createContext<{ permission: AuthPermission }>({ permission: AuthPermission.READ_ONLY });

function ProtectedChild() {
    const { instance, inProgress, accounts } = useMsal();

    const getToken = async () => {
        try {
            const accessTokenRequest = {
                scopes: [`${msalConfig.auth.clientId}/User.Read`],
                account: accounts[0],
            };

            const accessTokenResponse = await instance.acquireTokenSilent(accessTokenRequest);
            const _accessToken = accessTokenResponse.accessToken;
            return _accessToken;
        } catch (error: any) {
            console.error(error);
            return Promise.reject(error);
        }
    };

    useLayoutEffect(() => {
        axiosClient.interceptors.request.use(
            async (config) => {
                const token = await getToken();
                config.headers!['Authorization'] = 'Bearer ' + token;
                return config;
            },
            (error) => {
                return Promise.reject(error);
            }
        );

        axiosClient.interceptors.response.use(
            (response) => response,
            (error) => {
                if (error?.response?.status === 401) {
                    console.error('_error', error.response);
                    // instance.acquireTokenRedirect(loginRequest);
                } else {
                    console.error('_error', error.response);
                    pushError(
                        typeof error?.response?.data === 'string'
                            ? error?.response?.data
                            : error?.response?.data?.message || error.message || 'Server error!'
                    );
                    return Promise.reject(error);
                }
            }
        );
    }, []);

    if (inProgress !== InteractionStatus.None) {
        return <LoadingScreen isLoading={true} />;
    }

    return (
        <Stack direction={'column'} minHeight={'calc(100vh - 63px)'} width={'calc(100vW - 8px)'} className="App">
            {/* <IAppBar /> */}
            <ServiceProvider>
                <Outlet />
            </ServiceProvider>
        </Stack>
    );
}

export function UnAuth() {
    const { instance } = useMsal();

    useEffect(() => {
        instance.loginRedirect(loginRequest).catch((e) => {
            console.error(e);
        });
    }, []);

    return <LoadingScreen isLoading={true} />;
}

function AuthLayout(props: Props) {
    return (
        <Box sx={{ overflowX: 'hidden', pt: 0 }}>
            <AuthenticatedTemplate>
                <AuthContext.Provider value={{ permission: AuthPermission.EDITABLE }}>
                    <ProtectedChild />
                </AuthContext.Provider>
            </AuthenticatedTemplate>
            <UnauthenticatedTemplate>
                <UnAuth />
            </UnauthenticatedTemplate>
            {/* <Stack
                direction={'column'}
                minHeight={'calc(100vh - 63px)'}
                width={'calc(100vW - 8px)'}
                className="App"
                pt={5}
            >
                <IAppBar />
                <Outlet />
            </Stack> */}
        </Box>
    );
}

export default React.memo(AuthLayout);

interface Props {
    children?: ReactNode;
}

export function AuthLayoutWithChildren(props: Props & { jwt: string; permission: AuthPermission }) {
    axiosClient.defaults.headers['Authorization'] = props.jwt;

    return (
        <Box sx={{ pt: 0 }}>
            <AuthContext.Provider value={{ permission: props.permission || AuthPermission.READ_ONLY }}>
                {props.children}
            </AuthContext.Provider>
        </Box>
    );
}
