import { useState } from 'react';
import { SearchOption } from '../components/model/UpsertVehicleList';

export default function useVehicleListSearchOption() {
    const [searchOption, setSearchOption] = useState<SearchOption>({
        searchType: 'company',
        searchCompany: null,
        searchLocation: null,
        searchPortfolio: null,
    });
    const setPartialSearchOption = (partial: Partial<SearchOption>) =>
        setSearchOption((prev) => ({ ...prev, ...partial }));
    return { searchOption, setPartialSearchOption };
}
