import moment from "moment"
import { Company } from "../../../models/scope/Company"
import { Portfolio } from "../../../models/scope/Portfolio"
import { UnionScope } from "../components/model/UpsertVehicleList"
import { IPARK_PERMIT_TIME_REGEX } from "../constant"

export const DESCRIPTION = 'description'
export const getTimeFromRegex = (dateHours: string) => {
    if (!dateHours.match(IPARK_PERMIT_TIME_REGEX)) return 0

    if (!isNaN(Number(dateHours))) {
        return Number(dateHours) * 60 * 60
    }

    if (dateHours.includes("d") && dateHours.includes("h")) {
        const indexD = dateHours.indexOf("d")
        const days = Number(dateHours.substring(0, indexD))
        const stringHours = dateHours.slice(indexD + 1, dateHours.length - 1)
        const hours = Number(stringHours)
        return days * 24 * 60 * 60 + hours * 60 * 60
    }

    if (dateHours.includes("d")) {
        const indexD = dateHours.indexOf("d")
        const stringHours = dateHours.slice(indexD + 1, dateHours.length)
        const hours = (Number(stringHours))
        const days = Number(dateHours.substring(0, indexD))
        return days * 24 * 60 * 60 + (hours * 60 * 60)
    }

    if (dateHours.includes("h")) {
        const indexD = dateHours.indexOf("h")
        const hours = Number(dateHours.substring(0, indexD))
        return hours * 60 * 60
    }
}

export const parseTimeToRegexString = (second: number) => {
    const days = Math.floor(second / (24 * 60 * 60))
    const hours = Math.floor((second - (24 * 60 * 60 * days)) / (60 * 60))

    if (days > 0 && hours > 0) return days + "d " + hours + "h"
    if (days <= 0) return hours + "h"
    if (hours <= 0) return days + "d"
    return ""
}

export const secondsToDate = (seconds: number) => {
    return Math.floor(seconds / (24 * 60 * 60))
}

export const genWeekDate = (date: Date) => {
    const rs = Array.from(new Array(7)).map((a, index) => {
        return moment(date).add(index, 'day').toDate();
    });

    return rs;
}

export const isPortfolio = (union: UnionScope): union is Portfolio => {
    return (union as Portfolio).description !== undefined
}

export const isCompany = (union: UnionScope): union is Company => {
    return (union as Company).displayName !== undefined
}