import * as React from 'react';
import TextField from '@mui/material/TextField';
import Autocomplete, { autocompleteClasses } from '@mui/material/Autocomplete';
import { Box, styled } from '@mui/material';
import { LocationWithZones } from '../../../models/scope/Location';
import color from '../../../config/Colors';
import SearchIcon from '../../../components/svg/SearchIcon';

type Props = {
    locations: LocationWithZones[];
    value?: LocationWithZones | null;
    handleChange: (val: LocationWithZones | null) => void;
    label: string;
    disabled?: boolean;
};

export default function VLSelectLocationOfCompany({ locations, handleChange, value, label, disabled }: Props) {
    const [text, setText] = React.useState<string>('');
    return (
        <StyledBox
            sx={{
                '& .MuiOutlinedInput-root': {
                    padding: '5px 55px 5px 10px !important',
                },
            }}
        >
            <Autocomplete
                disablePortal
                id="combo-box-demo"
                options={locations}
                value={null}
                inputValue={text}
                getOptionLabel={(option) => option.name}
                onChange={(e, val) => {
                    handleChange(val);
                    setText('');
                }}
                isOptionEqualToValue={(option, value) => option.id === value.id}
                renderInput={(params) => {
                    return (
                        <TextField
                            {...params}
                            label=""
                            InputLabelProps={{ shrink: true }}
                            onChange={(e) => setText(e.target.value)}
                            placeholder="Please enter location name and select to add"
                        />
                    );
                }}
                size={'small'}
                disabled={disabled}
                popupIcon={<SearchIcon />}
                sx={{
                    [`& .${autocompleteClasses.popupIndicator}`]: {
                        transform: 'none',
                    },
                }}
            />
        </StyledBox>
    );
}

const StyledBox = styled(Box)({
    '& #combo-box-demo-listbox': {
        padding: '5px',
    },
    '& .MuiAutocomplete-listbox': {
        padding: '5px',
    },
    '& .MuiAutocomplete-option': {
        minHeight: 'auto !important',
        mb: '2px',
        borderBottom: `1px solid ${color.grey200}`,
        borderRadius: '4px',
    },
    //css first item chosen
    '& .MuiAutocomplete-groupLabel': {
        fontSize: '14px',
        lineHeight: 'normal',
        padding: '10px 20px',
        color: 'black',
        // fontWeight: 600,
        background: '#E5F2F8',
        my: '2px',
    },
    '& .MuiOutlinedInput-root': {
        padding: '5px 55px 5px 10px !important',
    },
    width: '100%',
});

// Top 100 films as rated by IMDb users. http://www.imdb.com/chart/top
