/* eslint-disable array-callback-return */
/* eslint-disable react-hooks/exhaustive-deps */
/* eslint-disable eqeqeq */
import { isAfter } from 'date-fns';
import _ from 'lodash';
import moment from 'moment';
import { useEffect, useState } from 'react';
import { UseFormReturn } from 'react-hook-form';
import { ParentProps } from '..';
import { vehicleListController } from '../../../controllers';
import { convertHour } from '../../../helpers/ConvertHour';
import { Permit, PermitType } from '../../../models/permit/Permit';
import { RecurringType } from '../../../models/permit/PermitRecurring';
import { PermitRecurringDay } from '../../../models/permit/PermitRecurringDay';
import { Scope, ScopeType } from '../../../models/permit/Scope';
import { Company } from '../../../models/scope/Company';
import { Location, LocationWithZones } from '../../../models/scope/Location';
import { Portfolio } from '../../../models/scope/Portfolio';
import { Tenant } from '../../../models/scope/Tenant';
import Zone from '../../../models/scope/Zone';
import { VehicleList, VehicleListWithZone } from '../../../models/vehicleList/VehicleList';
import {
    DataState,
    FormData,
    InternalPermitData,
    IParkData,
    SelectionState,
    UnionScope,
} from '../components/model/UpsertVehicleList';
import { COMPANY_SCOPE_ALL, COMPANY_SCOPE_CUSTOM } from '../components/permitScope/PermitScopeItem';
import {
    ALL_DAY_SECONDS,
    defaultFormDataInternal,
    defaultFormDataIPark,
    LOCATION_SCOPE_ALL,
    LOCATION_SCOPE_CUSTOM,
    REGIS_TYPE_FIXED_TIME,
    REGIS_TYPE_SPECIFIC_DURATION,
    SERVICE_TYPE_INTERNAL_PERMIT,
    SERVICE_TYPE_IPARK,
    TENANT_SCOPE_ALL,
    TENANT_SCOPE_CUSTOM,
} from '../constant';
import { isCompany, parseTimeToRegexString, secondsToDate } from '../helper';
import { LoadingState } from './useVehicleListGetData';
import { ScopeState } from './useVehicleListUpsert';

type Props = {
    methods: UseFormReturn<FormData, any>;
    listCompanySelect: UnionScope[];
    setPartialState: (partial: Partial<ScopeState>) => void;
    companies: Company[];
    locations: LocationWithZones[];
    companySelects: UnionScope[];
    setPartialSelectionState: (partial: Partial<SelectionState>) => void;
    dataState: DataState;
    loading: LoadingState;
    setPartialDataState: (partial: Partial<DataState>) => void;
    isEdit: boolean;
    parentProps: ParentProps;
    portfolios: Portfolio[];
};

export default function useVehicleListEdit({
    methods,
    companies,
    locations,
    companySelects,
    listCompanySelect,
    dataState,
    loading,
    setPartialState,
    setPartialSelectionState,
    setPartialDataState,
    isEdit,
    parentProps,
    portfolios,
}: Props) {
    const [isLoadingEditingData, setIsLoadingEditingData] = useState<boolean>(isEdit ? true : false);
    const [tempState, setTempState] = useState<TempState>({
        allCompany: [],
        allLocation: [],
        selectedCompany: [],
        selectedCompanyFull: [],
        scopes: [],
        allPortfolio: [],
    });
    const { vehicleListId } = parentProps;
    const [vehicleListEdit, setVehicleListEdit] = useState<VehicleListWithZone>({} as VehicleListWithZone);
    const [permit, setPermit] = useState<Permit>({} as Permit);

    const setPartialTemp = (partial: Partial<TempState>) => setTempState((prev) => ({ ...prev, ...partial }));

    // console.log(tempState);

    useEffect(() => {
        if (vehicleListId && !loading.companies && !loading.locations) {
            setPartialTemp({
                allCompany: _.cloneDeep(companies),
                allLocation: _.cloneDeep(locations),
                selectedCompanyFull: _.cloneDeep(listCompanySelect),
                selectedCompany: _.cloneDeep(companySelects),
                allPortfolio: _.cloneDeep(portfolios),
            });
            getVehicleListInfo(Number(vehicleListId)).then((form) => {
                methods.setValue('iParkData', form.iParkData, { shouldValidate: true });
                methods.setValue('internalPermitData', form.internalPermitData, { shouldValidate: true });
                methods.setValue('nameVehicleList', form.nameVehicleList, { shouldValidate: true });
                methods.setValue('scopeType', form.scopeType, { shouldValidate: true });
                methods.setValue('serviceType', form.serviceType, { shouldValidate: true });
                methods.setValue('comment', form.comment);
                methods.setValue('isAllowTenant', form.isAllowTenant);
            });
        }
    }, [vehicleListId, loading]);

    useEffect(() => {
        if (tempState.scopes.length > 0) {
            getPermitScope(tempState.scopes);
            handleRemoveExistsCompanyAndLocation();
            removeScopePartial();
        }
    }, [tempState.scopes]);

    // useEffect(() => {
    //     if (locationId && listCompanySelect.length > 0 && tempState.scopes.length > 0) {
    //         getPermitScopeLocationVL();
    //         handleRemoveExistsCompanyAndLocation();
    //     }
    // }, [locationId, listCompanySelect, tempState.scopes]);

    const handleRemoveExistsCompanyAndLocation = () => {
        const clCompanySelect = _.cloneDeep(tempState.selectedCompany);
        const clAllCompany = _.cloneDeep(companies).filter((c) => !clCompanySelect.some((cl) => cl.id === c.id));

        const allLocationSelect: LocationWithZones[] = [];
        clCompanySelect.map((cl) => {
            const item = cl;
            if (isCompany(item)) {
                if (item.locations) item.locations.map((l) => allLocationSelect.push(l));
            }
        });

        const clAllLocation = _.cloneDeep(locations).filter((l) => !allLocationSelect.some((cl) => cl.id === l.id));

        setPartialDataState({
            companies: clAllCompany,
            locations: clAllLocation,
        });
    };

    const getVehicleListInfo = async (id: number) => {
        const vehicleList = await vehicleListController.getById(id);
        setVehicleListEdit(vehicleList);

        const data: FormData = {
            nameVehicleList: vehicleList.name,
            serviceType: vehicleList.serviceId,
            scopeType: isGlobalScope(vehicleList) ? COMPANY_SCOPE_CUSTOM : COMPANY_SCOPE_ALL,
            internalPermitData: getInternalPermitData(vehicleList),
            iParkData: getIParkData(vehicleList),
            comment: vehicleList.comment || '',
            isAllowTenant: vehicleList.isAllowTenant ?? false,
        };
        const permit = vehicleList.permits![0];
        setPermit(permit);
        setPartialTemp({ scopes: permit.scopes });
        return data;
    };

    const getPermitScope = (scopes: Scope[]) => {
        scopes.forEach((scope) => {
            switch (scope.scopeType) {
                case ScopeType.portfolio:
                    addPortfolioScope(scope.scopeId!);
                    break;
                case ScopeType.company:
                    addAndRemoveCompanyLocationZone(scope.scopeId!);
                    break;
                case ScopeType.location:
                    addLocation(scope.scopeId!);
                    break;
                case ScopeType.zone:
                    addZone(scope.scopeId!);
                    break;
                case ScopeType.tenant:
                    addTenant(scope.scopeId!);
                    break;
                default:
                    break;
            }
        });

        // setPartialDataState({ companies: tempState.allCompany, locations: tempState.allLocation });
        setPartialState({ listCompanySelect: tempState.selectedCompanyFull });
        setPartialSelectionState({ companySelects: tempState.selectedCompany });
        setIsLoadingEditingData(false);
    };

    const addLocation = (idLocation: number) => {
        const location = locations.find((i) => i.id === idLocation)!;
        addAndRemoveCompanyLocationZone(location?.companyId!, location);
    };

    const addZone = (idZone: number) => {
        let idCompany = 0;
        let location: LocationWithZones = {} as LocationWithZones;
        let zone: Zone = {} as Zone;
        companies.forEach((c) => {
            c.locations!.forEach((l) => {
                l.zones!.forEach((z) => {
                    if (z.id === idZone) {
                        idCompany = c.id;
                        location = { ...l, shops: [], tenantScope: TENANT_SCOPE_ALL };
                        zone = z;
                    }
                });
            });
        });
        addAndRemoveCompanyLocationZone(idCompany, location, zone, undefined);
    };

    const addTenant = (idTenant: number) => {
        let idCompany = 0;
        let location: LocationWithZones = {} as LocationWithZones;
        let tenant: Tenant = {} as Tenant;
        companies.forEach((c) => {
            c.locations!.forEach((l) => {
                l.shops?.forEach((s) => {
                    if (s.clientId === idTenant) {
                        idCompany = c.id;
                        location = l;
                        tenant = s;
                    }
                });
            });
        });
        addAndRemoveCompanyLocationZone(idCompany, location, undefined, tenant);
    };

    const addPortfolioScope = (portfolioId: number) => {
        const clSelectedCompany = tempState.selectedCompany;
        const clSelectedCompanyFull = tempState.selectedCompanyFull;
        const itemComp = _.cloneDeep(portfolios).find((item) => item.id === portfolioId)!;
        clSelectedCompanyFull.push(itemComp);
        clSelectedCompany.push(itemComp);
        setPartialTemp({
            selectedCompanyFull: clSelectedCompanyFull,
            selectedCompany: clSelectedCompany,
        });
    };

    const removeScopePartial = () => {
        const clPortfolios = _.cloneDeep(portfolios);
        const portfolioScopes = _.cloneDeep(tempState.scopes).filter((i) => i.scopeType === ScopeType.portfolio);
        const removeList = clPortfolios.filter((l) => !portfolioScopes.some((cl) => cl.scopeId === l.id));
        setPartialDataState({ portfolios: removeList });
    };

    const addAndRemoveCompanyLocationZone = (
        idCompany: number,
        location?: LocationWithZones,
        zone?: Zone,
        tenant?: Tenant
    ) => {
        const clSelectedCompany = tempState.selectedCompany;
        const clSelectedCompanyFull = tempState.selectedCompanyFull;
        const itemComp = tempState.allCompany.find((item) => item.id === idCompany)!;
        //Zone add
        if (zone || tenant) {
            const indexCompanyExist = clSelectedCompany.findIndex((i) => i.id === idCompany);
            //company exists
            if (indexCompanyExist >= 0) {
                const union = clSelectedCompany[indexCompanyExist];
                //check union company
                if (isCompany(union)) {
                    const indexLocationExists = union.locations?.findIndex((l) => l.id === location!.id)!;
                    //location Exists
                    if (indexLocationExists >= 0) {
                        const zones = _.cloneDeep(union.locations![indexLocationExists].zones!);
                        const tenants = _.cloneDeep(union.locations![indexLocationExists].shops!);
                        if (zone) {
                            zones.push(zone);
                            union.locations![indexLocationExists].zones! = zones;
                        }
                        if (tenant) {
                            const isTenantExists = tenants.find((t) => t.clientId === tenant.clientId);
                            if (!!isTenantExists === false) {
                                tenants.push(tenant);
                                union.locations![indexLocationExists].shops! = tenants;
                                union.locations![indexLocationExists].tenantScope! = TENANT_SCOPE_CUSTOM;
                            }
                        }
                        //company Exists but location is not
                    } else {
                        if (zone) {
                            union.locations!.push({
                                ...location!,
                                zones: [zone!],
                                locationScope: LOCATION_SCOPE_CUSTOM,
                                tenantScope: TENANT_SCOPE_ALL,
                            });
                        }
                        if (tenant) {
                            union.locations!.push({
                                ...location!,
                                shops: [tenant!],
                                tenantScope: TENANT_SCOPE_CUSTOM,
                                locationScope: LOCATION_SCOPE_ALL,
                            });
                        }
                    }
                    //new company
                }
            } else {
                clSelectedCompanyFull.push(itemComp);

                zone &&
                    clSelectedCompany.push({
                        ...itemComp,
                        locations: [{ ...location!, zones: [zone!], locationScope: LOCATION_SCOPE_CUSTOM }],
                        companyScope: COMPANY_SCOPE_CUSTOM,
                    });

                tenant &&
                    clSelectedCompany.push({
                        ...itemComp,
                        locations: [
                            {
                                ...location!,
                                shops: [tenant!],
                                tenantScope: TENANT_SCOPE_CUSTOM,
                                locationScope: LOCATION_SCOPE_ALL,
                            },
                        ],
                        companyScope: COMPANY_SCOPE_CUSTOM,
                    });
            }
        } else {
            if (location) {
                const isCompanyExist = clSelectedCompany.findIndex((i) => i.id === location.companyId);
                if (isCompanyExist >= 0) {
                    const locationCl = clSelectedCompany[isCompanyExist];
                    if (isCompany(locationCl)) {
                        const isLocationExists = locationCl.locations!.findIndex((l) => l.id === location.id);
                        if (isLocationExists === -1) {
                            const arr = _.cloneDeep(clSelectedCompany[isCompanyExist]);
                            if (isCompany(arr)) {
                                arr.locations!.push({
                                    ...location,
                                    zones: [],
                                    locationScope: LOCATION_SCOPE_ALL,
                                    shops: [],
                                    tenantScope: TENANT_SCOPE_ALL,
                                });
                                clSelectedCompany[isCompanyExist] = arr;
                            }
                        }
                    }
                } else {
                    //add to Company
                    clSelectedCompanyFull.push(itemComp);
                    clSelectedCompany.push({
                        ...itemComp,
                        locations: [
                            {
                                ...location,
                                zones: [],
                                locationScope: LOCATION_SCOPE_ALL,
                                shops: [],
                                tenantScope: TENANT_SCOPE_ALL,
                            },
                        ],
                        companyScope: COMPANY_SCOPE_CUSTOM,
                    });
                }
                //company
            } else {
                clSelectedCompanyFull.push(itemComp);
                clSelectedCompany.push({ ...itemComp, locations: [], companyScope: COMPANY_SCOPE_ALL });
            }
        }

        setPartialTemp({
            selectedCompanyFull: clSelectedCompanyFull,
            selectedCompany: clSelectedCompany,
        });
    };

    const isGlobalScope = (vehicleList: VehicleList) => {
        const permit = vehicleList.permits![0];
        if (permit.scopes.length === 1) {
            if (permit.scopes[0].scopeType == ScopeType.global) return true;
        }
        return false;
    };

    const getInternalPermitData = (vehicleList: VehicleList) => {
        const permit = vehicleList.permits![0];
        const permitRecurring = permit.permitRecurring![0];

        if (vehicleList.serviceId === SERVICE_TYPE_INTERNAL_PERMIT) {
            const internalData: InternalPermitData = {
                permitType: permit.permitType,
                endStatus: permit.validateToType!,
                validateFrom: permit.validateFrom!,
                validateTo: permit.validateTo!,
                fromHour: convertHour.getHoursAndMinutesFromTimeNumber(permitRecurring.fromHour),
                toHour: convertHour.getHoursAndMinutesFromTimeNumber(permitRecurring.toHour),
                recurringType: Number(permitRecurring.recurringType),
                recurringEvery: permitRecurring.recurringEvery,
                weekRecurringDays:
                    permitRecurring.recurringType === RecurringType.week
                        ? getWeeksDay(permitRecurring.permitRecurringDays!)
                        : [],
                endAfterValue: permit.validateToAfterRecurringTime!,
                isCheckedAllDay: getIsCheckedAllDay(permit),
            };
            return internalData;
        }

        return defaultFormDataInternal;
    };

    const getIsCheckedAllDay = (permit: Permit) => {
        if (
            permit.permitRecurring &&
            permit.permitRecurring[0] &&
            permit.permitRecurring[0].fromHour == 0 &&
            permit.permitRecurring[0].toHour == ALL_DAY_SECONDS
        ) {
            return true;
        } else {
            return false;
        }
    };

    const getIParkData = (vehicleList: VehicleList) => {
        const permit = vehicleList.permits![0];
        if (vehicleList.serviceId == SERVICE_TYPE_IPARK) {
            const iparkData: IParkData = {
                fixedTime: parseTimeToRegexString(permit.duration!),
                regisType:
                    permit.permitType == PermitType.duration ? REGIS_TYPE_FIXED_TIME : REGIS_TYPE_SPECIFIC_DURATION,
                specificTime: secondsToDate(permit.maxDuration!),
                endStatus: permit.validateToType!,
                validateFrom: permit.validateFrom!,
                validateTo: permit.validateTo!,
            };
            return iparkData;
        }
        return defaultFormDataIPark;
    };

    const getValidateTime = (date: Date, permit?: Permit) => {
        return new Date(date);
    };

    const getWeeksDay = (arr: PermitRecurringDay[]) => {
        const num: number[] = [];
        arr.forEach((item) => {
            num.push(moment(item.firstExecuteAt!).weekday());
        });
        return num;
    };

    return { isLoadingEditingData, vehicleListEdit, permit, getValidateTime, getIsCheckedAllDay, getWeeksDay };
}

interface TempState {
    allCompany: Company[];
    allLocation: Location[];
    allPortfolio: Portfolio[];
    selectedCompany: UnionScope[];
    selectedCompanyFull: UnionScope[];
    scopes: Scope[];
}
