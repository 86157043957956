/* eslint-disable react-hooks/exhaustive-deps */
import { useEffect, useState } from 'react';
import { ParentProps } from '..';
import { companyController, locationController, portfolioController } from '../../../controllers';
import { DataState } from '../components/model/UpsertVehicleList';

const DefaultData = {
    companies: [],
    locations: [],
    services: [],
    portfolios: [],
};

export default function useVehicleListGetData(props: ParentProps) {
    const [loading, setLoading] = useState<LoadingState>({ companies: true, locations: true, portfolios: true });
    const [dataState, setDataState] = useState<DataState>(DefaultData);
    const [defaultDataBE, setDefaultDataBE] = useState<DataState>(DefaultData);
    const setPartialDataState = (partial: Partial<DataState>) => setDataState((prev) => ({ ...prev, ...partial }));

    useEffect(() => {
        getCompanies();
        getLocations();
        getPortfolios();
    }, []);

    const getCompanies = () => {
        setLoading((p) => ({ ...p, companies: true }));
        companyController
            .AllCompanyToZone()
            .then((res) => {
                setPartialDataState({ companies: res });
                setDefaultDataBE((d) => ({ ...d, companies: res }));
            })
            .finally(() => {
                setLoading((p) => ({ ...p, companies: false }));
            });
    };

    const getLocations = () => {
        setLoading((p) => ({ ...p, locations: true }));
        locationController
            .getAll({
                page: 1,
                pageSize: 10000,
                filter: { companyId: 0, regionId: 0 },
                search: { fields: [], value: '' },
            })
            .then((res) => {
                setPartialDataState({ locations: res.data });
                setDefaultDataBE((d) => ({ ...d, locations: res.data }));
            })
            .finally(() => {
                setLoading((p) => ({ ...p, locations: false }));
            });
    };

    const getPortfolios = () => {
        setLoading((p) => ({ ...p, portfolios: true }));
        portfolioController
            .getAll({
                page: 1,
                pageSize: 10000,
                filter: {},
                search: { fields: [], value: '' },
            })
            .then((res) => {
                setPartialDataState({ portfolios: res.data });
                setDefaultDataBE((d) => ({ ...d, portfolios: res.data }));
            })
            .finally(() => {
                setLoading((p) => ({ ...p, portfolios: false }));
            });
    };
    return { dataState, setPartialDataState, loading, defaultDataBE };
}

export interface LoadingState {
    companies: boolean;
    locations: boolean;
    portfolios: boolean;
}
