import React, { Dispatch, SetStateAction, useState } from 'react';
import { ExcelRawData } from '../components/UploadVRNPage';

type Props = {
    page: number;
    setPage: Dispatch<SetStateAction<number>>;
};

interface State {
    isLoading: boolean;
    progressPercent: number;
    fileName: string;
    fileUpload: File | null;
    isLoadingDownload: boolean;
    rawsExcelData: ExcelRawData[];
    errorText: string;
}

const defaultUploadState = {
    isLoading: false,
    progressPercent: 0,
    fileName: '',
    fileUpload: null,
    isLoadingDownload: false,
    rawsExcelData: [],
    errorText: '',
};

export default function useBulkUpload({ page, setPage }: Props) {
    const [state, setState] = useState<State>(defaultUploadState);

    const setPartialState = (partial: Partial<State>) => setState((prev) => ({ ...prev, ...partial }));

    return { ...state, setPartialState, defaultUploadState };
}

export const BulkUploadPermitsContext = React.createContext<ReturnType<typeof useBulkUpload>>({} as any);
