import React from 'react';
import { Outlet } from 'react-router-dom';
import useVehicleList, { VehicleListContext } from './hooks/useVehicleList';

export default function VehicleListLayout() {
    const vehicleData = useVehicleList();
    return (
        <VehicleListContext.Provider value={vehicleData}>
            <Outlet />
        </VehicleListContext.Provider>
    );
}
export function VehicleListLayoutWithChildren({ children }: any) {
    const vehicleData = useVehicleList();
    return <VehicleListContext.Provider value={vehicleData}>{children}</VehicleListContext.Provider>;
}
