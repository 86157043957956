import { Button, Stack, Typography } from '@mui/material';
import PopUpBase, { IPopUp } from './PopUpBase';

type Props = IPopUp & {
    title?: string;
    message?: string;
};

export default function PopUpWarning(props: Props) {
    return (
        <PopUpBase
            open={props.open}
            dialogProps={{ fullWidth: false, maxWidth: 'sm' }}
            onClose={props.onClose}
            onConfirm={() => {}}
            title={
                <Typography variant="h4" color="error">
                    {props.title || 'Warning'}
                </Typography>
            }
            hideConfirm
            hideClose
            subTitleProps={{ sx: { color: 'gray' } }}
            minWidthButton={150}
            desc={
                <Stack pb={0.5}>
                    <Typography textAlign={'center'} sx={{ minWidth: 320, maxWidth: 410 }}>
                        {props.message || 'Are you sure to delete?'}
                    </Typography>

                    <Stack mt={3} direction={'row'} justifyContent="space-around">
                        <Button sx={{ minWidth: 130 }} variant="cancel" onClick={props.onClose}>
                            Cancel
                        </Button>
                        <Button sx={{ minWidth: 130 }} variant="errorContained" onClick={props.onConfirm}>
                            Confirm
                        </Button>
                    </Stack>
                </Stack>
            }
        />
    );
}
