interface IConfig {
    gateway: {
        backendURL: string;
        iPermitURL: string;
    };
}
export const a = 0;

export const appConfig: IConfig = {
    gateway: {
        backendURL: process.env.REACT_APP_STELLA_URL!,
        iPermitURL: process.env.REACT_APP_IPERMIT_URL!
    },
};

// const localConfig: IConfig = {
//     gateway: {
//         // apiAccount: "http://192.168.1.227:5002/api",
//         // apiAccount: 'https://api-account-dev-intellipark.azurewebsites.net/api',
//         clientPortalAPI: "http://192.168.1.226:7177/api",
//         siteKeyCaptcha: process.env.REACT_APP_SITE_KEY_CAPTCHA!,
//         googMapSiteKey: process.env.REACT_APP_GOOGLE_MAP_KEY!,
//         apiAzureMedia: 'http://192.168.1.226:5000/api/Account/Avatar',
//         permits: "http://192.168.1.226:7177/api/Permit/GetPermits"
//     },
// };

// const prodConfig: IConfig = {
//     gateway: {
//         clientPortalAPI: '',
//         siteKeyCaptcha: process.env.REACT_APP_SITE_KEY_CAPTCHA!,
//         googMapSiteKey: process.env.REACT_APP_GOOGLE_MAP_KEY!,
//         apiAzureMedia: 'https://intelliparkdev.blob.core.windows.net/api/Account/Avatar',
//         permits: "http://0.0.0.0:7177/api/Permit/GetPermits"
//     },
// };
// const env = process.env.REACT_APP_NODE_ENV;

// console.log(env == 'development' ? devConfig : env == 'production' ? prodConfig : localConfig);
