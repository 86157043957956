import React, { SVGProps } from 'react';

type Props = {} & SVGProps<SVGSVGElement>;

export default function SearchIcon(props: Props) {
    return (
        <svg {...props} width="17" height="16" viewBox="0 0 17 16" fill="none" xmlns="http://www.w3.org/2000/svg">
            <circle
                cx="8.78626"
                cy="7.84901"
                r="5.59901"
                stroke="#85858A"
                strokeWidth="1.5"
                strokeLinecap="round"
                strokeLinejoin="round"
            />
            <path
                d="M13.5369 12.686L15.3509 14.5"
                stroke="#85858A"
                strokeWidth="1.5"
                strokeLinecap="round"
                strokeLinejoin="round"
            />
        </svg>
    );
}
