import { Button, Grid, SxProps, Theme, Typography } from '@mui/material';
import React from 'react';
import { BoxRadiusGrey, GFlexRow, GFlexRowCenter } from '../../styles/style';
import FilterIcon from '../svg/FilterIcon';

type BaseFilterProps = {
    rightTitleContent?: React.ReactNode;
    handleResetFilter: () => void;
    filterChild?: React.ReactNode;
    baseStyle?: SxProps<Theme>;
};

export default function FilterRoot(props: BaseFilterProps) {
    return (
        <BoxRadiusGrey sx={props.baseStyle}>
            <Grid container gap={2}>
                <GFlexRow item xs={12} justifyContent={props.rightTitleContent ? 'space-between' : 'left'}>
                    <GFlexRowCenter item gap={2}>
                        <FilterIcon />
                        <Typography>Filter</Typography>
                        <Button variant="cancelSmall" onClick={props.handleResetFilter}>
                            Reset filter
                        </Button>
                    </GFlexRowCenter>
                    {props.rightTitleContent}
                </GFlexRow>

                {props.filterChild}
            </Grid>
        </BoxRadiusGrey>
    );
}
