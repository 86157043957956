import { CircularProgress, Fade, Stack, Typography } from '@mui/material';
import React, { ReactNode } from 'react';
import NoDataIcon from './svg/NoDataIcon';

type Props = {
    loading?: boolean;
    children: ReactNode;
    rows: any[];
};

const LoadingWrap = (props: Props) => {
    return props.loading ? (
        <Stack direction={'row'} justifyContent="center" mt={5}>
            <CircularProgress />
        </Stack>
    ) : props.rows.length ? (
        <Fade in={!!props.rows.length}>
            <div>{props.children}</div>
        </Fade>
    ) : (
        <Stack alignItems="center" mt={10}>
            <Stack>
                <NoDataIcon style={{ fill: '#9598a1' }} />
            </Stack>
            <Typography variant="h5" color="#9598a1">
                No data here
            </Typography>
        </Stack>
    );
};

export default LoadingWrap;
