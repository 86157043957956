import { Typography } from '@mui/material';
import { Stack } from '@mui/system';
import moment from 'moment';
import { WhiteList } from '../../models/whiteList/WhiteList';
import { StyledBaseItem } from '../vehicleList/components/VehicleListItem';
import { VehicleListWithZone } from '../../models/vehicleList/VehicleList';
import { RecurringType } from '../../models/permit/PermitRecurring';
import { formatDate } from '../../helpers/moment';

type Props = {
    whiteList: WhiteList;
    vehicleList: VehicleListWithZone;
};

const WhiteListItem = (props: Props) => {
    const isPermitRecurring = (props.vehicleList?.permits?.[0].permitRecurring?.length ?? 0) > 0;

    const recurring = props.vehicleList?.permits?.[0].permitRecurring?.[0];
    const recurringType = recurring ? recurring.recurringType : null;

    const isIndefinite = recurringType === RecurringType.indefinite;
    const isOnce = recurringType === RecurringType.once;
    const isDaily = recurringType === RecurringType.day;
    const isWeekly = recurringType === RecurringType.week;
    const isMonthly = recurringType === RecurringType.month;

    const permitValidateTo = props.vehicleList?.permits?.[0].validateTo;

    const getPermitTo = () => {
        if (isDaily && !permitValidateTo) {
            const now = moment().format('YYYY-MM-DD');
            const dateTo = moment(props.whiteList.to).format('YYYY-MM-DD');
            if (now === dateTo) return 'Indefinite';
        }

        if (isDaily || isOnce || isWeekly || isMonthly) {
            return moment(props.whiteList.to).format('HH:mm:ss DD/MM/YYYY');
        }

        if (permitValidateTo) {
            const validateTo = moment(permitValidateTo).format('DD/MM/YYYY');

            let secondsPermitRecurring = isPermitRecurring
                ? props.vehicleList?.permits?.[0].permitRecurring?.[0].toHour
                : 0;
            if (secondsPermitRecurring === 86400) {
                secondsPermitRecurring -= 60;
            }
            const convertDateValidateTo = moment(validateTo, 'DD/MM/YYYY')
                .add(secondsPermitRecurring, 'seconds')
                .toDate();
            return moment(convertDateValidateTo).format('HH:mm:ss DD/MM/YYYY');
        }

        return 'Indefinite';
    };

    // const getPermitTo = () => {
    //     if (!isIndefinite || !isOnce) {
    //         if (permitValidateTo) {
    //             const validateTo = moment(permitValidateTo).format('DD/MM/YYYY');
    //             let secondsPermitRecurring = isPermitRecurring
    //                 ? props.vehicleList?.permits?.[0].permitRecurring?.[0].toHour
    //                 : 0;
    //             if (secondsPermitRecurring === 86400) {
    //                 secondsPermitRecurring -= 60;
    //             }
    //             const convertDateValidateTo = moment(validateTo, 'DD/MM/YYYY')
    //                 .add(secondsPermitRecurring, 'seconds')
    //                 .toDate();

    //             return moment(convertDateValidateTo).format('HH:mm:ss DD/MM/YYYY');
    //         } else {
    //             if (isDaily || isWeekly || isMonthly) {
    //                 return moment(props.whiteList.to).format('HH:mm:ss DD/MM/YYYY');
    //             }
    //             return 'Indefinite';
    //         }
    //     } else {
    //         return moment(props.whiteList.to).format('HH:mm:ss DD/MM/YYYY');
    //     }
    // };

    const getValidTo = () => {
        if (isPermitRecurring) {
            if (isIndefinite) {
                const now = new Date().setHours(0, 0, 0, 0);
                const dateTo = new Date(props.whiteList.to).setHours(0, 0, 0, 0);
                if (dateTo <= now) return moment(props.whiteList.to).format('HH:mm:ss DD/MM/YYYY');
                return 'Indefinite';
            } else {
                return getPermitTo();
            }
        } else {
            return moment(props.whiteList.to).format('HH:mm:ss DD/MM/YYYY');
        }
    };

    return (
        <StyledBaseItem spacing={'8px'}>
            <Stack direction={'row'} alignItems="center" justifyContent={'space-between'}>
                <Typography variant="h6">{props.whiteList.vrnNumber}</Typography>
            </Stack>

            <Typography>
                Valid from: {moment(props.whiteList.from).format('HH:mm:ss DD/MM/YYYY')} - Valid to: {getValidTo()}
            </Typography>

            <Typography color="GrayText">
                Created date: {formatDate.getLocalDateString(props.whiteList.created)}
                {/* <span style={{ margin: '0 4px 0 4px' }}>-</span> Created by: {props.whiteList.createdBy} */}
            </Typography>
        </StyledBaseItem>
    );
};

export default WhiteListItem;
