export interface SearchType {
    name: string;
    id: string;
}

export interface Search {
    type: SearchType;
    value: string;
}

export const searchTypes: SearchType[] = [
    {
        name: 'Search by VRN',
        id: 'vrn',
    },
    {
        name: 'Search by name',
        id: 'name',
    },
];
