import { colors, IconButton, styled, Tooltip, Typography } from '@mui/material';
import { Stack } from '@mui/system';
import moment from 'moment';
import { useContext } from 'react';
import LinkTo from '../../components/LinkTo';
import EditIcon2 from '../../components/svg/EditIcon2';
import RemoveIcon2 from '../../components/svg/RemoveIcon2';
import color from '../../config/Colors';
import { Vehicle } from '../../controllers/vehicle/VehicleController';
import { AuthContext, AuthPermission } from '../../layout/AuthLayout';
import { DangerTooltip } from '../../styles/style';
import { StyledBaseItem } from '../vehicleList/components/VehicleListItem';
import { formatDate } from '../../helpers/moment';

type Props = {
    vehicleListId: number;
    item: Vehicle;
    onDelete?(item: any): void;
    onEdit?(vrnId: number): void;
};

export const StyledBaseIconButton = styled(IconButton)({
    backgroundColor: color.grey200,
    color: color.grey600,
    border: `2px solid ${color.grey100}`,
    width: '32px',
    height: '32px',
    padding: '4px',
    ':hover': {
        backgroundColor: colors.grey[300],
    },
});

const VRNItem = (props: Props) => {
    const { permission: p } = useContext(AuthContext);
    const readOnly = p === AuthPermission.READ_ONLY;
    const isShowName = (props.item.firstName + props.item.lastName).length > 0;
    return (
        <StyledBaseItem spacing={'8px'}>
            <Stack direction={'row'} alignItems="center" justifyContent={'space-between'} sx={{ height: 21 }}>
                <Typography variant="h6">VRN: {props.item.vrnNumber}</Typography>
                <Stack direction={'row'} spacing={1}>
                    <LinkTo to={`${props.onEdit?.(props.item.id)}`}>
                        <Tooltip title={'Edit'} placement="top">
                            <StyledBaseIconButton disabled={readOnly}>
                                <EditIcon2 />
                            </StyledBaseIconButton>
                        </Tooltip>
                    </LinkTo>
                    <DangerTooltip title={'Delete'} placement="top">
                        <StyledBaseIconButton onClick={() => props.onDelete?.(props.item)} disabled={readOnly}>
                            <RemoveIcon2 />
                        </StyledBaseIconButton>
                    </DangerTooltip>
                </Stack>
            </Stack>
            {isShowName && (
                <Typography variant="h6">
                    Name: {props.item.firstName} {props.item.lastName}
                </Typography>
            )}

            <Typography color="GrayText" fontStyle={'italic'}>
                Created date: {formatDate.getLocalDateString(props.item.created)}
            </Typography>
        </StyledBaseItem>
    );
};

export default VRNItem;
