/* eslint-disable react-hooks/exhaustive-deps */
import React, { useContext, useEffect } from 'react';
import moment from 'moment';
import { Controller } from 'react-hook-form';
import { Grid } from '@mui/material';
import { ListRecurring } from '../../constant';
import { RecurringType } from '../../../../models/permit/PermitRecurring';
import { GridInterNalRecur } from './InternalPermitStyle';
import AutoCompleteInternalPermit from './AutoCompleteInternalPermit';
import DayRecurring from '../recurringType/DayRecurring';
import MonthRecurring from '../recurringType/MonthRecurring';
import WeekRecurring from '../recurringType/WeekRecurring';
import InternalPermitTimeDuration from './InternalPermitTimeDuration';
import InternalPermitDateRange from './InternalPermitDateRange';
import { VehicleListUpsertContext } from '../../hooks/useVehicleListUpsert';
import { endOfDay, startOfDay } from 'date-fns';
import { convertHour } from '../../../../helpers/ConvertHour';
import { Permit, ValidateToType } from '../../../../models/permit/Permit';

export default function InternalPermit() {
    const { methods } = useContext(VehicleListUpsertContext);
    const { isEdit, editVehicleListData } = useContext(VehicleListUpsertContext);

    const { getValues, setValue, control, watch } = methods;

    const { permit, getWeeksDay } = editVehicleListData;
    const oldPermit = permit;

    const getRecurTypeFromSelect = () => {
        return ListRecurring.find((item) => item.id === getValues().internalPermitData!.recurringType);
    };
    const { internalPermitData } = watch();
    const { recurringType, recurringEvery, weekRecurringDays } = internalPermitData!;

    const renRecurType = () => {
        switch (recurringType) {
            case RecurringType.once || RecurringType.indefinite:
                return <></>;
            case RecurringType.day:
                return (
                    <DayRecurring
                        recurringEvery={recurringEvery}
                        setREcurringEvery={(val) => setValue('internalPermitData.recurringEvery', val)}
                        methods={methods}
                        isEdit={isEdit}
                    />
                );
            case RecurringType.week:
                return (
                    <WeekRecurring
                        recurringEvery={recurringEvery}
                        setREcurringEvery={(val) => setValue('internalPermitData.recurringEvery', val)}
                        weekRecurringDays={weekRecurringDays}
                        setWeekRecurringDays={(val) => setValue('internalPermitData.weekRecurringDays', val)}
                        methods={methods}
                        isEdit={isEdit}
                    />
                );
            case RecurringType.month:
                return (
                    <MonthRecurring
                        recurringEvery={recurringEvery}
                        setREcurringEvery={(val) => setValue('internalPermitData.recurringEvery', val)}
                        methods={methods}
                        isEdit={isEdit}
                    />
                );
            case RecurringType.year:
                return <></>;
            default:
                break;
        }
    };

    useEffect(() => {
        handleChangeRecurType(recurringType);
    }, [recurringType]);

    const getTime = (date: Date, permit: Permit, currentRecur: RecurringType) => {
        const recurType = permit.permitRecurring![0].recurringType;
        if (currentRecur === recurType) {
            return new Date(date);
        } else {
            return null;
        }
    };

    const handleChangeRecurType = (recurringType: RecurringType) => {
        if (!isEdit) {
            if (recurringType === Number(RecurringType.indefinite)) {
                methods.setValue(
                    'internalPermitData',
                    {
                        ...internalPermitData,
                        fromHour: startOfDay(new Date()),
                        toHour: endOfDay(new Date()),
                        validateTo: new Date(2999, 1, 1),
                        isCheckedAllDay: false,
                    },
                    { shouldTouch: true, shouldValidate: true }
                );
            } else if (recurringType === RecurringType.once) {
                methods.setValue(
                    'internalPermitData',
                    {
                        ...internalPermitData,
                        fromHour: convertHour.getHoursAndMinutesFromTimeNumber(null),
                        toHour: convertHour.getHoursAndMinutesFromTimeNumber(null),
                        validateTo: new Date(),
                        endStatus: ValidateToType.infinite,
                        isCheckedAllDay: false,
                    },
                    { shouldTouch: true, shouldDirty: true, shouldValidate: true }
                );
            } else {
                methods.setValue(
                    'internalPermitData',
                    {
                        ...internalPermitData,
                        fromHour: convertHour.getHoursAndMinutesFromTimeNumber(null),
                        toHour: convertHour.getHoursAndMinutesFromTimeNumber(null),
                        validateTo: new Date(),
                        endStatus: ValidateToType.infinite,
                        isCheckedAllDay: false,
                    },
                    { shouldTouch: true, shouldDirty: true, shouldValidate: true }
                );
            }
        } else if (isEdit && permit && permit.id) {
            // getIsCheckedAllDay
            //change data
            if (recurringType === RecurringType.indefinite) {
                methods.setValue(
                    'internalPermitData',
                    {
                        ...internalPermitData,
                        fromHour: startOfDay(new Date()),
                        toHour: endOfDay(new Date()),
                        validateFrom: new Date(),
                        validateTo: new Date(2999, 1, 1),
                        endStatus: ValidateToType.infinite,
                        // isCheckedAllDay: getIsCheckedAllDay(permit),
                    },
                    { shouldTouch: true, shouldDirty: true, shouldValidate: true }
                );
            } else {
                methods.setValue(
                    'internalPermitData',
                    {
                        ...internalPermitData,
                        fromHour: convertHour.getHoursAndMinutesFromTimeNumber(permit.permitRecurring![0].fromHour),
                        toHour: convertHour.getHoursAndMinutesFromTimeNumber(permit.permitRecurring![0].toHour),
                        validateFrom: getTime(permit.validateFrom!, permit, recurringType),
                        validateTo: getTime(permit.validateTo!, permit, recurringType),
                        endStatus: permit.validateToType!,
                        weekRecurringDays:
                            oldPermit.permitRecurring![0].recurringType === RecurringType.week
                                ? getWeeksDay(oldPermit.permitRecurring![0].permitRecurringDays!)
                                : [moment(new Date()).weekday()],
                        // isCheckedAllDay: getIsCheckedAllDay(permit),
                    },
                    { shouldTouch: true, shouldDirty: true, shouldValidate: true }
                );
            }
        }
    };

    return (
        <GridInterNalRecur item xs={12} container spacing={2}>
            <Grid item xs={[RecurringType.indefinite, RecurringType.once].includes(recurringType) ? 12 : 4}>
                <Controller
                    name="internalPermitData.recurringType"
                    control={control}
                    render={({ field, fieldState, formState }) => {
                        return (
                            <AutoCompleteInternalPermit
                                listData={ListRecurring}
                                label="Recurring type"
                                value={getRecurTypeFromSelect()}
                                disabled={isEdit}
                                setFilter={(option) => {
                                    field.onChange(option.id);
                                }}
                            />
                        );
                    }}
                />
            </Grid>

            <Grid
                item
                xs={8}
                display={[RecurringType.indefinite, RecurringType.once].includes(recurringType) ? 'none' : 'block'}
            >
                {renRecurType()}
            </Grid>
            <Grid
                container
                item
                direction={'row'}
                // mt={2}
                display={recurringType === RecurringType.indefinite ? 'none' : 'flex'}
            >
                <Grid item xs={12}>
                    <InternalPermitTimeDuration isEdit={isEdit} />
                </Grid>
                <Grid
                    xs={12}
                    item
                    // mt={touchedFields.internalPermitData!.toHour && !!touchedFields.internalPermitData!.toHour ? 1 : 0}
                >
                    <InternalPermitDateRange isEdit={isEdit} />
                </Grid>
            </Grid>
        </GridInterNalRecur>
    );
}
