export enum VLLogAction {
    'Created',
    'Updated',
    'Deleted',
    'Added VRNs',
    'Viewed',
    'View permit list',
    'View permit whitelist',
    'Deleted permit',
    'Updated permit',
}

export enum VLLogField { 'Name', 'Scope', 'Comment', 'Status', 'Parameter change' }

// console.log(a);
// console.log(VLLogAction[a]);