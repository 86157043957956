import { appConfig } from '../config/AppConfig';
import { axiosCreator } from './axiosConfig';
import { CompaniesController } from './company/CompaniesController';
import { LocationController } from './location/LocationController';
import { PortfolioController } from './portfolio/PortfolioController';
import { RegionController } from './region/RegionController';
import { TenantController } from './tenant/TenantController';
import { VehicleController } from './vehicle/VehicleController';
import { ServiceController } from './vehicleList/ServiceController';
import { VehicleListController } from './vehicleList/VehicleListController';
import { WhiteListController } from './vehicleList/WhiteListController';
import { ZoneController } from './zone/ZoneController';

const apiUrl = appConfig.gateway.backendURL;

export const axiosClient = axiosCreator({
    // url: apiUrl.authen,
    url: apiUrl,
    onError: (err: any) => {
        // if (err.status == 401) {
        //     window.location.href = '/login';
        // }
        // if (err.status == 404) {
        //     window.location.href = '/';
        // }
        // if (err.status == 403) {
        //     window.location.href = '/';
        // }
    },
});

export const companyController = new CompaniesController(apiUrl, axiosClient);
export const regionController = new RegionController(apiUrl, axiosClient);
export const locationController = new LocationController(apiUrl, axiosClient);
export const zoneController = new ZoneController(apiUrl, axiosClient);
export const serviceController = new ServiceController(apiUrl, axiosClient);
export const vehicleListController = new VehicleListController(apiUrl, axiosClient);
export const whiteListController = new WhiteListController(apiUrl, axiosClient);
export const vehicleController = new VehicleController(apiUrl, axiosClient);
export const portfolioController = new PortfolioController(apiUrl, axiosClient);
export const tenantController = new TenantController(apiUrl, axiosClient);

