/* eslint-disable react-hooks/exhaustive-deps */
import { Stack, Typography } from '@mui/material';
import moment from 'moment';
import { useEffect, useState } from 'react';
import PopUpBase, { IPopUp } from '../../components/PopUpBase';
import { vehicleController } from '../../controllers';
import { Vehicle, VehicleFilter } from '../../controllers/vehicle/VehicleController';
import useDbSearch from '../../hooks/useDbSearch';
import { Pagination } from '../../models/filter/BaseFilter';
import { VehicleList } from '../../models/vehicleList/VehicleList';
import BootstrapInput from './components/BootstrapInput';
import BPagination from './components/BPagination';
import { formatDate } from '../../helpers/moment';

type PopUpProps = IPopUp & {
    vehicleList?: VehicleList;
};

const PopUpViewVrn = (props: PopUpProps) => {
    const [query, setQuery] = useState({ page: 1, search: '' });

    const [paging, setPaging] = useState<Pagination<Vehicle>>({
        page: 1,
        pageSize: 10,
        data: [],
        total: 0,
        totalPage: 0,
    });

    const getList = (_props: Partial<VehicleFilter>) => {
        props.vehicleList?.id &&
            vehicleController
                .filter({
                    page: query.page,
                    filter: {
                        vehicleListId: props.vehicleList.id,
                    },
                    pageSize: 10,
                    search: {
                        fields: ['name'],
                        value: '',
                    },
                    ..._props,
                })
                .then((res) => {
                    setPaging(res);
                });
    };

    const _useDbSearch = useDbSearch({
        fn: (text: string) =>
            getList({
                page: 1,
                search: {
                    fields: ['name'],
                    value: text,
                },
            }),
    });

    useEffect(() => {
        getList({ page: query.page });
    }, [query, props.vehicleList?.id]);

    return (
        <PopUpBase
            {...props}
            dialogProps={{ fullWidth: false, maxWidth: 'md' }}
            onConfirm={() => {}}
            title={
                <Typography
                    variant="h4"
                    sx={{ padding: '0 24px', maxWidth: '450px' }}
                >{`View VRNs of ${props.vehicleList?.name}`}</Typography>
            }
            hideConfirm
            hideClose
            subTitleProps={{ sx: { color: 'gray' } }}
            minWidthButton={150}
            desc={
                <Stack pb={0.5} minWidth={400}>
                    <Stack mt="4px" mb={2} direction="row" justifyContent={'flex-end'}>
                        <BootstrapInput
                            sx={{
                                '& .MuiInputBase-input': {
                                    minWidth: 200,
                                },
                            }}
                            value={query.search}
                            onChange={(e) => {
                                const _value = e.target.value;
                                setQuery((prev) => ({ ...prev, search: _value }));
                                _useDbSearch(_value);
                            }}
                            id="bootstrap-input"
                            placeholder="Search VRN"
                        />
                    </Stack>

                    <table className="table table-sm table-bordered mb-2">
                        <thead>
                            <tr>
                                <th scope="col">#</th>
                                <th scope="col">VRN number</th>
                                <th scope="col">Created date</th>
                                <th scope="col">First name</th>
                                <th scope="col">Last name</th>
                            </tr>
                        </thead>
                        <tbody>
                            {paging.data.map((row, index) => (
                                <tr>
                                    <td>{index + 1}</td>
                                    <td>{row.vrnNumber}</td>
                                    <td>{formatDate.getLocalDateString(row.created, 'DD.MM.YYYY')}</td>
                                    <td>{row.firstName}</td>
                                    <td>{row.lastName}</td>
                                </tr>
                            ))}
                        </tbody>
                    </table>

                    {paging.totalPage > 1 && (
                        <BPagination
                            page={paging.page}
                            totalPage={paging.totalPage}
                            onChangePage={(page) => setQuery((prev) => ({ ...prev, page: page }))}
                        />
                    )}
                </Stack>
            }
        />
    );
};

export default PopUpViewVrn;
