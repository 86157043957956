import { BaseHttpController } from 'ale-base-model';
import { AxiosInstance } from 'axios';
import { Service } from '../../models/vehicleList/Service';

export class ServiceController extends BaseHttpController<Service> {
    constructor(serviceUrl: string, client: AxiosInstance) {
        super(serviceUrl, 'Service', client);
    }

    async getAll(): Promise<Service[]> {
        const res = await this.doGet({ path: 'getAll', config: {} });
        return res.data;
    }
}
