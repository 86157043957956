import { BaseModel } from '../BaseModel';
import { PermitRecurringDay } from './PermitRecurringDay';

export interface PermitRecurring extends BaseModel {
    fromHour: number | null;
    toHour: number | null;
    recurringType: RecurringType;
    recurringEvery: number;
    permitRecurringDays: PermitRecurringDay[];
}

export enum RecurringType {
    once = 0,
    day = 1,
    week = 2,
    month = 3,
    year = 4,
    indefinite = 5,
}
