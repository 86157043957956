import * as React from 'react';

function RemoveIcon2(props: React.SVGProps<SVGSVGElement>) {
    return (
        <svg width={12} height={14} viewBox="0 0 12 14" fill="none" xmlns="http://www.w3.org/2000/svg" {...props}>
            <path
                d="M10.603 5.357s-.352 4.37-.556 6.21c-.098.878-.64 1.394-1.53 1.41-1.692.03-3.387.032-5.079-.004-.855-.017-1.389-.539-1.484-1.402-.206-1.856-.556-6.214-.556-6.214M11.5 3.263H.5M9.38 3.263c-.508 0-.947-.36-1.047-.859l-.157-.788A.83.83 0 007.373 1H4.627a.83.83 0 00-.802.616l-.158.788c-.1.5-.538.86-1.047.86"
                stroke="currentColor"
                strokeLinecap="round"
                strokeLinejoin="round"
            />
        </svg>
    );
}

export default RemoveIcon2;
