import { Close } from '@material-ui/icons';
import {
    Button,
    Dialog,
    DialogActions,
    DialogActionsProps,
    DialogContent,
    DialogProps,
    DialogTitle,
    IconButton,
    Stack,
    Typography,
    TypographyProps,
} from '@mui/material';
import { ReactNode } from 'react';

export type IPopUp = {
    open: boolean;
    onClose?(): void;
    onConfirm?: () => any;
    dialogProps?: Omit<DialogProps, 'open' | 'onClose'>;
    dialogActionsProps?: DialogActionsProps;
    customActions?: ReactNode;
    hideConfirm?: boolean;
    hideClose?: boolean;
};

type Props = IPopUp & {
    title?: ReactNode;
    subTitle?: ReactNode;
    desc: ReactNode;
    fixOverflow?: boolean;
    minWidthButton?: number;
    subTitleProps?: TypographyProps;
    hideTitle?: boolean;
};

export default function PopUpBase(props: Props) {
    return (
        <Dialog
            PaperProps={{
                style: {
                    borderRadius: '10px',
                    border: '1px solid #ddd',
                    position: 'relative',
                },
            }}
            {...props.dialogProps}
            open={props.open}
            onClose={props.onClose}
        >
            <IconButton sx={{ position: 'absolute', top: 8, right: 8 }} onClick={props.onClose}>
                <Close />
            </IconButton>
            {!props.hideTitle && (
                <DialogTitle component={Stack} sx={{ textAlign: 'center' }}>
                    {typeof props.title === 'string' ? (
                        <Typography variant="h4">{props.title}</Typography>
                    ) : (
                        props.title
                    )}

                    {typeof props.subTitle === 'string' ? (
                        <Typography color="success.main" variant="body1" {...props.subTitleProps}>
                            {props.subTitle}
                        </Typography>
                    ) : (
                        props.subTitle
                    )}
                </DialogTitle>
            )}
            <DialogContent sx={{ overflowY: props.fixOverflow ? 'unset' : undefined }}>{props.desc}</DialogContent>
            {!(props.hideClose && props.hideConfirm) && (
                <DialogActions
                    {...props.dialogActionsProps}
                    sx={{
                        display: 'flex',
                        justifyContent: 'space-between',
                        '& button': {
                            minWidth: props.minWidthButton || 200,
                        },
                        px: 3,
                        pb: 3,
                        ...props.dialogActionsProps?.sx,
                    }}
                >
                    {props.customActions ? (
                        props.customActions
                    ) : (
                        <>
                            {!props.hideClose && (
                                <Button variant="cancel" onClick={props.onClose}>
                                    Close
                                </Button>
                            )}
                            {!props.hideConfirm && (
                                <Button variant="contained" onClick={props.onConfirm}>
                                    Submit
                                </Button>
                            )}
                        </>
                    )}
                </DialogActions>
            )}
        </Dialog>
    );
}
