import React from 'react';

export default function useMenuELPopUp() {
    const [anchorEl, setAnchorEl] = React.useState<null | HTMLElement>(null);
    const openAnchorEl = Boolean(anchorEl);
    const handleClick = (event: React.MouseEvent<HTMLButtonElement>) => {
        setAnchorEl(event.currentTarget);
    };
    const handleClose = () => {
        setAnchorEl(null);
    };
    return { openAnchorEl, handleClick, handleClose, anchorEl, setAnchorEl };
}
