/* eslint-disable react-hooks/exhaustive-deps */
import React, { useEffect, useState } from 'react';
import { vehicleListController } from '../../../controllers';
import { Pagination } from '../../../models/filter/BaseFilter';
import { FilterHistoryAction } from '../../../models/vehicleListAction/VehicleListHistoryLogFilter';
import { VehicleListLogItem } from '../../../models/vehicleListAction/VehicleListLogItem';

interface Props {
    vehicleListId: number;
}

interface State {
    filter: FilterHistoryAction;
    isVLHistoryLoading: boolean;
    vlHistoryPagination: Pagination<VehicleListLogItem>;
}

export default function useVehicleListHistoryLog(props: Props) {
    const defaultFilterEventLogs: FilterHistoryAction = {
        page: 1,
        pageSize: 10,
        search: {
            fields: [''],
            value: '',
        },
        filter: {
            vehicleListId: props.vehicleListId,
            from: undefined,
            to: undefined,
        },
    };
    const [state, setState] = useState<State>({
        filter: defaultFilterEventLogs,
        isVLHistoryLoading: true,
        vlHistoryPagination: { data: [], page: 1, pageSize: 5, total: 1, totalPage: 1 },
    });

    useEffect(() => {
        getEventData(state.filter);
    }, [state.filter]);

    const getEventData = (filter: FilterHistoryAction) => {
        setPartialActionFilter({ isVLHistoryLoading: true });

        vehicleListController
            .getEvent(filter)
            .then((res) => {
                setPartialActionFilter({ vlHistoryPagination: res });
            })
            .finally(() => {
                setPartialActionFilter({ isVLHistoryLoading: false });
            });
    };

    const setPartialActionFilter = (partial: Partial<State>) => setState((prev) => ({ ...prev, ...partial }));
    const setPartialFilter = (partial: Partial<FilterHistoryAction>) =>
        setState((p) => ({ ...p, filter: { ...p.filter, ...partial } }));

    return { setPartialActionFilter, state, setPartialFilter, defaultFilterEventLogs };
}

export const VehicleListHistoryLogContext = React.createContext<ReturnType<typeof useVehicleListHistoryLog>>({} as any);
