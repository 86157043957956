import { BaseHttpController } from 'ale-base-model';
import { AxiosInstance } from 'axios';
import { Pagination } from '../../models/filter/BaseFilter';
import { FilterPortfolio, Portfolio } from '../../models/scope/Portfolio';

export class PortfolioController extends BaseHttpController<Portfolio> {
    constructor(serviceUrl: string, client: AxiosInstance) {
        super(serviceUrl, 'Portfolio', client);
    }

    async getAll(filter: FilterPortfolio): Promise<Pagination<Portfolio>> {
        const res = await this.doPost({ path: 'getAll', body: filter, config: {} });
        return res.data;
    }

}
