import * as React from 'react';
import { Autocomplete, Box, Checkbox, TextField, Typography } from '@mui/material';
import CheckBoxIcon from '../../../../components/svg/CheckBoxIcon';
import CheckedIcon from '../../../../components/svg/CheckedIcon';
import color from '../../../../config/Colors';

const icon = <CheckBoxIcon fontSize="small" />;
const checkedIcon = <CheckedIcon fontSize="small" />;

type Props<T> = {
    listData: T[];
    label: string | JSX.Element;
    value: any[];
    keyLabel: keyof T;
    keyId: keyof T;
    setFilter: (option: T[]) => void;
    isDisable?: boolean;
    isSelectAllZone: boolean;
    placeHolder?: string;
};

export default function MultiSelectPermitScopeZone<T>(props: Props<T>) {
    const handleInput = (e: any, value: T[]) => {
        props.setFilter(value);
    };

    return (
        <Box
            sx={{
                '& ul': {
                    padding: '5px !important',
                    borderBottom: `1px solid ${color.grey100}`,
                },
                '& svg': {
                    width: '15px !important',
                    height: '15px !important',
                },
                '& .MuiButtonBase-root': {
                    backgroundColor: color.grey200,
                    maxWidth: '120px !important',
                },
            }}
        >
            <Autocomplete
                multiple
                id="checkboxes-tags-demo1"
                options={props.listData}
                sx={{
                    '& .MuiOutlinedInput-root > span.MuiAutocomplete-tagSizeSmall': {
                        px: '5px',
                        background: color.grey200,
                        borderRadius: '5px',
                        fontSize: '12px',
                    },
                }}
                disabled={props.isDisable || false}
                componentsProps={{
                    popper: {
                        sx: {
                            zIndex: 99999,
                            '& .MuiAutocomplete-listbox': {
                                padding: '5px',
                            },
                            '& li': {
                                minHeight: 'auto',
                            },
                        },
                    },
                }}
                limitTags={2}
                disableCloseOnSelect
                noOptionsText={'No options'}
                // open
                size="small"
                getOptionLabel={(option) => option[props.keyLabel]}
                onChange={(event, value) => handleInput(event, value)}
                value={props.value || null}
                renderOption={(props1, option, { selected }) => {
                    return (
                        <li
                            {...props1}
                            style={{
                                borderRadius: '4px',
                                marginBottom: '2px',
                                paddingRight: '10px',
                            }}
                            key={Number(option[props.keyId])}
                        >
                            <Checkbox
                                icon={icon}
                                checkedIcon={checkedIcon}
                                style={{ marginRight: 8, padding: 0 }}
                                checked={selected}
                            />
                            <Typography sx={{ overflow: 'hidden', textOverflow: 'ellipsis' }}>
                                {option[props.keyLabel]}
                            </Typography>
                        </li>
                    );
                }}
                readOnly={props.isSelectAllZone ? true : false}
                renderInput={(params) => {
                    if (props.isSelectAllZone) {
                        params.InputProps.startAdornment = undefined;
                        return (
                            <TextField
                                {...params}
                                label={props.label}
                                InputLabelProps={{ shrink: true }}
                                placeholder={props.placeHolder ?? 'All zones'}
                                sx={{
                                    input: {
                                        '&::placeholder': {
                                            opacity: 1,
                                        },
                                    },
                                }}
                            />
                        );
                    } else {
                        return (
                            <TextField
                                {...params}
                                label={props.label}
                                error={props.value.length === 0}
                                InputLabelProps={{ shrink: true }}
                                placeholder={'Select more'}
                                sx={{}}
                            />
                        );
                    }
                }}
                isOptionEqualToValue={(option, value) =>
                    value === undefined || value === '' || option[props.keyId] === value[props.keyId]
                }
            />
        </Box>
    );
}
