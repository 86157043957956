import { addSeconds } from 'date-fns';
import { startOfDay } from 'date-fns/esm';
import moment from 'moment';

class ConvertHour {
    hourToDecimal(time: any) {
        const [h, m] = time.split(':');
        return Number((+h + +m / 60).toFixed(2));
    }

    dateToHour(date: Date) {
        return date.getHours() + ':' + date.getMinutes();
    }

    getSecondFromStartOfDay(date: Date | null) {
        if (!date) return null;
        const [h, m] = [date.getHours(), date.getMinutes()];
        const second = h * 60 * 60 + m * 60;
        return second;
    }

    getSecondFromHoursString = (num: string) => {
        const arr = num.split(':');
        const [h, m] = [arr[0], arr[1]];
        return Number(h) * 60 * 60 + Number(m) * 60;
    };

    decimalToDate(hour: number | null) {
        if (hour !== null) return moment().startOf('day').add(hour, 'hours').toDate();
        else return null;
    }

    getHoursAndMinutesFromTimeNumber = (seconds?: number | null) => {
        if (seconds === 0) {
            return startOfDay(new Date());
        }
        if (!seconds) return null;
        return addSeconds(startOfDay(new Date()), seconds);
    };

    SecondsToHoursMinStringFormat = (seconds: number | null) => {
        if (seconds === 0) return '00:00';
        if (!seconds) return null;
        return moment
            .utc(seconds * 1000)
            .format('HH:mm')
            .toString();
    };
}

export const convertHour = new ConvertHour();
