import { BaseHttpController } from 'ale-base-model';
import { AxiosInstance } from 'axios';
import { Region } from '../../models/scope/Region';
import { Company } from '../../models/scope/Company';
import { FilterCompany, FilterRequest, Pagination } from '../../models/filter/BaseFilter';

export interface Filter {
    companyId: number | 0;
    regionId?: number | 0;
    locationId?: number | 0;
    zoneIds?: number[];
    from?: Date | null;
    to?: Date | null;
}
export interface GetAllCompanyProps extends FilterRequest<Company> {
    filter: {};
}
export class CompaniesController extends BaseHttpController<Company> {
    constructor(serviceUrl: string, client: AxiosInstance) {
        super(serviceUrl, 'Company', client);
    }

    async getAll(props: FilterRequest<Company>): Promise<Pagination<Company>> {
        const filterCompany: FilterCompany = {
            ...props,
            filter: { companyId: 0, regionId: 0, locationId: 0, vehicleListId: 0, ...props.filter },
        };
        const res = await this.doPost({ path: 'GetAll', body: filterCompany, config: {} });
        return res.data;
    }
    async getMyCompany(): Promise<Company[]> {
        // axiosClient.defaults.headers['authorization'] = jwt;
        const res = await this.doGet({ path: '', config: {} });
        return res.data;
    }

    async getMyCompanyHaveTenant(): Promise<Company[]> {
        const res = await this.doGet({ path: 'tenant', config: {} });

        return res.data;
    }

    async GetCompanyDetail(filter: Filter): Promise<{ regions: Region[]; locations: Location[] }> {
        const res = await this.client.post(`${this.serviceURL}/${this.basePath}/GetRegionsAndLocations`, filter);
        return { regions: res.data.regions, locations: res.data.locations };
    }

    async GetCompanyDetailHaveTenant(filter: Filter): Promise<{ regions: Region[]; locations: Location[] }> {
        const res = await this.client.post(`${this.serviceURL}/${this.basePath}/GetRegionsAndLocationTenant`, filter);
        return { regions: res.data.regions, locations: res.data.locations };
    }

    async filter(filter: FilterRequest<Company>): Promise<Pagination<Company>> {
        const res = await this.doPost({ path: 'filter', body: filter, config: {} });
        return res.data;
    }

    async getById(id: number) {
        const res = await this.client.get(`${this.serviceURL}/company/${id}`);
        return res.data;
    }

    async CompanyToZone(val: SelectCompanyToZone): Promise<Company> {
        const res = await this.doPost({ path: 'CompanyToZone', body: val, config: {} });
        return res.data;
    }

    async AllCompanyToZone(): Promise<Company[]> {
        const res = await this.doGet({ path: 'AllCompanyToZone', config: {} });
        return res.data;
    }

    async CompanyByChildId(CompanyByChildId: CompanyByChildId): Promise<Company> {
        const res = await this.doPost({ path: 'CompanyByChildId', body: CompanyByChildId, config: {} });
        return res.data;
    }
}

export interface SelectCompanyToZone {
    type: string;
    id: number;
}

export interface CompanyByChildId {
    locationId?: number;
    zoneId?: number;
}
