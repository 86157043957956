import { Stack, TableBody, TableCell, TableRow, Typography } from '@mui/material';
import _ from 'lodash';
import moment from 'moment';
import color from '../../../config/Colors';
import { VehicleListLogItem } from '../../../models/vehicleListAction/VehicleListLogItem';
import { VLLogAction } from '../../../models/vehicleListAction/VLLogAction';
import { formatDate } from '../../../helpers/moment';

type Props = {
    historyItems: VehicleListLogItem[];
};

export default function TableRowsHistoryAction({ historyItems }: Props) {
    return (
        <TableBody>
            {historyItems.map((item) => {
                return (
                    <TableRow
                        key={item.id}
                        sx={{
                            '&:last-child th:first-of-type': { borderEndStartRadius: '10px' },
                            '&:last-child td:last-child': { borderEndEndRadius: '10px' },
                        }}
                    >
                        <TableCell
                            align="left"
                            component="th"
                            scope="row"
                            sx={{ p: '8px 16px', borderLeft: `1px solid ${color.grey300}` }}
                        >
                            {`${formatDate.getLocalDateString(item.created)}`}
                        </TableCell>
                        <TableCell align="left">
                            <Stack gap={0.5}>
                                <Typography>{item.userName}</Typography>
                                <Typography color={color.grey600} fontSize={'12px'}>
                                    {_.truncate(item.email, { length: 45 })}
                                </Typography>
                            </Stack>
                        </TableCell>
                        <TableCell align="left">{VLLogAction[item.action]}</TableCell>
                        <TableCell align="left" sx={{ p: '8px 16px', borderRight: `1px solid ${color.grey300}` }}>
                            <p
                                dangerouslySetInnerHTML={{ __html: item.description.replace(/\n/g, '<br />') }}
                                style={{ margin: 0 }}
                            />
                        </TableCell>
                    </TableRow>
                );
            })}
        </TableBody>
    );
}
