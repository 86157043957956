/* eslint-disable jsx-a11y/anchor-is-valid */
export const cx = (className: string, bool?: boolean) => (bool ? className : '');

const BPagination = (props: { page: number; totalPage: number; onChangePage(page: number): void }) => {
    const disabledPrev = props.page === 1;
    const disabledNext = props.page === props.totalPage;

    return (
        <nav>
            <ul className="pagination mb-0">
                <li
                    className={`page-item ${cx('disabled', disabledPrev)}`}
                    onClick={() => !disabledPrev && props.onChangePage(props.page - 1)}
                >
                    <a className="page-link" href="#" aria-label="Previous">
                        <span aria-hidden="true">&laquo;</span>
                    </a>
                </li>
                {[...new Array(props.totalPage)].fill(true).map((_, index) => {
                    const _disabled = index + 1 === props.page;
                    const _page = index + 1;
                    return (
                        <li
                            key={index}
                            className={`page-item ${cx('disabled', _disabled)}`}
                            onClick={() => !_disabled && props.onChangePage(_page)}
                        >
                            <a className="page-link" href="#">
                                {_page}
                            </a>
                        </li>
                    );
                })}
                <li
                    className={`page-item ${cx('disabled', disabledNext)}`}
                    onClick={() => !disabledNext && props.onChangePage(props.page + 1)}
                >
                    <a className="page-link" href="#" aria-label="Next">
                        <span aria-hidden="true">&raquo;</span>
                    </a>
                </li>
            </ul>
        </nav>
    );
};

export default BPagination;
