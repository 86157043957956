import { Navigate, Route, Routes } from 'react-router-dom';
import AuthLayout from './layout/AuthLayout';
import { Vehicle } from './models/vehicleList/Vehicle';
import { WrapEventLogPage } from './pages/event-logs';
import LogoutPage from './pages/LogoutPage';
import VehicleListPCNPage from './pages/vehicle-list-pcn';
import WrapVehicleListPage from './pages/vehicleList';
import VehicleListLayout from './pages/vehicleList/VehicleListLayout';
import { VehicleListDetailPage } from './pages/vehicleListDetails';
import { WrapVehicleListUpsert } from './pages/vehicleListUpsert';
import { WrapVRNPage } from './pages/vrn';
import WrapAddVRNPage, { WrapEditVRNPage } from './pages/vrn/AddVRN';
import './styles/App.css';

const VehicleListRoutes = () => {
    return (
        <Routes>
            <Route path="vehicle-list" element={<VehicleListLayout />}>
                <Route index element={<WrapVehicleListPage />} />
                <Route path="add" element={<WrapVehicleListUpsert />} />
                <Route path="edit/:idVehicleList" element={<WrapVehicleListUpsert />} />
                <Route path=":vehicleListId">
                    <Route index element={<VehicleListDetailPage />} />
                    <Route path="vrn" element={<WrapVRNPage />} />
                    <Route path="vrn/add" element={<WrapAddVRNPage />} />
                    <Route path="vrn/:vrnId" element={<WrapEditVRNPage />} />
                    <Route path="event-logs" element={<WrapEventLogPage />} />
                </Route>
            </Route>

            <Route path="" element={<Navigate to={'vehicle-list'} />} />
        </Routes>
    );
};

export default function App() {
    return (
        <Routes>
            <Route element={<AuthLayout />}>
                {/* <Route path="vehicle-list-pcn" element={<VehicleListPCNPage  
                    isAddPermission={false}
                    locationId={5097}
                    zoneReference=""
                    vrn={{
                        id:0,
                        permitsId:0,
                        bayNumber:"",
                        firstName:"aaa",
                        lastName:"ccc",
                        type:"a",
                        vrnNumber:"XX69NYS"
                    }}
                />} /> */}
                <Route path="global/*" element={<VehicleListRoutes />} />
                <Route path="location/:locationId/*" element={<VehicleListRoutes />} />
                <Route path="zone/:zoneId/*" element={<VehicleListRoutes />} />
                <Route path="" element={<Navigate to={'global'} />} />
                <Route path="logout" element={<LogoutPage />} />
            </Route>
        </Routes>
    );
}
