import { BaseHttpController } from 'ale-base-model';
import { AxiosInstance } from 'axios';
import { BaseModel } from '../../models/BaseModel';
import { Pagination } from '../../models/filter/BaseFilter';
import { FilterWhiteListProps } from '../vehicleList/WhiteListController';

export interface Vehicle extends BaseModel {
    permitsId: number;
    firstName: string;
    lastName: string;
    type: string;
    vrnNumber: string;
    bayNumber: string;
}

export interface VehicleRes extends Vehicle {
    statusCode?: number
}

export type VehicleFilter = Pick<FilterWhiteListProps, 'page' | 'pageSize' | 'search'> & {
    filter: { vehicleListId: number };
};

export class VehicleController extends BaseHttpController<Vehicle> {
    constructor(serviceUrl: string, client: AxiosInstance) {
        super(serviceUrl, 'Vehicle', client);
    }

    async filter(props: VehicleFilter): Promise<Pagination<Vehicle>> {
        const res = await this.doPost({ path: 'Vrn/filter', body: props, config: {} });
        return res.data;
    }

    getById(id: number) {
        return this.doGet({ path: `${id}`, config: {} }).then((res) => res.data);
    }

    async upSert(vehicles: Vehicle[]): Promise<VehicleRes[]> {
        const res = await this.doPost({ path: '', body: vehicles, config: {} });
        return res.data;
    }

    async Delete(deleteVrn: DeleteVrn): Promise<VehicleRes[]> {
        const res = await this.doPost({ path: 'delete', body: deleteVrn, config: {} });
        return res.data;
    }

    async DownloadExampleSheet(): Promise<File> {
        const res = await this.doGet({ path: `DownloadSampleSheet`, config: { responseType: 'blob' } })
        return res.data
    }
}

export interface DeleteVrn {
    vrnNumber: string,
    vehicleListId: number
}
