/* eslint-disable array-callback-return */
/* eslint-disable react-hooks/exhaustive-deps */
import { LoadingButton } from '@mui/lab';
import {
    Button,
    CircularProgress,
    Divider,
    FormControl,
    FormControlLabel,
    Grid,
    Radio,
    RadioGroup,
    Stack,
    styled,
    TextField,
    Typography,
} from '@mui/material';
import { Box } from '@mui/system';
import { useEffect } from 'react';
import { Controller, FormProvider } from 'react-hook-form';
import { useNavigate, useParams } from 'react-router-dom';
import ISelect from '../../components/ISelect';
import LabelWithRequired from '../../components/textfield/LabelWithRequired';
import WarningAddAtLeast from '../../components/WarningAddAtLeast';
import color from '../../config/Colors';
import { vehicleListController } from '../../controllers';
import { VLLogAction } from '../../models/vehicleListAction/VLLogAction';
import { GFlexRow } from '../../styles/style';
import { VehicleListPageProps } from '../vehicleList';
import { useVehicleListParams } from '../vehicleListDetails';
import InternalPermit from './components/internalPermit/InternalPermit';
import IParkForm from './components/iPark/IParkForm';
import { SearchOption } from './components/model/UpsertVehicleList';
import PermitScopeItem from './components/permitScope/PermitScopeItem';
import PortfolioScopeItem from './components/permitScope/PortfolioScopeItem';
import VLSelectCompany from './components/VLSelectCompany';
import VLSelectCompanyScope from './components/VLSelectCompanyScope';
import VLSelectLocation from './components/VLSelectLocation';
import VLSelectPortfolio from './components/VLSelectPortfolio';
import {
    scopeTypes,
    SCOPE_TYPE_CUSTOM,
    SCOPE_TYPE_GLOBAL,
    serviceTypes,
    SERVICE_TYPE_INTERNAL_PERMIT,
    SERVICE_TYPE_IPARK,
    TENANT_SCOPE_ALL,
} from './constant';
import { isCompany } from './helper';
import useVehicleListUpsert, { VehicleListUpsertContext } from './hooks/useVehicleListUpsert';
import { v4 as uuid } from 'uuid';
import { RecurringType } from '../../models/permit/PermitRecurring';
import _ from 'lodash';
import CheckBoxAllowTenantToSee from './components/CheckBoxAllowTenantToSee';
import { VehicleList } from '../../models/vehicleList/VehicleList';

export type VehicleListUpsertAction = {
    onCanceled(): void;
    onSave(): void;
    onSaveAndAddPermit: (vehicleList: VehicleList) => void;
};

export type ParentProps = VehicleListPageProps & {
    vehicleListId?: string;
    action: VehicleListUpsertAction;
};

export function VehicleListUpsert(props: ParentProps) {
    const vehicleListUpsertData = useVehicleListUpsert(props);
    const { isGlobal, methods, isEdit, setPartialSelectionState, setPartialDataState, setPartialState, ...vlData } =
        vehicleListUpsertData;
    const {
        control,
        watch,
        formState: { errors, touchedFields, isValid },
    } = methods;

    const { serviceType, scopeType, internalPermitData, isAllowTenant } = watch();
    const { isScopeValid, isAllScopeValid, isSubmitting } = vlData.scopeState;
    const { companySelects } = vlData.selectionState;
    const { companies, locations, portfolios } = vlData.dataState;
    const { searchType } = vlData.searchOption;
    const { isLoadingEditingData } = vlData.editVehicleListData;
    const { locationId } = props;
    const isShowGlobalItem = !(locationId && !isEdit);
    useEffect(() => {
        window.scrollTo({ top: 0, left: 0, behavior: 'smooth' });
    }, []);

    //set action view first time
    useEffect(() => {
        if (isEdit) {
            vehicleListController.viewAction({
                vehicleListId: Number(props.vehicleListId),
                action: VLLogAction['Viewed'],
            });
        }
    }, []);

    // console.log(vlData.listCompanySelect);
    // console.log(companySelects);

    const getPermitScope = () => {
        switch (scopeType) {
            case SCOPE_TYPE_CUSTOM:
                return (
                    <Grid item xs={12}>
                        <Stack spacing={1}>
                            {vlData.listCompanySelect?.length > 0 &&
                                vlData.listCompanySelect?.map((item, index) => {
                                    if (item) {
                                        if (isCompany(item)) {
                                            //company item
                                            return (
                                                <PermitScopeItem
                                                    key={item.id}
                                                    company={item}
                                                    companySelect={companySelects[index]}
                                                    index={index}
                                                    handleRemoveCompany={vlData.handleRemoveCompany}
                                                    isShowGlobalItem={isShowGlobalItem}
                                                />
                                            );
                                        } else {
                                            //portfolio
                                            return (
                                                <PortfolioScopeItem
                                                    key={uuid()}
                                                    index={index}
                                                    portfolio={item}
                                                    handleRemovePortfolio={vlData.handleRemovePortfolio}
                                                />
                                            );
                                        }
                                    }
                                })}
                        </Stack>
                    </Grid>
                );
            //SCOPE_TYPE_GLOBAL
            default:
                return <></>;
        }
    };

    const getServiceScope = () => {
        switch (serviceType) {
            case SERVICE_TYPE_IPARK:
                return (
                    <Grid item xs={12} container spacing={2}>
                        <IParkForm />
                    </Grid>
                );
            //SERVICE_TYPE_INTERNAL_PERMIT
            default:
                return (
                    <Grid item xs={12}>
                        <InternalPermit />
                    </Grid>
                );
        }
    };

    const handleChangePermitScope = (id: number) => {
        setPartialSelectionState({ companySelects: [] });
        //remove all company when change permit scope
        setPartialDataState({
            companies: vlData.defaultDataBE.companies.sort((x, y) => x.displayName.localeCompare(y.displayName)),
            locations: vlData.defaultDataBE.locations.sort((x, y) => x.name.localeCompare(y.name)),
            portfolios: vlData.defaultDataBE.portfolios.sort((x, y) => x.name.localeCompare(y.name)),
        });
        setPartialState({ listCompanySelect: [], isScopeValid: id === SCOPE_TYPE_GLOBAL ? true : false });
    };

    //change scope when service change
    useEffect(() => {
        if (!isLoadingEditingData) {
            let isListCompanyValid = vlData.listCompanySelect.length > 0 ? true : false;
            if (scopeType === SCOPE_TYPE_GLOBAL && serviceType !== SERVICE_TYPE_IPARK) {
                setPartialState({ isScopeValid: true });
            }
            if (serviceType === SERVICE_TYPE_IPARK) {
                methods.setValue('scopeType', SCOPE_TYPE_CUSTOM);
                setAllTenantWhenChangeScopeToIPark();
                if (!isEdit && !!isGlobal) return setPartialState({ isScopeValid: isListCompanyValid });
                if (isEdit) return setPartialState({ isScopeValid: isListCompanyValid });
            } else {
                setNoneTenantWhenChangeScopeToInternal();
                if (scopeType === SCOPE_TYPE_GLOBAL) return setPartialState({ isScopeValid: true });
                return setPartialState({ isScopeValid: isListCompanyValid });
            }
        }
    }, [serviceType, JSON.stringify(vlData.listCompanySelect), scopeType, isLoadingEditingData]);

    const setNoneTenantWhenChangeScopeToInternal = () => {
        const newCompanySelects = _.cloneDeep(companySelects);
        newCompanySelects.forEach((c) => {
            if (isCompany(c)) {
                c.locations?.forEach((l) => {
                    l.tenantScope = TENANT_SCOPE_ALL;
                    l.shops = [];
                });
            }
        });
        setPartialSelectionState({ companySelects: newCompanySelects });
    };

    const setAllTenantWhenChangeScopeToIPark = () => {
        if (!isEdit) {
            const newCompanySelects = _.cloneDeep(companySelects);
            newCompanySelects.forEach((c) => {
                if (isCompany(c)) {
                    c.locations?.forEach((l) => {
                        l.tenantScope = TENANT_SCOPE_ALL;
                        l.shops = [];
                    });
                }
            });
            setPartialSelectionState({ companySelects: newCompanySelects });
        }
    };

    // console.log(!isValid || !isScopeValid || !isAllScopeValid);
    // console.log('valid', isValid);
    // console.log('Scope valid', isScopeValid);
    // console.log('All valid', isAllScopeValid);

    const getSelection = (searchOption: SearchOption) => {
        switch (searchOption.searchType) {
            case 'company':
                return (
                    <VLSelectCompany
                        label={''}
                        listData={companies.sort((c1, c2) => c1.displayName.localeCompare(c2.displayName))}
                        keyEqual={'id'}
                        keyLabel={'displayName'}
                        setFilter={(option) => {
                            vlData.handleAddScope(searchOption.searchType, option.id);
                        }}
                        isLoading={vlData.loading.companies}
                    />
                );
            case 'location':
                return (
                    <VLSelectLocation
                        label={''}
                        listData={locations.sort((c1, c2) => c1.name.localeCompare(c2.name))}
                        keyEqual={'id'}
                        keyLabel={'name'}
                        setFilter={(option) => {
                            vlData.handleAddScope(searchOption.searchType, option.companyId!, option);
                        }}
                        isLoading={vlData.loading.locations}
                    />
                );
            default:
                return (
                    <VLSelectPortfolio
                        label={''}
                        listData={portfolios.sort((c1, c2) => c1.name.localeCompare(c2.name))}
                        keyEqual={'id'}
                        keyLabel={'name'}
                        setFilter={(option) => {
                            vlData.handleAddPortfolio(option);
                        }}
                        isLoading={vlData.loading.portfolios}
                    />
                );
        }
    };

    // console.log(portfolios);

    return (
        <VehicleListUpsertContext.Provider value={vehicleListUpsertData}>
            <FormProvider {...methods}>
                <form onSubmit={methods.handleSubmit(vlData.formSubmitHandler)}>
                    <ContainerHelperTextAbsolute sx={{ px: '12px', pt: isGlobal ? 0 : 3 }}>
                        <Grid container spacing={1.5}>
                            <Grid item xs={12}>
                                <Typography variant="h3">
                                    {isEdit ? 'Edit vehicle list' : 'Add vehicle list'}
                                </Typography>
                            </Grid>

                            <Grid item xs={12} my={1}>
                                <Controller
                                    name="nameVehicleList"
                                    control={control}
                                    render={({ field }) => {
                                        return (
                                            <TextField
                                                {...field}
                                                label={<LabelWithRequired label={'Name'} isShowRequired={true} />}
                                                placeholder={'Enter name of vehicle list'}
                                                error={Boolean(
                                                    touchedFields?.nameVehicleList && errors?.nameVehicleList
                                                )}
                                                helperText={
                                                    touchedFields?.nameVehicleList && errors.nameVehicleList?.message
                                                }
                                                onChange={(e) => field.onChange(e.target.value)}
                                                // disabled={isEdit}
                                                fullWidth
                                                size="small"
                                                inputProps={{
                                                    maxLength: 200,
                                                }}
                                            />
                                        );
                                    }}
                                />
                            </Grid>

                            <Grid item xs={12} container spacing={2}>
                                <Grid item xs={12}>
                                    <Typography
                                        sx={{
                                            color: color.grey600,
                                            fontWeight: 500,
                                            pt: 1,
                                        }}
                                    >
                                        Service settings:{' '}
                                    </Typography>
                                </Grid>
                                <Grid item xs={12}>
                                    <Controller
                                        name="serviceType"
                                        control={control}
                                        render={({ field }) => {
                                            return (
                                                <ISelect
                                                    listData={serviceTypes}
                                                    keyEqual="id"
                                                    keyLabel="name"
                                                    label="Service"
                                                    value={serviceType}
                                                    handleChange={(e) => {
                                                        field.onChange(e);
                                                        // handleChangePermitScopeWhenChangeService(e);
                                                    }}
                                                    isDisable={isEdit}
                                                />
                                            );
                                        }}
                                    />
                                </Grid>
                                {getServiceScope()}
                            </Grid>

                            {props.zoneId && (
                                <Grid item mt={2}>
                                    <CheckBoxAllowTenantToSee
                                        methods={methods}
                                        companySelects={companySelects}
                                        setPartialState={setPartialState}
                                    />
                                </Grid>
                            )}

                            {!props.zoneId || isEdit ? (
                                <>
                                    <Grid
                                        item
                                        xs={12}
                                        container
                                        mt={
                                            !!isGlobal && internalPermitData.recurringType !== RecurringType.once
                                                ? 2
                                                : internalPermitData.recurringType === RecurringType.once
                                                ? 0
                                                : 1
                                        }
                                        justifyContent="space-between"
                                        gap={2}
                                    >
                                        <GFlexRow item alignItems="center" xs="auto" minHeight={'40px'}>
                                            <Typography sx={{ color: color.grey600, fontWeight: 500 }}>
                                                Permit scopes:{' '}
                                            </Typography>

                                            {isShowGlobalItem && (
                                                <Controller
                                                    name="scopeType"
                                                    control={control}
                                                    render={({ field }) => {
                                                        return (
                                                            <FormControl sx={{ ml: 2 }}>
                                                                <RadioGroup
                                                                    row
                                                                    aria-labelledby="demo-row-radio-buttons-group-label"
                                                                    name="row-radio-buttons-group"
                                                                    value={scopeType}
                                                                    sx={{
                                                                        '& input': {},
                                                                    }}
                                                                    onChange={(e, val) => {
                                                                        handleChangePermitScope(Number(val));
                                                                        field.onChange(Number(val));
                                                                    }}
                                                                >
                                                                    {scopeTypes.map((item, index) => {
                                                                        return (
                                                                            <FormControlLabel
                                                                                key={index}
                                                                                value={item.id}
                                                                                control={<Radio />}
                                                                                label={item.name}
                                                                                disabled={
                                                                                    isLoadingEditingData ||
                                                                                    vlData.locationLoading ||
                                                                                    serviceType === SERVICE_TYPE_IPARK
                                                                                }
                                                                            />
                                                                        );
                                                                    })}
                                                                </RadioGroup>
                                                            </FormControl>
                                                        );
                                                    }}
                                                />
                                            )}
                                        </GFlexRow>

                                        {scopeType === SCOPE_TYPE_CUSTOM && isShowGlobalItem && (
                                            <Grid item xs={serviceType === SERVICE_TYPE_INTERNAL_PERMIT ? 6 : true}>
                                                <StyledSearchBox>
                                                    <VLSelectCompanyScope
                                                        listData={SelectScopes}
                                                        keyEqual="val"
                                                        keyLabel={'label'}
                                                        label=""
                                                        value={searchType}
                                                        handleChange={(e) =>
                                                            vlData.setPartialSearchOption({
                                                                searchType: e as
                                                                    | 'company'
                                                                    | 'location'
                                                                    | 'portfolio'
                                                                    | undefined,
                                                            })
                                                        }
                                                    />
                                                    <Divider
                                                        orientation="vertical"
                                                        flexItem
                                                        sx={{
                                                            mx: '5px',
                                                            borderRightWidth: 1,
                                                            borderColor: color.grey600,
                                                        }}
                                                        variant="middle"
                                                    />
                                                    {getSelection(vlData.searchOption)}
                                                </StyledSearchBox>
                                            </Grid>
                                        )}
                                        {serviceType === SERVICE_TYPE_IPARK && (
                                            <Grid item xs="auto">
                                                <CheckBoxAllowTenantToSee
                                                    methods={methods}
                                                    companySelects={companySelects}
                                                    setPartialState={setPartialState}
                                                />
                                            </Grid>
                                        )}
                                    </Grid>

                                    {scopeType === SCOPE_TYPE_CUSTOM &&
                                    !isLoadingEditingData &&
                                    companySelects.length === 0 ? (
                                        !!isGlobal && (
                                            <Grid item xs={12}>
                                                <WarningAddAtLeast text={`Please select at least one ${searchType}`} />
                                            </Grid>
                                        )
                                    ) : (
                                        <></>
                                    )}

                                    {isLoadingEditingData || vlData.locationLoading ? (
                                        <GFlexRow item xs={12} justifyContent="center" py={3} mt={!!isGlobal ? 4 : 2}>
                                            <CircularProgress color="success" />
                                        </GFlexRow>
                                    ) : (
                                        <></>
                                    )}

                                    {getPermitScope()}
                                </>
                            ) : (
                                <></>
                            )}

                            <Grid item xs={12} mt={2}>
                                <Controller
                                    name="comment"
                                    control={control}
                                    render={({ field }) => {
                                        return (
                                            <TextField
                                                {...field}
                                                label={'Comment'}
                                                placeholder={'Enter comment'}
                                                onChange={(e) => field.onChange(e.target.value)}
                                                fullWidth
                                                id="outlined-multiline-static"
                                                multiline
                                                rows={2}
                                                sx={{ '& .MuiOutlinedInput-root': { padding: '4px 0' } }}
                                                size="small"
                                            />
                                        );
                                    }}
                                />
                            </Grid>

                            <Grid
                                display={'flex'}
                                flexDirection="row"
                                justifyContent={'space-between'}
                                item
                                xs={12}
                                mt={10}
                            >
                                <Button sx={{ minWidth: '200px' }} variant="cancel" onClick={props.action.onCanceled}>
                                    Cancel
                                </Button>
                                <Stack direction={'row'} gap={1}>
                                    {serviceType === SERVICE_TYPE_INTERNAL_PERMIT && (
                                        <LoadingButton
                                            loading={isSubmitting}
                                            loadingPosition="start"
                                            disabled={!isValid || !isScopeValid || !isAllScopeValid}
                                            startIcon={<></>}
                                            variant="outlined"
                                            sx={{
                                                minWidth: '200px',
                                            }}
                                            type="submit"
                                        >
                                            Save only
                                        </LoadingButton>
                                    )}

                                    {serviceType === SERVICE_TYPE_INTERNAL_PERMIT ? (
                                        <LoadingButton
                                            loading={isSubmitting}
                                            loadingPosition="start"
                                            disabled={!isValid || !isScopeValid || !isAllScopeValid}
                                            onClick={() => {
                                                vlData.handleCreateVehicleList(methods.getValues(), true);
                                            }}
                                            startIcon={<></>}
                                            variant="contained"
                                            sx={{ minWidth: '200px' }}
                                        >
                                            Save and add permits
                                        </LoadingButton>
                                    ) : (
                                        <LoadingButton
                                            loading={isSubmitting}
                                            loadingPosition="start"
                                            disabled={!isValid || !isScopeValid || !isAllScopeValid}
                                            startIcon={<></>}
                                            variant="contained"
                                            sx={{ minWidth: '200px' }}
                                            type="submit"
                                        >
                                            Save
                                        </LoadingButton>
                                    )}
                                </Stack>
                            </Grid>
                        </Grid>
                    </ContainerHelperTextAbsolute>
                </form>
            </FormProvider>
        </VehicleListUpsertContext.Provider>
    );
}

export function WrapVehicleListUpsert() {
    const { locationId, zoneId, path } = useVehicleListParams();
    const { idVehicleList } = useParams();
    const navigate = useNavigate();

    return (
        <VehicleListUpsert
            action={{
                onCanceled() {
                    navigate(-1);
                },
                onSaveAndAddPermit(vehicleListId) {
                    navigate(`/${path}/vehicle-list/${vehicleListId}/vrn/add`);
                },
                onSave() {
                    navigate(-1);
                },
            }}
            locationId={locationId}
            zoneId={zoneId}
            vehicleListId={idVehicleList}
        />
    );
}

const StyledSearchBox = styled(Box)({
    width: '100%',
    display: 'flex',
    justifyContent: 'space-between',
    '& .MuiBox-root': {
        display: 'flex',
        alignItems: 'center',
    },
    border: `1px solid ${color.grey400}`,
    borderRadius: '5px',
    outlineOffset: '-1px',
    height: '40px',
    paddingRight: '8px',
});

const ContainerHelperTextAbsolute = styled(Box)({
    '& .MuiFormHelperText-root': {
        position: 'absolute',
        top: 38,
        left: -12,
    },
    minHeight: 'calc(50vh)',
    paddingBottom: '30px',
    marginBottom: '24px',
});

const SelectScopes: ScopeSelect[] = [
    {
        val: 'company',
        label: 'Company',
    },
    {
        val: 'location',
        label: 'Location',
    },
    {
        val: 'portfolio',
        label: 'Portfolio',
    },
];

interface ScopeSelect {
    val: string;
    label: string;
}
