/* eslint-disable array-callback-return */
import _ from 'lodash';
import { useEffect, useState } from 'react';
import { SelectionState, UnionScope } from '../components/model/UpsertVehicleList';
import { COMPANY_SCOPE_ALL, COMPANY_SCOPE_CUSTOM } from '../components/permitScope/PermitScopeItem';
import { LOCATION_SCOPE_ALL, LOCATION_SCOPE_CUSTOM } from '../constant';
import { isCompany, isPortfolio } from '../helper';
import { ScopeState } from './useVehicleListUpsert';

type Props = {
    isAllScopeValid: boolean;
    setPartialState: (partial: Partial<ScopeState>) => void;
};

export default function useVehicleListSelection({ isAllScopeValid, setPartialState }: Props) {
    const [selectionState, SetSelectionState] = useState<SelectionState>({ companySelects: [] });

    useEffect(() => {
        let isValid = true;
        selectionState.companySelects.map((u) => {
            // portfolio
            if (isPortfolio(u)) {
                isValid = true;
                return;
                //company
            } else {
                if (u.companyScope === COMPANY_SCOPE_CUSTOM && u.locations!.length === 0) {
                    isValid = false;
                    return;
                } else {
                    u.locations!.map((location) => {
                        if (location.locationScope === LOCATION_SCOPE_CUSTOM && location.zones!.length === 0) {
                            isValid = false;
                            return;
                        }
                    });
                }
            }
        });
        setPartialState({ isAllScopeValid: isValid });
        // eslint-disable-next-line react-hooks/exhaustive-deps
    }, [JSON.stringify(selectionState)]);

    const setPartialSelectionState = (partial: Partial<SelectionState>) => {
        SetSelectionState((prev) => ({ ...prev, ...partial }));
    };

    const getCompanyScope = (unionType: UnionScope) => {
        if (isPortfolio(unionType)) {
            return COMPANY_SCOPE_ALL;
        } else {
            const index = selectionState.companySelects.findIndex((i) => {
                if (isCompany(i)) return i.id === unionType.id;
            });
            if (index >= 0) {
                const item = selectionState.companySelects[index];
                if (isCompany(item)) return item.companyScope!;
                else return COMPANY_SCOPE_ALL;
            } else return COMPANY_SCOPE_ALL;
        }
    };

    const setCompanyScope = (unionType: UnionScope, scope: number) => {
        const arrClone = _.cloneDeep(selectionState.companySelects);
        if (isCompany(unionType)) {
            const index = selectionState.companySelects.findIndex((i) => i.id === unionType.id);
            if (index >= 0) {
                const item = arrClone[index];
                if (isCompany(item)) {
                    item.companyScope = scope;
                    item.locations = [];
                    setPartialSelectionState({ companySelects: arrClone });
                }
            }
        }
    };

    const getLocationScope = (idCompany: number, idLocation: number) => {
        const arrClone = _.cloneDeep(selectionState.companySelects);
        const indexCompany = arrClone.findIndex((i) => {
            if (isCompany(i)) return i.id === idCompany;
        });
        if (indexCompany >= 0) {
            const item = arrClone[indexCompany];
            if (isCompany(item)) {
                const arrLocation = item.locations!;
                const indexLocation = arrLocation.findIndex((i) => i.id === idLocation);
                if (indexLocation >= 0) {
                    return arrLocation[indexLocation].locationScope!;
                }
            }
        }
        return 1;
    };

    const setLocationScope = (idCompany: number, idLocation: number, scope: number) => {
        const arrClone = _.cloneDeep(selectionState.companySelects);
        const indexCompany = arrClone.findIndex((i) => {
            if (isCompany(i)) return i.id === idCompany;
        });
        if (indexCompany >= 0) {
            const item = arrClone[indexCompany];
            if (isCompany(item)) {
                const arrLocation = item.locations!;
                const indexLocation = arrLocation.findIndex((i) => i.id === idLocation);
                if (indexLocation >= 0) {
                    arrLocation[indexLocation].locationScope = scope;
                    if (scope === LOCATION_SCOPE_ALL) {
                        arrLocation[indexLocation].zones = [];
                    }
                }
            }
        }
        setPartialSelectionState({ companySelects: arrClone });
    };

    const getTenantScope = (idCompany: number, idLocation: number) => {
        const arrClone = _.cloneDeep(selectionState.companySelects);
        const indexCompany = arrClone.findIndex((i) => {
            if (isCompany(i)) return i.id === idCompany;
        });
        if (indexCompany >= 0) {
            const item = arrClone[indexCompany];
            if (isCompany(item)) {
                const arrLocation = item.locations!;
                const indexLocation = arrLocation.findIndex((i) => i.id === idLocation);
                if (indexLocation >= 0) {
                    return arrLocation[indexLocation].tenantScope!;
                }
            }
        }
        return 1;
    };

    const setTenantScope = (idCompany: number, idLocation: number, scope: number) => {
        const arrClone = _.cloneDeep(selectionState.companySelects);
        const indexCompany = arrClone.findIndex((i) => {
            if (isCompany(i)) return i.id === idCompany;
        });
        if (indexCompany >= 0) {
            const item = arrClone[indexCompany];
            if (isCompany(item)) {
                const arrLocation = item.locations!;
                const indexLocation = arrLocation.findIndex((i) => i.id === idLocation);
                if (indexLocation >= 0) {
                    arrLocation[indexLocation].tenantScope = scope;
                    if (scope === LOCATION_SCOPE_ALL) {
                        arrLocation[indexLocation].shops = [];
                    }
                }
            }
        }
        setPartialSelectionState({ companySelects: arrClone });
    };

    return {
        selectionState,
        setPartialSelectionState,
        getCompanyScope,
        setCompanyScope,
        setLocationScope,
        getLocationScope,
        getTenantScope,
        setTenantScope,
    };
}
