/* eslint-disable eqeqeq */
/* eslint-disable react-hooks/exhaustive-deps */
import {
    Box,
    FormControl,
    FormControlLabel,
    Grid,
    Radio,
    RadioGroup,
    Stack,
    TextField,
    Typography,
} from '@mui/material';
import { DatePicker, LocalizationProvider, PickersDay } from '@mui/x-date-pickers';
import { AdapterDateFns } from '@mui/x-date-pickers/AdapterDateFns';
import { addDays } from 'date-fns';
import moment from 'moment';
import React, { useContext, useEffect } from 'react';
import { Controller } from 'react-hook-form';
import CalendarIcon from '../../../../components/svg/CalendarIcon';
import LabelWithRequired from '../../../../components/textfield/LabelWithRequired';
import color from '../../../../config/Colors';
import { ValidateToType } from '../../../../models/permit/Permit';
import { REGIS_TYPE_FIXED_TIME, REGIS_TYPE_SPECIFIC_DURATION } from '../../constant';
import { getTimeFromRegex } from '../../helper';
import { VehicleListUpsertContext } from '../../hooks/useVehicleListUpsert';
import { useStylesPicker } from '../internalPermit/InternalPermitDateRange';

export default function IParkForm() {
    const {
        methods,
        isEdit,
        editVehicleListData: { permit },
    } = useContext(VehicleListUpsertContext);
    const {
        control,
        watch,
        formState: { errors },
        setError,
        setValue,
        clearErrors,
    } = methods;
    const classes = useStylesPicker();

    const { regisType, fixedTime, endStatus, validateFrom, validateTo } = watch().iParkData;

    useEffect(() => {
        if (validateFrom === null) {
            setError('iParkData.validateFrom', { type: 'required', message: 'This field is required' });
        }
    }, [validateFrom]);

    useEffect(() => {
        if (endStatus === ValidateToType.absoluteAt && validateTo === null) {
            setValue('iParkData.validateTo', null, {
                shouldTouch: true,
                shouldValidate: true,
            });
        }
        if (isEdit && endStatus === ValidateToType.infinite) {
            setValue('iParkData.validateTo', moment(validateFrom!).add(1, 'd').toDate(), {
                shouldTouch: true,
                shouldValidate: true,
            });
        }
    }, [endStatus]);

    return (
        <LocalizationProvider dateAdapter={AdapterDateFns}>
            <Grid item xs={12}>
                <Stack display={'flex'} direction={'row'} alignItems={'center'}>
                    <Controller
                        name="iParkData.regisType"
                        control={control}
                        render={({ field }) => {
                            return (
                                <FormControl sx={{ ml: 2 }}>
                                    <RadioGroup
                                        row
                                        aria-labelledby="demo-row-radio-buttons-group-label"
                                        name="row-radio-buttons-group"
                                        value={regisType}
                                        onChange={(e, val) => {
                                            if (!isEdit) {
                                                field.onChange(Number(val));
                                            }
                                        }}
                                    >
                                        <FormControlLabel
                                            value={REGIS_TYPE_SPECIFIC_DURATION}
                                            control={<Radio />}
                                            label={
                                                <Controller
                                                    name="iParkData.specificTime"
                                                    control={control}
                                                    render={({ field }) => {
                                                        return (
                                                            <TextField
                                                                {...field}
                                                                label={
                                                                    <LabelWithRequired
                                                                        label={'Max days'}
                                                                        isShowRequired={true}
                                                                    />
                                                                }
                                                                type={'number'}
                                                                placeholder={
                                                                    'Please enter max days customer can register'
                                                                }
                                                                error={
                                                                    Boolean(errors?.iParkData?.specificTime) &&
                                                                    regisType === REGIS_TYPE_SPECIFIC_DURATION
                                                                }
                                                                helperText={
                                                                    regisType === REGIS_TYPE_SPECIFIC_DURATION
                                                                        ? errors?.iParkData?.specificTime?.message
                                                                        : ''
                                                                }
                                                                onChange={(e) => {
                                                                    field.onChange(Number(e.target.value));
                                                                }}
                                                                // disabled={isEdit}
                                                                fullWidth
                                                                size="small"
                                                                inputProps={{
                                                                    maxLength: 100,
                                                                }}
                                                                disabled={regisType === 1 || isEdit}
                                                            />
                                                        );
                                                    }}
                                                />
                                            }
                                        />
                                        <FormControlLabel
                                            value={REGIS_TYPE_FIXED_TIME}
                                            control={<Radio />}
                                            label={
                                                <Controller
                                                    name="iParkData.fixedTime"
                                                    control={control}
                                                    render={({ field }) => {
                                                        return (
                                                            <TextField
                                                                {...field}
                                                                className="helper-text-absolute"
                                                                label={
                                                                    <LabelWithRequired
                                                                        label={'Permit time'}
                                                                        isShowRequired={true}
                                                                    />
                                                                }
                                                                placeholder={'Use the format: 4d 6h'}
                                                                error={
                                                                    Boolean(errors?.iParkData?.fixedTime) &&
                                                                    regisType === REGIS_TYPE_FIXED_TIME
                                                                }
                                                                helperText={
                                                                    regisType === REGIS_TYPE_FIXED_TIME
                                                                        ? errors?.iParkData?.fixedTime?.message
                                                                        : ''
                                                                }
                                                                onChange={(e) => field.onChange(e.target.value)}
                                                                // disabled={isEdit}
                                                                fullWidth
                                                                size="small"
                                                                inputProps={{
                                                                    maxLength: 100,
                                                                }}
                                                                disabled={regisType === 2}
                                                            />
                                                        );
                                                    }}
                                                />
                                            }
                                        />
                                    </RadioGroup>
                                </FormControl>
                            );
                        }}
                    />
                    <Typography display={regisType === REGIS_TYPE_FIXED_TIME ? 'block' : 'none'}>
                        Total hours: {getTimeFromRegex(fixedTime) ? getTimeFromRegex(fixedTime)! / (60 * 60) : 0}
                    </Typography>
                </Stack>
                <Grid item xs={12} mt={3} container mb={Boolean(errors.iParkData?.validateTo) ? 1 : 0}>
                    <Grid item xs="auto">
                        <Controller
                            name="iParkData.validateFrom"
                            control={control}
                            render={({ field, fieldState, formState }) => {
                                return (
                                    <DatePicker
                                        label={<LabelWithRequired label={'Start date'} isShowRequired={true} />}
                                        value={validateFrom}
                                        onChange={(newValue, _) => {
                                            if (newValue != null) {
                                                field.onChange(newValue);
                                                setValue('iParkData.validateTo', null, {
                                                    shouldTouch: true,
                                                    shouldValidate: true,
                                                });
                                            }
                                        }}
                                        disabled={isEdit}
                                        components={{
                                            OpenPickerIcon: CalendarIcon,
                                        }}
                                        OpenPickerButtonProps={{ className: classes.inputStyle }}
                                        renderInput={(params) => (
                                            <TextField
                                                {...params}
                                                error={Boolean(errors.iParkData?.validateFrom)}
                                                helperText={errors.iParkData?.validateFrom?.message}
                                                fullWidth={true}
                                                sx={{
                                                    '& .MuiInputBase-input': {
                                                        caretColor: 'transparent',
                                                    },
                                                    width: '330px',
                                                }}
                                                onKeyDown={(e) => {
                                                    e.preventDefault();
                                                }}
                                            />
                                        )}
                                        renderDay={(day, _value, DayComponentProps) => {
                                            return (
                                                <Box className={classes.inputStyle} key={JSON.stringify(day)}>
                                                    <PickersDay {...DayComponentProps} />
                                                </Box>
                                            );
                                        }}
                                        inputFormat="dd/MM/yyyy"
                                        minDate={new Date()}
                                        PopperProps={{
                                            placement: 'bottom-start',
                                        }}
                                    />
                                );
                            }}
                        />
                    </Grid>

                    <Grid item xs={7}>
                        <Controller
                            name="iParkData.endStatus"
                            control={control}
                            render={({ field }) => {
                                return (
                                    <RadioGroup
                                        aria-labelledby="demo-radio-buttons-group-label"
                                        value={endStatus}
                                        name="radio-buttons-group"
                                        onChange={(e: any, val) => {
                                            const validTo = val != '0' ? addDays(validateFrom!, 1) : null;
                                            // setFieldTouched('validateTo');
                                            field.onChange(Number(val));
                                            setValue('iParkData.validateTo', validTo, { shouldTouch: true });
                                            if (Number(val) !== 0) {
                                                clearErrors('iParkData.validateTo');
                                            }
                                        }}
                                        color={color.success}
                                        sx={{
                                            '& .MuiButtonBase-root': { padding: '5px' },
                                            '& .MuiFormControlLabel-root': {
                                                ml: 0,
                                            },
                                        }}
                                    >
                                        <Grid item direction="row" alignItems={'center'} container pl={2} gap={2}>
                                            <Grid
                                                item
                                                container
                                                display={'flex'}
                                                direction={'row'}
                                                alignItems="center"
                                                xs={'auto'}
                                                gap={2}
                                                // mt={{ xs: 1, sm: 0 }}
                                            >
                                                <Grid xs={'auto'} item>
                                                    <FormControlLabel
                                                        sx={{
                                                            '&.MuiFormControlLabel-labelPlacementEnd': {
                                                                marginRight: '0px !important',
                                                            },
                                                        }}
                                                        value={ValidateToType.absoluteAt}
                                                        disabled={validateFrom === null}
                                                        control={<Radio />}
                                                        label={<Typography variant="body1">{'End date'}</Typography>}
                                                    />
                                                </Grid>
                                                <Grid item>
                                                    <DatePicker
                                                        label={
                                                            <LabelWithRequired
                                                                label={'End date'}
                                                                isShowRequired={true}
                                                            />
                                                        }
                                                        value={endStatus != 0 ? null : validateTo}
                                                        onChange={(newValue) => {
                                                            if (newValue != null) {
                                                                // setFieldTouched('validateTo');
                                                                setValue('iParkData.validateTo', newValue, {
                                                                    shouldTouch: true,
                                                                    shouldValidate: true,
                                                                });
                                                            }
                                                        }}
                                                        disabled={
                                                            endStatus !== ValidateToType.absoluteAt ||
                                                            validateFrom === null
                                                        }
                                                        components={{
                                                            OpenPickerIcon: CalendarIcon,
                                                        }}
                                                        inputFormat="dd/MM/yyyy"
                                                        minDate={validateFrom}
                                                        renderInput={(params) => (
                                                            <TextField
                                                                {...params}
                                                                error={Boolean(errors.iParkData?.validateTo)}
                                                                helperText={errors.iParkData?.validateTo?.message}
                                                                sx={{
                                                                    '& .MuiInputBase-input': {
                                                                        caretColor: 'transparent',
                                                                    },
                                                                    minWidth: '330px',
                                                                }}
                                                                onKeyDown={(e) => {
                                                                    e.preventDefault();
                                                                }}
                                                                fullWidth={true}
                                                            />
                                                        )}
                                                        renderDay={(day, _value, DayComponentProps) => {
                                                            return (
                                                                <Box
                                                                    className={classes.inputStyle}
                                                                    key={JSON.stringify(day)}
                                                                >
                                                                    <PickersDay {...DayComponentProps} />
                                                                </Box>
                                                            );
                                                        }}
                                                        PopperProps={{
                                                            placement: 'bottom-start',
                                                        }}
                                                    />
                                                </Grid>
                                            </Grid>
                                            <Grid xs={3} item>
                                                <FormControlLabel
                                                    disabled={validateFrom === null}
                                                    value={ValidateToType.infinite}
                                                    control={<Radio />}
                                                    label={'No end date'}
                                                    sx={{ paddingTop: 0 }}
                                                />
                                            </Grid>
                                        </Grid>
                                    </RadioGroup>
                                );
                            }}
                        />
                    </Grid>
                </Grid>
            </Grid>
        </LocalizationProvider>
    );
}
