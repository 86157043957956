import { ArrowDropDown } from '@material-ui/icons';
import { Stack, Typography, IconButton, Collapse, Box, Grid, TextField } from '@mui/material';
import React, { useState } from 'react';
import { Controller } from 'react-hook-form';
import RemoveIcon from '../../components/svg/RemoveIcon';
import color from '../../config/Colors';
import { StyledBaseItem } from '../vehicleList/components/VehicleListItem';

type Props = {
    index: number;
    remove: any;
    control: any;
    total: number;
    isNotCompleted: boolean;
    onChangePermitType(text: string): void;
    disabled?: boolean;
};

const suggests = ['Blue Badge', 'Students', 'Contractors', 'Residential', 'VIP', 'Visitor', 'Staff'];
const BAY_INFO_LIMIT_CHARACTER = 40;

export function PermitItem({ index, remove, control, total, onChangePermitType, isNotCompleted, ...props }: Props) {
    const [open, setOpen] = useState(true);
    const [isFocused, setIsFocused] = useState(false);

    return (
        <StyledBaseItem
            style={{
                padding: '16px',
            }}
        >
            <Stack>
                <Stack
                    direction={'row'}
                    alignItems="center"
                    justifyContent={'space-between'}
                    sx={{
                        height: 24,
                        cursor: 'pointer',
                    }}
                    onClick={(e) => {
                        e.preventDefault();
                        e.stopPropagation();
                        setOpen(!open);
                    }}
                >
                    <Stack direction={'row'}>
                        <ArrowDropDown />
                        <Typography variant="h6">Permit {index + 1}</Typography>

                        {isNotCompleted && (
                            <Typography ml={1} color="error.main">
                                (Not completed)
                            </Typography>
                        )}
                    </Stack>

                    <Stack>
                        {total > 1 && (
                            <IconButton
                                onClick={() => remove(index)}
                                aria-label="fingerprint"
                                color="success"
                                sx={{
                                    '& .svg-icon-stroke': {
                                        stroke: color.danger,
                                    },
                                    '&:hover': {
                                        backgroundColor: color.grey200,
                                    },
                                    width: '32px',
                                    height: '32px',
                                    padding: '4px',
                                }}
                            >
                                <RemoveIcon width={'30px'} height={'30px'} />
                            </IconButton>
                        )}
                    </Stack>
                </Stack>

                <Collapse in={open}>
                    <Box mt={2}>
                        <Grid container spacing={2}>
                            <Grid item xs={4}>
                                <Controller
                                    control={control}
                                    name={`vrns.${index}.vrnNumber`}
                                    render={({ field, fieldState }) => (
                                        <TextField
                                            {...field}
                                            autoComplete="off"
                                            required
                                            fullWidth
                                            label={'Reg number'}
                                            placeholder="Enter reg number"
                                            error={!!fieldState.error?.message}
                                            helperText={fieldState.error?.message}
                                            disabled={props.disabled}
                                        />
                                    )}
                                />
                            </Grid>
                            <Grid item xs={4}>
                                <Controller
                                    control={control}
                                    name={`vrns.${index}.firstName`}
                                    render={({ field, fieldState }) => (
                                        <TextField
                                            {...field}
                                            autoComplete="off"
                                            fullWidth
                                            label={'First name'}
                                            placeholder="Enter first name"
                                            error={!!fieldState.error?.message}
                                            helperText={fieldState.error?.message}
                                        />
                                    )}
                                />
                            </Grid>
                            <Grid item xs={4}>
                                <Controller
                                    control={control}
                                    name={`vrns.${index}.lastName`}
                                    render={({ field, fieldState }) => (
                                        <TextField
                                            {...field}
                                            autoComplete="off"
                                            fullWidth
                                            label={'Last name'}
                                            placeholder="Enter last name"
                                            error={!!fieldState.error?.message}
                                            helperText={fieldState.error?.message}
                                        />
                                    )}
                                />
                            </Grid>

                            <Grid item xs={8}>
                                <Controller
                                    control={control}
                                    name={`vrns.${index}.type`}
                                    render={({ field, fieldState }) => (
                                        <Stack sx={{ position: 'relative' }}>
                                            <TextField
                                                {...field}
                                                autoComplete="off"
                                                // required
                                                fullWidth
                                                label={'Permit type'}
                                                placeholder="Enter type for permit"
                                                // error={!!fieldState.error?.message}
                                                // helperText={fieldState.error?.message || ''}
                                                sx={{
                                                    '& .MuiFormHelperText-root': {
                                                        height: fieldState.error?.message ? '19.92px' : 0,
                                                        transition: 'all 0.3s',
                                                    },
                                                }}
                                            />

                                            <Typography
                                                color="GrayText"
                                                sx={{
                                                    fontSize: 12,
                                                    // pl: '14px',
                                                    pt: '4px',
                                                    fontWeight: 500,
                                                }}
                                            >
                                                Suggest:{' '}
                                                {suggests.map((t, i, arr) => (
                                                    <Typography
                                                        color="GrayText"
                                                        key={t}
                                                        component={'span'}
                                                        sx={{
                                                            fontSize: 12,
                                                            fontWeight: 400,
                                                        }}
                                                    >
                                                        <Typography
                                                            component={'span'}
                                                            color="GrayText"
                                                            sx={{
                                                                cursor: 'pointer',
                                                                fontSize: 12,
                                                                ':hover': {
                                                                    color: color.secondary,
                                                                    textDecoration: 'underline',
                                                                },
                                                            }}
                                                            onClick={() => {
                                                                onChangePermitType(t);
                                                            }}
                                                        >
                                                            {t}
                                                        </Typography>
                                                        {i !== arr.length - 1 && ', '}
                                                    </Typography>
                                                ))}
                                            </Typography>
                                        </Stack>
                                    )}
                                />
                            </Grid>
                            <Grid
                                item
                                xs={4}
                                sx={{
                                    '& .MuiFormHelperText-root': {
                                        position: 'absolute',
                                        bottom: -20,
                                        right: '-10px',
                                        fontSize: '12px',
                                    },
                                }}
                            >
                                <Controller
                                    control={control}
                                    name={`vrns.${index}.bayNumber`}
                                    render={({ field, fieldState }) => (
                                        <TextField
                                            {...field}
                                            onFocus={() => setIsFocused(true)}
                                            onBlur={() => setIsFocused(false)}
                                            autoComplete="off"
                                            fullWidth
                                            label={'Bay information'}
                                            placeholder="Enter bay information"
                                            // error={!!fieldState.error?.message}
                                            helperText={
                                                isFocused && `${field.value.length ?? 0}/${BAY_INFO_LIMIT_CHARACTER}`
                                            }
                                            inputProps={{
                                                maxlength: 40,
                                            }}
                                        />
                                    )}
                                />
                            </Grid>
                        </Grid>
                    </Box>
                </Collapse>
            </Stack>
        </StyledBaseItem>
    );
}
