import { BaseHttpController } from 'ale-base-model';
import { AxiosInstance } from 'axios';
import { FilterLocation, Pagination } from '../../models/filter/BaseFilter';
import { Location, LocationWithDetail } from '../../models/scope/Location';

export class LocationController extends BaseHttpController<Location> {
    constructor(serviceUrl: string, client: AxiosInstance) {
        super(serviceUrl, 'Location', client);
    }

    async getAll(filter: FilterLocation, address?: string): Promise<Pagination<Location>> {
        const res = await this.doPost({ path: 'getList', body: filter, config: {} });
        return res.data;
    }

    async getLocation(idLocation: number): Promise<LocationWithDetail> {
        const res = await this.client.get(`${this.serviceURL}/Location/${idLocation}`);
        return res.data;
    }

    async filter(filter: FilterLocation): Promise<Pagination<LocationWithDetail>> {
        const res = await this.doPost({ path: 'filter', body: filter, config: {} });
        return res.data;
    }
}
