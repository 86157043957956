import { BaseModel } from "../BaseModel";

export interface Scope extends BaseModel {
    permitId?: number
    vehicleListId?: number,
    scopeType: ScopeType,
    scopeId: number | null
    parentScope?: Scope

}

export enum ScopeType {
    global = 0,
    company = 1,
    region = 2,
    location = 3,
    zone = 4,
    portfolio = 5,
    tenant = 6
}