import { Stack, Typography } from '@mui/material';
import React from 'react';
import { useFieldArray, UseFormReturn } from 'react-hook-form';
import { PermitItem } from '../PermitItem';
import { pick } from 'lodash';
import { Vehicle } from '../../../controllers/vehicle/VehicleController';
import { FormDataVRN } from '../AddVRN';

type Props = {
    formData: UseFormReturn<FormDataVRN, any>;
    item?: Vehicle;
    defaultPermit: any;
    handleChangePermitType: (text: string, index: number) => void;
    isAddPage: boolean;
};

export default function ListVRNAddItem(props: Props) {
    const { control, watch } = props.formData;

    const { fields, append, remove } = useFieldArray({
        control,
        name: 'vrns',
    });

    return (
        <Stack mt={2} spacing={2}>
            {fields.map((f, index, arr) => {
                const isNotCompleted = Object.values(pick(watch(`vrns.${index}`), ['vrnNumber'])).some((v) => !v);

                return (
                    <PermitItem
                        key={f.id}
                        index={index}
                        remove={remove}
                        control={control}
                        onChangePermitType={(text) => props.handleChangePermitType(text, index)}
                        total={arr.length}
                        isNotCompleted={isNotCompleted}
                        disabled={!props.isAddPage}
                    />
                );
            })}

            {!props.item && (
                <Stack>
                    <Typography
                        color="success.main"
                        sx={{ cursor: 'pointer' }}
                        onClick={() => {
                            append(props.defaultPermit);
                        }}
                    >
                        + Add permit
                    </Typography>
                </Stack>
            )}
        </Stack>
    );
}
