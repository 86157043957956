import * as React from 'react';

function EditIcon3(props: any) {
    return (
        <svg width={20} height={19} viewBox="0 0 20 19" fill="none" xmlns="http://www.w3.org/2000/svg" {...props}>
            <path
                d="M9.39 1.035H6.023c-2.77 0-4.507 1.961-4.507 4.737v7.49C1.515 16.04 3.244 18 6.022 18h7.95c2.779 0 4.508-1.961 4.508-4.738V9.634"
                stroke="currentColor"
                strokeWidth={1.5}
                strokeLinecap="round"
                strokeLinejoin="round"
            />
            <path
                clipRule="evenodd"
                d="M6.99 8.36l6.732-6.732a2.149 2.149 0 013.037 0l1.097 1.097a2.147 2.147 0 010 3.037l-6.765 6.764a1.955 1.955 0 01-1.383.573H6.333l.085-3.405c.013-.501.217-.978.572-1.333z"
                stroke="currentColor"
                strokeWidth={1.5}
                strokeLinecap="round"
                strokeLinejoin="round"
            />
            <path
                d="M12.7 2.669l4.113 4.113"
                stroke="currentColor"
                strokeWidth={1.5}
                strokeLinecap="round"
                strokeLinejoin="round"
            />
        </svg>
    );
}

export default EditIcon3;
