import React, { SVGProps } from 'react';

type Props = {} & SVGProps<SVGSVGElement>;

export default function CalendarIcon(props: Props) {
    return (
        <svg {...props} width="16" height="16" viewBox="0 0 16 16" fill="none" xmlns="http://www.w3.org/2000/svg">
            <path
                d="M12 3.16675H4.00004C2.80342 3.16675 1.83337 4.1368 1.83337 5.33341V12.0001C1.83337 13.1967 2.80342 14.1667 4.00004 14.1667H12C13.1967 14.1667 14.1667 13.1967 14.1667 12.0001V5.33341C14.1667 4.1368 13.1967 3.16675 12 3.16675Z"
                stroke="#85858A"
            />
            <path d="M4.5 1.83325V4.16659" stroke="#85858A" strokeLinecap="round" />
            <path d="M2.5 6.16675H13.5" stroke="#85858A" strokeLinecap="round" />
            <path d="M11.8334 1.83325V4.16659" stroke="#85858A" strokeLinecap="round" />
            <path
                d="M5.5 9.33325C5.77614 9.33325 6 9.10939 6 8.83325C6 8.55711 5.77614 8.33325 5.5 8.33325C5.22386 8.33325 5 8.55711 5 8.83325C5 9.10939 5.22386 9.33325 5.5 9.33325Z"
                fill="#85858A"
            />
            <path
                d="M8.16663 9.33325C8.44277 9.33325 8.66663 9.10939 8.66663 8.83325C8.66663 8.55711 8.44277 8.33325 8.16663 8.33325C7.89048 8.33325 7.66663 8.55711 7.66663 8.83325C7.66663 9.10939 7.89048 9.33325 8.16663 9.33325Z"
                fill="#85858A"
            />
            <path
                d="M10.8334 9.33325C11.1095 9.33325 11.3334 9.10939 11.3334 8.83325C11.3334 8.55711 11.1095 8.33325 10.8334 8.33325C10.5572 8.33325 10.3334 8.55711 10.3334 8.83325C10.3334 9.10939 10.5572 9.33325 10.8334 9.33325Z"
                fill="#85858A"
            />
            <path
                d="M5.5 12C5.77614 12 6 11.7761 6 11.5C6 11.2239 5.77614 11 5.5 11C5.22386 11 5 11.2239 5 11.5C5 11.7761 5.22386 12 5.5 12Z"
                fill="#85858A"
            />
            <path
                d="M8.16663 12C8.44277 12 8.66663 11.7761 8.66663 11.5C8.66663 11.2239 8.44277 11 8.16663 11C7.89048 11 7.66663 11.2239 7.66663 11.5C7.66663 11.7761 7.89048 12 8.16663 12Z"
                fill="#85858A"
            />
            <path
                d="M10.8334 12C11.1095 12 11.3334 11.7761 11.3334 11.5C11.3334 11.2239 11.1095 11 10.8334 11C10.5572 11 10.3334 11.2239 10.3334 11.5C10.3334 11.7761 10.5572 12 10.8334 12Z"
                fill="#85858A"
            />
        </svg>
    );
}
