import { BaseHttpController } from 'ale-base-model';
import { AxiosInstance } from 'axios';
import { Pagination } from '../../models/filter/BaseFilter';
import { PermitTypeVl } from '../../models/permit/Permit';
import { VehicleList, VehicleListWithZone } from '../../models/vehicleList/VehicleList';
import { VLViewAction } from '../../models/vehicleListAction/VLViewAction';
import { FilterHistoryAction } from '../../models/vehicleListAction/VehicleListHistoryLogFilter';
import { VehicleListLogItem } from '../../models/vehicleListAction/VehicleListLogItem';

export interface FilterVehicleListByPermitTypeProps {
    permitType?: number;
    indefinitePage?: number;
    temporaryPage?: number;
    oneDayPage?: number;
    hasChangedPermitTypePage?: boolean;
}

export interface FilterVehicleListProps {
    page: number;
    pageSize: number;
    search: Search;
    filter: Filter;
}

export interface Search {
    fields: string[];
    value: string;
}

export interface Filter {
    companyId: number;
    regionId: number;
    locationId: number;
    zoneIds: number[];
    tenantIds: number[];
    serviceId: number;
    scopeType: number;
    status: number;
    vrn?: string;
    zoneReference?: string;
    permitTypeVl?: PermitTypeVl;
}

export class VehicleListController extends BaseHttpController<VehicleList> {
    constructor(serviceUrl: string, client: AxiosInstance) {
        super(serviceUrl, '', client);
    }

    // async getAll(): Promise<Service[]> {
    //     const res = await this.doGet({ path: 'getAll', config: {} })
    //     return res.data
    // }

    getById(id: number): Promise<VehicleListWithZone> {
        return this.client.get(`/${id}`).then((res) => res.data);
    }

    remove(id: number): Promise<VehicleListWithZone> {
        return this.client.delete(`${id}`).then((res) => res.data);
    }

    async filter(props: FilterVehicleListProps): Promise<Pagination<VehicleListWithZone>> {
        const res = await this.client.post(`Filter`, props);
        return res.data;
    }

    async filterVrn(props: FilterVehicleListProps): Promise<Pagination<VehicleListWithZone>> {
        // const res = await this.doPost({ path: 'FilterVrn', body: props, config: {} });
        const res = await this.client.post('FilterVrn', props);
        return res.data;
    }

    async changeStatus(id: number) {
        // const res = await this.doPost({ path: `ChangeStatus/${id}`, config: {} });
        const res = await this.client.post(`ChangeStatus/${id}`);
        return res.data;
    }

    async upSert(vehicleList: VehicleList): Promise<VehicleList> {
        // const res = await this.doPost({ path: ``, body: vehicleList, config: {} });
        const res = await this.client.post(``, vehicleList);
        return res.data;
    }

    async getEvent(filter: FilterHistoryAction): Promise<Pagination<VehicleListLogItem>> {
        const res = await this.client.post(`getEvent`, filter);
        return res.data;
    }

    async viewAction(action: VLViewAction) {
        const res = await this.client.post(`viewAction`, action);
        return res.data;
    }

    async downloadExcel(props: { filter: FilterVehicleListProps }): Promise<File> {
        const res = await this.client.post(`Export/DownloadVl`, props.filter, {
            responseType: 'blob',
        });
        return res.data;
    }
}
