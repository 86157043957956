import { FormControlLabel, FormControlLabelProps, styled, Switch, SwitchProps } from '@mui/material';

const StyledIOSSwitch = styled((props: SwitchProps) => (
    <Switch focusVisibleClassName=".Mui-focusVisible" disableRipple {...props} />
))(({ theme }) => ({
    width: 42,
    height: 26,
    padding: 0,
    margin: '0 8px 0 0 !important',
    '& .MuiSwitch-switchBase': {
        padding: 0,
        margin: 2,
        transitionDuration: '300ms',
        '&.Mui-checked': {
            transform: 'translateX(16px)',
            color: '#fff',
            '& + .MuiSwitch-track': {
                backgroundColor: theme.palette.primary.main,
                opacity: 1,
                border: 0,
            },
            '&.Mui-disabled + .MuiSwitch-track': {
                opacity: 0.5,
            },
        },
        // '&.Mui-focusVisible .MuiSwitch-thumb': {
        //     color: '#33cf4d',
        //     border: '6px solid #fff',
        // },
        '&.Mui-disabled .MuiSwitch-thumb': {
            color: theme.palette.mode === 'light' ? theme.palette.grey[100] : theme.palette.grey[600],
        },
        '&.Mui-disabled + .MuiSwitch-track': {
            opacity: theme.palette.mode === 'light' ? 0.7 : 0.3,
        },
    },
    '& .MuiSwitch-thumb': {
        boxSizing: 'border-box',
        width: 22,
        height: 22,
    },
    '& .MuiSwitch-track': {
        borderRadius: 26 / 2,
        backgroundColor: theme.palette.mode === 'light' ? '#E9E9EA' : '#39393D',
        opacity: 1,
        transition: theme.transitions.create(['background-color'], {
            duration: 500,
        }),
    },
}));

export default function IOSSwitch(
    props: Pick<FormControlLabelProps, 'checked' | 'defaultChecked' | 'onChange' | 'disabled'>
) {
    return (
        <FormControlLabel
            {...props}
            control={<StyledIOSSwitch sx={{ m: 1 }} />}
            label={props.checked ? 'Active' : 'Inactive'}
            sx={{
                '&.MuiFormControlLabel-root .MuiFormControlLabel-label': {
                    color: props.checked ? 'primary.main' : 'initial',
                    minWidth: 50.27,
                },
            }}
        />
    );
}
