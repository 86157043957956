import { Stack } from '@mui/system';
import React from 'react';
import Required from './Required';

export default function LabelWithRequired({
    label,
    isShowRequired,
    children,
}: {
    label: string;
    isShowRequired?: boolean;
    children?: React.ReactNode;
}) {
    return (
        <Stack display="flex" direction={'row'} alignItems="center">
            {label} {isShowRequired ? <Required /> : ''}
            {children}
        </Stack>
    );
}
