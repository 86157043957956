import { IconButton, Menu, MenuItem, Typography } from '@mui/material';
import React from 'react';
import MenuDeleteIcon from '../../../../components/svg/MenuDeleteIcon';
import color from '../../../../config/Colors';
import useMenuELPopUp from '../../../../hooks/useMenuELPopUp';
import { Portfolio } from '../../../../models/scope/Portfolio';
import { GFlexRow, GRadiusItem } from '../../../../styles/style';
import { isPortfolio } from '../../helper';
import { UnionScope } from '../model/UpsertVehicleList';

type Props = {
    index: number;
    portfolio: UnionScope;
    handleRemovePortfolio: (portfolio: Portfolio) => void;
};

export default function PortfolioScopeItem({ index, portfolio, handleRemovePortfolio }: Props) {
    const { openAnchorEl, handleClick, handleClose, anchorEl } = useMenuELPopUp();
    return (
        <GRadiusItem sx={{ padding: 2 }} gap={2}>
            <GFlexRow item justifyContent={'space-between'}>
                <Typography
                    fontWeight={500}
                    sx={{ overflow: 'hidden', textOverflow: 'ellipsis', whiteSpace: 'nowrap' }}
                >
                    {index + 1 + '. '}
                    {isPortfolio(portfolio) ? portfolio.name : ''} {'(Portfolio)'}
                </Typography>
                <IconButton
                    // onClick={() => props.handleRemove(props.company)}
                    sx={{ padding: 0 }}
                    id="basic-button"
                    aria-controls={openAnchorEl ? 'basic-menu' : undefined}
                    aria-haspopup="true"
                    aria-expanded={openAnchorEl ? 'true' : undefined}
                    onClick={handleClick}
                >
                    <MenuDeleteIcon />
                </IconButton>
            </GFlexRow>
            <Menu
                id="basic-menu"
                anchorEl={anchorEl}
                open={openAnchorEl}
                onClose={handleClose}
                MenuListProps={{
                    'aria-labelledby': 'basic-button',
                }}
                transformOrigin={{ horizontal: 'right', vertical: 'top' }}
                anchorOrigin={{ horizontal: 'right', vertical: 'bottom' }}
                PaperProps={{ sx: { padding: 1 } }}
                sx={{
                    '& .MuiPaper-root': {
                        padding: '0 5px ',
                        boxShadow: 'none',
                        border: '1px solid #dddddd',
                        borderRadius: '2px',
                    },
                    '& .MuiList-root': {
                        py: '5px',
                    },
                }}
                transitionDuration={100}
            >
                <MenuItem
                    sx={{ color: color.danger, p: '6px 6px' }}
                    onClick={() => {
                        if (isPortfolio(portfolio)) {
                            handleRemovePortfolio(portfolio);
                        }
                        handleClose();
                    }}
                    disableRipple
                >
                    Delete portfolio
                </MenuItem>
            </Menu>
        </GRadiusItem>
    );
}
