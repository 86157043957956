/* eslint-disable array-callback-return */
import { LoadingButton } from '@mui/lab';
import { Grid, styled, Typography } from '@mui/material';
import React, { ChangeEvent, useContext, useRef } from 'react';
import { BulkUploadPermitsContext } from '../hook/useBulkUpload';
import UploadBorderStep from './UploadBorderStep';
import { HiUpload } from 'react-icons/hi';
import LinearWithValueLabel from './LinearProgressWithLabel';
import color from '../../../config/Colors';
import * as XLSX from 'xlsx';
import { pushError } from '../../../components/toast';

export default function UploadVRNPage() {
    const { setPartialState, ...state } = useContext(BulkUploadPermitsContext);

    const uploadInputRef = useRef<HTMLInputElement | null>(null);

    const setProgress = (percent: number) => {
        setPartialState({ progressPercent: percent });
    };

    const handleCancelUpload = () => {
        setPartialState({
            fileName: '',
            progressPercent: 0,
            fileUpload: null,
            rawsExcelData: [],
        });
    };

    const handleFileUpload = (e: ChangeEvent<HTMLInputElement>) => {
        setPartialState({ isLoading: true, errorText: '' });
        setProgress(0);
        if (!e.target.files) {
            return;
        }
        setProgress(10);
        const file = e.target.files[0];
        setPartialState({ fileName: file.name, fileUpload: file });

        //read data from file
        var reader = new FileReader();
        reader.onload = function (e) {
            setProgress(30);
            var data = e.target!.result;
            let readerData = XLSX.read(data, { type: 'binary' });
            const wsName = readerData.SheetNames[0];
            const ws = readerData.Sheets[wsName];
            /* Convert array to json*/
            const startRows = 1;
            const dataParse: any[] = XLSX.utils.sheet_to_json(ws, {
                header: 1,
                range: startRows,
                raw: false,
            });

            const rawData: ExcelRawData[] = [];
            setProgress(40);
            let isValid = true;
            if (dataParse.length > 0) {
                dataParse.map((item, index) => {
                    //check valid file
                    if (index === 0) {
                        const rowData: any[] = item;
                        if (rowData[0] !== 'Reg number') return (isValid = false);
                    }
                    if (index !== 0) {
                        const rowData: any[] = item;
                        if (rowData[0] || rowData[1] || rowData[2] || rowData[3]) {
                            const permitDataItem: ExcelRawData = {
                                regNumber: rowData[0],
                                permitType: rowData[1],
                                firstName: rowData[2],
                                lastName: rowData[3],
                                bayInformation: rowData[4],
                            };
                            rawData.push(permitDataItem);
                        }
                    }
                });
            }
            if (!isValid) {
                // toast.error(t('permits:upload.invalidFileFormat'));
                pushError('Invalid file format');
                handleCancelUpload();
                return;
            }
            setProgress(50);
            // //check row data
            if (rawData.length > 0) {
                setProgress(100);
                setPartialState({ rawsExcelData: rawData });
            } else {
                setProgress(0);
                setPartialState({ errorText: 'File select must be at least one permit' });
            }
        };
        reader.readAsBinaryString(file);

        //clear input file
        (e.target as HTMLInputElement).value = '';
    };

    return (
        <UploadBorderStep
            isLoadingDownload={state.isLoadingDownload}
            setIsLoadingProgress={(val) => {
                setPartialState({ isLoadingDownload: val });
            }}
            content={
                <React.Fragment>
                    {state.progressPercent !== 0 ? (
                        <LinearWithValueLabel
                            progressPercent={state.progressPercent}
                            fileName={state.fileName}
                            handleCancelUpload={handleCancelUpload}
                        />
                    ) : (
                        <Typography variant="h4" color={color.grey600} textAlign="center">
                            Please upload a permits file.
                        </Typography>
                    )}

                    {state.errorText !== '' && (
                        <Typography color={color.danger} textAlign="center">
                            {state.errorText}
                        </Typography>
                    )}
                    <input
                        ref={uploadInputRef}
                        type="file"
                        accept=".xlsx"
                        style={{ display: 'none' }}
                        onChange={handleFileUpload}
                    />
                    <LoadingButton
                        variant="contained"
                        loading={false}
                        loadingPosition="start"
                        startIcon={<></>}
                        sx={{ width: '200px' }}
                        endIcon={<HiUpload style={{ fontSize: '14px' }} />}
                        // disabled={state.isLoading === true}
                        onClick={() => uploadInputRef.current && uploadInputRef.current.click()}
                    >
                        {state.progressPercent === 100 ? 'Replace file' : 'Upload file'}
                    </LoadingButton>
                </React.Fragment>
            }
        />
    );
}

export const GridUploadBorder = styled(Grid)({
    border: '1px dashed #DDDDDD',
    borderRadius: '5px',
    display: 'flex',
    flexDirection: 'column',
    justifyContent: 'center',
    alignItems: 'center',
    padding: '15px 32px',
    gap: '24px',
    minHeight: '200px',
});

export interface ExcelRawData {
    regNumber: string | undefined;
    firstName: string | undefined;
    lastName: string | undefined;
    permitType: string | undefined;
    bayInformation: string | undefined;
}
