/* eslint-disable react-hooks/exhaustive-deps */
import { ArrowDropDown } from '@material-ui/icons';
import {
    CircularProgress,
    ClickAwayListener,
    Fade,
    IconButton,
    Paper,
    Stack,
    TextField,
    Typography,
} from '@mui/material';
import _ from 'lodash';
import { useCallback, useEffect, useRef, useState } from 'react';
import { VariableSizeList } from 'react-window';
import InfiniteLoader from 'react-window-infinite-loader';
import { activeStyles, search } from '../../helpers';
import useDbSearch from '../../hooks/useDbSearch';
import { Pagination } from '../../models/filter/BaseFilter';
import BaseOption from './BaseOption';

type StyledAutocompleteProps<T> = {
    disabledAllOption?: boolean;
    readOnly?: boolean;
    disabled?: boolean;
    isSync?: boolean;
    label?: string;
    value?: T;
    placeholder?: string;
    onChange?(value?: T): any;
    isEqual?(option?: T, value?: T): boolean;
    getOptionLabel(option: T): string;

    searchLoading: boolean;

    loading?: boolean;
    paging: T[];
    pagingDefault: T[];

    hasNextPage: boolean;
    isNextPageLoading: boolean;
    loadNextPage(searchText: string): void;
};

const OPTION_HEIGHT = 37; // px

const getHeight = (width: number, content: string) => {
    const UL_PADDING = 16;
    const ITEM_PADDING = 16;
    const SCROLL_BAR_WIDTH = 4;
    const LINE_HEIGHT = 21;

    const para = document.createElement('p');
    para.innerText = content;
    para.style.width = `${width - UL_PADDING - ITEM_PADDING - SCROLL_BAR_WIDTH}px`;

    document.body.appendChild(para);
    const height = para.offsetHeight;
    document.body.removeChild(para);

    // console.log(`_height`, width, content, height);

    return height > LINE_HEIGHT ? OPTION_HEIGHT + LINE_HEIGHT : OPTION_HEIGHT;
};

export default function AutocompleteNoPagi<T>(props: StyledAutocompleteProps<T>) {
    const [open, setOpen] = useState(false);
    const [inputValue, setInputValue] = useState('');
    const [searchValue, setSearchValue] = useState('');
    const [searchLoading, setSearchLoading] = useState(false);

    const toggle = () => {
        setOpen(!open);
    };

    const handleClose = () => {
        setOpen(false);
    };

    useEffect(() => {
        setInputValue(props.value ? props.getOptionLabel(props.value) : '');
    }, [props.value]);

    useEffect(() => {
        if (open) {
            setSearchValue('');
        } else {
            setInputValue(props.value ? props.getOptionLabel(props.value) : '');
        }
        if (!inputValue) {
            setItems(itemsDefault);
        }
    }, [open]);

    useEffect(() => {
        setSearchLoading(props.searchLoading);
    }, [props.paging, props.searchLoading]);

    const itemsDefault = props.paging;
    const [items, setItems] = useState(props.paging);
    useEffect(() => {
        setItems(itemsDefault);
    }, [itemsDefault]);
    const { hasNextPage } = props;
    const itemCount = hasNextPage ? items.length + 1 : items.length;

    const loadMoreItems = () => (props.isNextPageLoading ? undefined : props.loadNextPage(searchValue));
    const isItemLoaded = (index: number) => !hasNextPage || index < items.length;

    const handleChangeText = (e: any) => {
        const _value = e.target.value;
        setInputValue(_value);
        if (open) {
            setSearchValue(_value);
        }
        if (_value) {
            const _itemFilter = itemsDefault.filter((item) => search(props.getOptionLabel(item), _value));
            setItems(_itemFilter);
        } else {
            setItems(props.pagingDefault);
        }
    };

    const refZ = useRef<any>(null);
    console.log('inputValue', inputValue);
    return (
        <ClickAwayListener onClickAway={handleClose} mouseEvent="onMouseDown" touchEvent="onTouchEnd">
            <div ref={refZ}>
                <Stack
                    sx={{
                        // height: 40,
                        position: 'relative',
                    }}
                >
                    <TextField
                        label={props.label}
                        value={inputValue}
                        disabled={props.loading || props.disabled || props.readOnly}
                        fullWidth
                        onClick={(e) => {
                            if (props.loading || props.disabled || props.readOnly) return;
                            e.preventDefault();
                            e.stopPropagation();
                            setOpen(true);
                        }}
                        autoComplete="off"
                        onChange={handleChangeText}
                        placeholder={props.placeholder || 'All option'}
                        sx={{
                            '& input:not(:focus)': {
                                '&::placeholder': {
                                    color: 'initial',
                                    opacity: 1,
                                },
                            },
                        }}
                        InputProps={{
                            readOnly: props.readOnly,

                            endAdornment: (
                                <IconButton
                                    size="small"
                                    sx={{
                                        padding: '1px',
                                    }}
                                    onClick={(e) => {
                                        e.preventDefault();
                                        e.stopPropagation();
                                        toggle();
                                    }}
                                    disabled={props.loading || props.disabled || props.readOnly}
                                >
                                    {props.loading ? (
                                        <CircularProgress size={'16px'} />
                                    ) : (
                                        <ArrowDropDown
                                            fontSize="small"
                                            style={{ transition: '0.3s' }}
                                            className={open ? 'rotate' : ''}
                                        />
                                    )}
                                </IconButton>
                            ),
                        }}
                    />

                    <Fade in={open} timeout={0} unmountOnExit={false}>
                        <Paper
                            sx={{
                                position: 'absolute',
                                top: 'calc(100% + 2px)',
                                zIndex: 2,
                                left: 0,
                                right: 0,
                                p: 1,
                            }}
                        >
                            {!props.disabledAllOption && (
                                <Stack
                                    sx={{
                                        ...activeStyles({ display: 'none' }, Boolean(searchValue || searchLoading)),
                                    }}
                                >
                                    <Stack>
                                        <BaseOption
                                            option={undefined}
                                            select={!props.value}
                                            renderOption={() => <Typography>{props.placeholder}</Typography>}
                                            onClick={(option) => {
                                                handleClose();
                                                props.onChange?.(undefined);
                                            }}
                                        />
                                    </Stack>
                                </Stack>
                            )}

                            {!searchLoading && (
                                <InfiniteLoader
                                    isItemLoaded={isItemLoaded}
                                    itemCount={itemCount}
                                    loadMoreItems={loadMoreItems}
                                    threshold={5}
                                >
                                    {({ onItemsRendered, ref }) => {
                                        return (
                                            <VariableSizeList
                                                ref={ref}
                                                onItemsRendered={onItemsRendered}
                                                height={OPTION_HEIGHT * Math.min(6, itemCount)}
                                                itemCount={itemCount}
                                                itemSize={(index) =>
                                                    getHeight(
                                                        refZ.current?.offsetWidth,
                                                        items[index] ? props.getOptionLabel(items[index]) : ''
                                                    )
                                                }
                                                width={'100%'}
                                                className="styled-scroll"
                                            >
                                                {({ index, style }) => {
                                                    const option = items[index];

                                                    return !isItemLoaded(index) ? (
                                                        <Stack
                                                            style={style}
                                                            sx={{ height: OPTION_HEIGHT, pl: 1 }}
                                                            justifyContent="center"
                                                        >
                                                            <Typography>Loading...</Typography>
                                                        </Stack>
                                                    ) : (
                                                        <BaseOption
                                                            stackProps={{ style: style }}
                                                            option={option}
                                                            renderOption={(_option) => (
                                                                <Typography>{props.getOptionLabel(_option)}</Typography>
                                                            )}
                                                            onClick={(option) => {
                                                                handleClose();
                                                                props.onChange?.(option);
                                                            }}
                                                            select={
                                                                props.isEqual?.(option, props.value) ??
                                                                _.isEqual(option, props.value)
                                                            }
                                                        />
                                                    );
                                                }}
                                            </VariableSizeList>
                                        );
                                    }}
                                </InfiniteLoader>
                            )}

                            <Stack
                                sx={{
                                    height: 30,
                                    display: 'flex',
                                    justifyContent: 'center',
                                    paddingLeft: '5px',
                                    ...activeStyles({ display: 'none' }, Boolean(!searchLoading)),
                                }}
                            >
                                <Typography color="grayText">Loading...</Typography>
                            </Stack>

                            <Stack
                                sx={{
                                    height: 30,
                                    display: 'flex',
                                    justifyContent: 'center',
                                    paddingLeft: '5px',
                                    ...activeStyles(
                                        { display: 'none' },
                                        Boolean(!!items.length || props.loading || searchLoading)
                                    ),
                                }}
                            >
                                <Typography color="grayText">No options</Typography>
                            </Stack>
                        </Paper>
                    </Fade>
                </Stack>
            </div>
        </ClickAwayListener>
    );
}
