import { Divider, FormControl, InputLabel, MenuItem, Select, SelectChangeEvent } from '@mui/material';
import React from 'react';
import color from '../config/Colors';

type Props<T> = {
    listData: T[];
    value: number;
    keyEqual: keyof T;
    keyLabel: keyof T;
    handleChange: (val: number) => void;
    label: string;
    isDisable?: boolean;
};

export default function ISelect<T>(props: Props<T>) {
    const handleChange = (event: SelectChangeEvent<any>, child: React.ReactNode) => {
        props.handleChange(Number(event.target.value));
    };

    return (
        <FormControl
            fullWidth
            size="small"
            sx={{
                '& svg': {
                    width: '20px',
                    height: '20px',
                },
                '& .MuiSvgIcon-root': {
                    top: 'calc(50% - 0.4em)',
                },
            }}
        >
            <InputLabel
                id="demo-multiple-checkbox-label"
                sx={{
                    background: color.white,
                }}
            >
                {props.label}
            </InputLabel>
            <Select
                labelId="demo-simple-select-label"
                id="demo-simple-select"
                onChange={handleChange}
                value={props.value}
                disabled={props.isDisable}
            >
                {props.listData.map((item, index) => {
                    return [
                        <MenuItem value={Number(item[props.keyEqual])} key={index}>
                            {item[props.keyLabel] as string}
                        </MenuItem>,
                        index !== props.listData.length - 1 && (
                            <Divider
                                sx={{
                                    my: '2px !important',
                                    borderColor: color.grey200,
                                    mx: '3px',
                                }}
                            />
                        ),
                    ];
                })}
            </Select>
        </FormControl>
    );
}
