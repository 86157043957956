import { Box, Grid, Stack, TextField, Typography } from '@mui/material';
import moment from 'moment';
import _ from 'lodash';
import color from '../../../../config/Colors';
import { PropsRecurring } from './DayRecurring';
import { useContext } from 'react';
import { VehicleListUpsertContext } from '../../hooks/useVehicleListUpsert';

interface Props extends PropsRecurring {
    weekRecurringDays: number[];
    setWeekRecurringDays: (arr: number[]) => void;
}

export default function WeekRecurring({
    recurringEvery,
    setREcurringEvery,
    weekRecurringDays,
    setWeekRecurringDays,
    methods,
    isEdit,
}: Props) {
    const daysOfWeek = [1, 2, 3, 4, 5, 6, 0];
    const { handleChangeValidateFromWhenEdit } = useContext(VehicleListUpsertContext);

    return (
        <Grid
            direction={'row'}
            sx={{ background: color.grey100, borderRadius: '5px', padding: '10px !important' }}
            alignItems="center"
            container
            item
            gap={0}
        >
            <Grid item container xs={6} direction={'row'} alignItems={'center'}>
                <Typography variant="body1" component={'span'}>
                    {'Recurring every'}
                </Typography>

                <TextField
                    placeholder={''}
                    sx={{
                        minWidth: '40px',
                        mx: 2,
                        '& input': {
                            textAlign: 'center',
                        },
                    }}
                    inputProps={{ min: 1, max: 53 }}
                    value={recurringEvery}
                    disabled={isEdit}
                    onChange={(e) => {
                        const value = Number(e.target!.value);
                        const val = value < 1 ? 1 : value > 53 ? 53 : value;
                        setREcurringEvery(val);
                    }}
                    size="small"
                    type={'number'}
                />

                <Grid item>
                    <Typography variant="body1" component={'span'} mr={2}>
                        {'week(s) on'}
                    </Typography>
                </Grid>
            </Grid>

            <Grid item container xs={6} direction={'row'} alignItems={'center'}>
                <Grid item>
                    <Stack direction={'row'}>
                        {daysOfWeek.map((item) => {
                            const isActive = weekRecurringDays.findIndex((day) => day === item) >= 0;
                            return (
                                <Box
                                    key={item}
                                    sx={[
                                        {
                                            width: '32px',
                                            height: '32px',
                                            borderRadius: '50%',
                                            backgroundColor: color.grey400,
                                            cursor: 'pointer',
                                            display: 'flex',
                                            flexDirection: 'column',
                                            justifyContent: 'center',
                                            mr: 1,
                                            userSelect: 'none',
                                        },
                                        isActive
                                            ? {
                                                  backgroundColor: color.primary,
                                              }
                                            : {},
                                    ]}
                                    onClick={() => {
                                        if (!isEdit) {
                                            if (isActive) {
                                                if (weekRecurringDays.length > 1) {
                                                    const dayRecur = _.cloneDeep(weekRecurringDays).filter(
                                                        (day) => day !== item
                                                    );
                                                    setWeekRecurringDays(dayRecur.sort((objA, objB) => objA - objB));
                                                }
                                            } else {
                                                const arr = _.cloneDeep(weekRecurringDays);
                                                //check if day is before today
                                                arr.push(item);
                                                setWeekRecurringDays(arr.sort((objA, objB) => objA - objB));
                                            }
                                        }
                                    }}
                                >
                                    <Typography
                                        variant="body1"
                                        fontSize={'10px'}
                                        fontWeight={500}
                                        color={color.white}
                                        textAlign="center"
                                    >
                                        {moment.weekdaysShort(item)}
                                    </Typography>
                                </Box>
                            );
                        })}
                    </Stack>
                </Grid>
            </Grid>
        </Grid>
    );
}
