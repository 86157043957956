import { BaseHttpController } from 'ale-base-model';
import { AxiosInstance } from 'axios';
import { FilterRequest, Pagination } from '../../models/filter/BaseFilter';
import { Region } from '../../models/scope/Region';

export class RegionController extends BaseHttpController<Region> {
    constructor(serviceUrl: string, client: AxiosInstance) {
        super(serviceUrl, 'Region', client);
    }

    async getAll(filter: FilterRequest<Region>): Promise<Pagination<Region>> {
        const res = await this.doPost({ path: 'GetAll', body: filter, config: {} });
        return res.data;
    }

    async getById(id: number) {
        const res = await this.client.get(`${this.serviceURL}/${this.basePath}/${id}`);
        return res.data;
    }
}
