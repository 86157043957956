import React from 'react';
import TextField from '@mui/material/TextField';
import Autocomplete from '@mui/material/Autocomplete';
import { Box, Typography } from '@mui/material';
import { BaseSelect } from '../../../vehicleList/hooks/useVehicleList';
import color from '../../../../config/Colors';

type Props = {
    listData: BaseSelect[];
    label: string | JSX.Element;
    value?: BaseSelect;
    disabled?: boolean;
    setFilter: (option: BaseSelect) => void;
};

export default function AutoCompleteInternalPermit(props: Props) {
    const handleChange = (event: any, value: BaseSelect | null) => {
        if (value == null) {
            // props.setFilter(props.listData[0].value);
        } else {
            props.setFilter(value);
        }
    };

    return (
        <Box
            sx={{
                '& #combo-box-demo-listbox': {
                    padding: '5px',
                },
                '& .MuiAutocomplete-listbox': {
                    padding: '5px',
                },
                '& .MuiAutocomplete-option': {
                    minHeight: 'auto !important',
                    mb: '2px',
                    borderBottom: `1px solid ${color.grey200}`,
                    borderRadius: '4px',
                },
                //css first item chosen
                '& .MuiAutocomplete-groupLabel': {
                    fontSize: '14px',
                    lineHeight: 'normal',
                    padding: '10px 20px',
                    color: 'black',
                    // fontWeight: 600,
                    background: '#E5F2F8',
                    my: '2px',
                },
                width: '100%',
            }}
        >
            <Autocomplete
                fullWidth
                disablePortal
                noOptionsText={'No options'}
                sx={{
                    '& #combo-box-demo-listbox': {
                        padding: '5px',
                    },
                }}
                // open
                disabled={props.disabled ?? false}
                defaultValue={props.listData[0]}
                options={props.listData}
                value={props.value || null}
                isOptionEqualToValue={(option, value) => option.id === value.id}
                onChange={(event, value) => handleChange(event, value)}
                renderInput={(params) => <TextField {...params} label={props.label} />}
                getOptionLabel={(option) => option.name ?? ''}
                renderOption={(props, option: BaseSelect, { selected }) => {
                    return (
                        <Box key={option.id}>
                            <li
                                {...props}
                                style={{
                                    display: 'flex',
                                    flexDirection: 'column',
                                    background: selected ? color.lightPrimary : color.white,
                                }}
                                value={option.id}
                                key={option.id}
                            >
                                <Typography variant="body1" width={'100%'}>
                                    {option.name}{' '}
                                </Typography>
                                <Typography
                                    fontSize={10}
                                    color={color.grey600}
                                    sx={{ textAlign: 'left', width: '100%' }}
                                >
                                    {option.desc}
                                </Typography>
                            </li>
                        </Box>
                    );
                }}
                size="small"
            />
        </Box>
    );
}
