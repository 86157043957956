import { Box, Checkbox, FormControlLabel, Grid, Typography } from '@mui/material';
import React from 'react';
import { Controller, UseFormReturn } from 'react-hook-form';
import CheckBoxIcon from '../../../components/svg/CheckBoxIcon';
import CheckedIcon from '../../../components/svg/CheckedIcon';
import { LOCATION_SCOPE_CUSTOM, TENANT_SCOPE_CUSTOM } from '../constant';
import { isCompany } from '../helper';
import { ScopeState } from '../hooks/useVehicleListUpsert';
import { FormData, UnionScope } from './model/UpsertVehicleList';

export default function CheckBoxAllowTenantToSee({
    methods,
    setPartialState,
    companySelects,
}: {
    methods: UseFormReturn<FormData, any>;
    setPartialState: (partial: Partial<ScopeState>) => void;
    companySelects: UnionScope[];
}) {
    const { control, watch } = methods;
    const { isAllowTenant } = watch();

    const handleChangeValid = (e: React.ChangeEvent<HTMLInputElement>) => {
        let isAllTenantScopeValid = true;
        companySelects.forEach((c) => {
            if (isCompany(c)) {
                c.locations?.forEach((l) => {
                    if (!e.target.checked) {
                        if (l.tenantScope && l.tenantScope === TENANT_SCOPE_CUSTOM && l.shops && l.shops.length === 0) {
                            isAllTenantScopeValid = false;
                        }
                    }

                    if (
                        l.locationScope &&
                        l.locationScope === LOCATION_SCOPE_CUSTOM &&
                        l.zones &&
                        l.zones.length === 0
                    ) {
                        isAllTenantScopeValid = false;
                    }
                });
            }
        });
        setPartialState({ isScopeValid: isAllTenantScopeValid });
    };

    return (
        <Grid item xs="auto">
            <Box minWidth={'345px'}>
                <Controller
                    name="isAllowTenant"
                    control={control}
                    render={({ field }) => {
                        return (
                            <FormControlLabel
                                control={
                                    <Checkbox
                                        size="small"
                                        icon={<CheckBoxIcon />}
                                        checkedIcon={<CheckedIcon />}
                                        onChange={(e) => {
                                            handleChangeValid(e);
                                            field.onChange(e);
                                        }}
                                        checked={isAllowTenant}
                                    />
                                }
                                label={<Typography>Exclude all tenants from seeing this vehicle list</Typography>}
                                sx={{
                                    '&.MuiFormControlLabel-root': {
                                        margin: 0,
                                    },
                                }}
                            />
                        );
                    }}
                />
            </Box>
        </Grid>
    );
}
