/* eslint-disable react-hooks/exhaustive-deps */
import { Checkbox, FormControlLabel, Grid, Stack, TextField, Theme, Typography } from '@mui/material';
import { makeStyles } from '@mui/styles';
import { LocalizationProvider, TimePicker } from '@mui/x-date-pickers';
import { AdapterDateFns } from '@mui/x-date-pickers/AdapterDateFns';
import moment from 'moment';
import React, { useEffect } from 'react';
import color from '../../../../config/Colors';
import LabelWithRequired from '../../../../components/textfield/LabelWithRequired';
import { Controller, useFormContext } from 'react-hook-form';
import StartDateSelect from './StartDateSelect';
import { RecurringType } from '../../../../models/permit/PermitRecurring';
import { endOfDay, startOfDay } from 'date-fns';
import UncheckedIcon from '../../../../components/svg/UncheckedIcon';
import CheckedIcon from '../../../../components/svg/CheckedIcon';
import { FormData } from '../model/UpsertVehicleList';
import { convertHour } from '../../../../helpers/ConvertHour';
import { ALL_DAY_SECONDS } from '../../constant';

const icon = <UncheckedIcon fontSize="small" />;
const checkedIcon = <CheckedIcon fontSize="small" />;

export type DurationProps = {
    isEdit: boolean;
};

export default function InternalPermitTimeDuration(props: DurationProps) {
    const {
        formState: { errors },
        control,
        watch,
        setValue,
    } = useFormContext<FormData>();
    const { internalPermitData } = watch();

    const { fromHour, toHour, recurringType, isCheckedAllDay } = internalPermitData;

    const classes = useStyles();
    // const { getFieldProps, permit, values, setFieldTouched } =
    //     useContext(UpsertPermitItemContext);
    const startTime = moment(fromHour).format('DD/MM/YYYY HH:mm');
    const endTime = moment(toHour).format('DD/MM/YYYY HH:mm');
    const totalMinute = moment(endTime, 'DD/MM/YYYY HH:mm').diff(moment(startTime, 'DD/MM/YYYY HH:mm'), 'minutes');
    const hr = moment.duration(totalMinute, 'minutes').hours();
    const min = moment.duration(totalMinute, 'minutes').minutes();
    const duration = `${hr}hr ${min}min`;

    const handleChange = (event: React.ChangeEvent<HTMLInputElement>) => {
        setValue('internalPermitData.fromHour', startOfDay(new Date()), { shouldValidate: true });
        setValue(
            'internalPermitData.toHour',
            event.target.checked ? endOfDay(new Date()) : convertHour.getHoursAndMinutesFromTimeNumber(ALL_DAY_SECONDS),
            { shouldValidate: true }
        );
    };

    useEffect(() => {
        if (isCheckedAllDay) {
            setValue('internalPermitData.fromHour', startOfDay(new Date()), { shouldValidate: true });
            setValue('internalPermitData.toHour', endOfDay(new Date()), { shouldValidate: true });
        }
    }, []);

    // console.log(watch());

    return (
        <LocalizationProvider dateAdapter={AdapterDateFns}>
            <Grid
                mb={recurringType === RecurringType.once ? 1.5 : 2}
                xs={12}
                item
                container
                spacing={2}
                direction="row"
                alignItems={'center'}
                mr={2}
            >
                {/* <Grid lg={4} md={4} sm={4} xs={checkedAllDay ? 8.5 : 12} item> */}
                {/* </Grid> */}

                {![RecurringType.indefinite].includes(recurringType) && (
                    <Grid item xs={3} display={'flex'} flexDirection="row" alignItems={'center'}>
                        <StartDateSelect isEdit={props.isEdit} />
                    </Grid>
                )}
                <Grid item display={isCheckedAllDay ? 'none' : 'flex'}>
                    <Typography>from</Typography>
                </Grid>

                <Grid display={isCheckedAllDay ? 'none' : 'flex'} xs={2} mt={0} item>
                    <Stack spacing={2} direction={'row'} justifyContent={'flex-start'} alignItems={'center'}>
                        <Controller
                            name="internalPermitData.fromHour"
                            control={control}
                            render={({ field }) => {
                                return (
                                    <TimePicker
                                        label={<LabelWithRequired label={'Start time'} isShowRequired={true} />}
                                        value={fromHour}
                                        disabled={props.isEdit}
                                        onChange={(newVal, textVal) => {
                                            // setFieldTouched('fromHour');
                                            if (newVal != null) {
                                                // setFieldTouched('toHour');
                                                field.onChange(newVal);
                                                setValue('internalPermitData.toHour', null, { shouldTouch: true });
                                            }
                                        }}
                                        ampm={false}
                                        renderInput={(params) => (
                                            <TextField
                                                {...params}
                                                error={Boolean(errors.internalPermitData?.fromHour)}
                                                helperText={errors.internalPermitData?.fromHour?.message}
                                                disabled={props.isEdit}
                                                fullWidth
                                                sx={{
                                                    // maxWidth: '220px',
                                                    '& .MuiInputBase-input': {
                                                        caretColor: 'transparent',
                                                    },
                                                }}
                                                onKeyDown={(e) => {
                                                    e.preventDefault();
                                                }}
                                            />
                                        )}
                                    />
                                );
                            }}
                        />
                    </Stack>
                </Grid>
                <Grid item display={isCheckedAllDay ? 'none' : 'flex'}>
                    <Typography display={'block'} variant="body1">
                        {'to'}
                    </Typography>
                </Grid>

                <Grid display={isCheckedAllDay ? 'none' : 'flex'} xs={2} mt={{ xs: 1, sm: 0 }} item>
                    <Stack spacing={2} direction={'row'} justifyContent={'flex-start'} alignItems={'center'}>
                        <Controller
                            name="internalPermitData.toHour"
                            control={control}
                            render={({ field }) => {
                                return (
                                    <TimePicker
                                        ampm={false}
                                        label={<LabelWithRequired label={'End time'} isShowRequired={true} />}
                                        value={toHour}
                                        disabled={props.isEdit}
                                        minTime={moment(fromHour).add(1, 'minutes').toDate()}
                                        onChange={(newVal, textVal) => {
                                            // setFieldTouched('toHour');
                                            if (newVal != null) {
                                                // setFieldTouched('toHour');
                                                field.onChange(newVal);
                                            }
                                        }}
                                        OpenPickerButtonProps={{ className: classes.timeStyle }}
                                        renderInput={(params) => (
                                            <TextField
                                                {...params}
                                                error={!!errors?.internalPermitData?.toHour}
                                                helperText={errors?.internalPermitData?.toHour?.message}
                                                fullWidth
                                                disabled={props.isEdit}
                                                sx={{
                                                    '& .MuiInputBase-input': {
                                                        caretColor: 'transparent',
                                                    },
                                                }}
                                                onKeyDown={(e) => {
                                                    // setFieldTouched('toHour');
                                                    e.preventDefault();
                                                }}
                                            />
                                        )}
                                    />
                                );
                            }}
                        />
                    </Stack>
                </Grid>
                <Grid item display={isCheckedAllDay ? 'none' : 'flex'}>
                    <Typography
                        display={Boolean(toHour == null || fromHour == null) ? 'none' : 'flex'}
                        mr={toHour == null || fromHour == null ? 0 : 2}
                        variant="body1"
                    >
                        {'Time duration per day: '}
                        {toHour == null || fromHour == null ? 0 : duration || 0}
                    </Typography>
                </Grid>
                <Grid item>
                    <Controller
                        name="internalPermitData.isCheckedAllDay"
                        control={control}
                        render={({ field }) => {
                            return (
                                <FormControlLabel
                                    sx={{
                                        '&.MuiFormControlLabel-labelPlacementEnd': {
                                            marginRight: '0px !important',
                                        },
                                        '& .MuiButtonBase-root': {
                                            padding: '5px',
                                        },
                                        paddingLeft: 2,
                                    }}
                                    control={
                                        <Checkbox
                                            onChange={(e, isChecked) => {
                                                field.onChange(isChecked);
                                                handleChange(e);
                                            }}
                                            disabled={props.isEdit}
                                            icon={icon}
                                            checkedIcon={checkedIcon}
                                            checked={isCheckedAllDay}
                                        />
                                    }
                                    label={<Typography variant="body1">{'All day'}</Typography>}
                                />
                            );
                        }}
                    />
                </Grid>
            </Grid>
        </LocalizationProvider>
    );
}

const useStyles = makeStyles((theme: Theme) => ({
    timeStyle: {
        '& .Mui-disabled': {
            color: 'red !important',
        },
    },
    inputStyle: {
        '& .Mui-selected': {
            backgroundColor: `${color.primary} !important`,
        },
    },
    checkBoxc: {
        '.MuiFormControlLabel-root': {
            marginRight: '0px !important',
        },
    },
}));
