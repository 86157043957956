import { CircularProgress, Grid, Typography } from '@mui/material';
import React from 'react';
import color from '../../../config/Colors';
import { vehicleController } from '../../../controllers';
import { BlobFile } from '../../../helpers/BlobFileHelper';
import { GridUploadBorder } from './UploadVRNPage';

type Props = {
    content?: JSX.Element;
    isLoadingDownload: boolean;
    setIsLoadingProgress: (val: boolean) => void;
};

export default function UploadBorderStep({ content, isLoadingDownload, setIsLoadingProgress }: Props) {
    const handleDownloadExample = async () => {
        if (isLoadingDownload === false) {
            setIsLoadingProgress(true);
            const nameFile = `Sample sheet.xlsx`;
            await vehicleController
                .DownloadExampleSheet()
                .then((res) => {
                    BlobFile(res, nameFile);
                })
                .finally(() => {
                    setIsLoadingProgress(false);
                });
        }
    };
    return (
        <Grid
            sx={{
                display: 'flex',
                flexDirection: 'column',
                alignItems: 'center',
                padding: '24px 0px',
            }}
        >
            <GridUploadBorder container mt={1}>
                {content}
            </GridUploadBorder>
            <Typography variant="body2" component="div" padding={'0 15px'} textAlign="center" mt={2}>
                <span style={{ fontWeight: 500 }}>Note: </span>
                <span>
                    Data must be in same format as per the sample. All mandatory fields must be filled out to ensure
                    correct data insertion.{' '}
                </span>
                <Typography component="span" variant="body2" sx={{ color: color.priText }}>
                    Download sample format:{' '}
                </Typography>
                <Typography
                    component="span"
                    variant="body2"
                    sx={{
                        color: color.priText,
                        textDecoration: 'underline',
                        '&:hover': {
                            cursor: 'pointer',
                        },
                    }}
                    onClick={handleDownloadExample}
                >
                    Sample sheet.{' '}
                    {isLoadingDownload && <CircularProgress size={8} sx={{ margin: 'auto', color: color.priText }} />}
                </Typography>
            </Typography>
        </Grid>
    );
}
