import { useEffect, useState } from 'react';
import { UseFormReturn } from 'react-hook-form';
import { ParentProps } from '..';
import { companyController } from '../../../controllers';
import { DataState, FormData, SelectionState } from '../components/model/UpsertVehicleList';
import { COMPANY_SCOPE_CUSTOM } from '../components/permitScope/PermitScopeItem';
import { LOCATION_SCOPE_CUSTOM, SCOPE_TYPE_CUSTOM, TENANT_SCOPE_ALL } from '../constant';
import { ScopeState } from './useVehicleListUpsert';

type Props = {
    parentProps: ParentProps;
    setPartialState: (partial: Partial<ScopeState>) => void;
    setPartialSelectionState: (partial: Partial<SelectionState>) => void;
    setPartialDataState: (partial: Partial<DataState>) => void;
    methods: UseFormReturn<FormData, any>;
    isEdit: boolean;
};

export default function useVehicleListLocation(props: Props) {
    const [locationLoading, setLocationLoading] = useState(false);
    const isGlobal = !props.parentProps.locationId && !props.parentProps.zoneId;

    useEffect(() => {
        if (!isGlobal && !props.isEdit) {
            getLocationById(Number(props.parentProps.locationId));
        }
        // eslint-disable-next-line react-hooks/exhaustive-deps
    }, [props.parentProps.locationId]);

    const getLocationById = (idLocation: number) => {
        setLocationLoading(true);
        companyController
            .CompanyToZone({ type: 'location', id: idLocation || 4934 })
            .then((res) => {
                const location = { ...res.locations![0] };
                props.setPartialState({ listCompanySelect: [res] });
                props.setPartialSelectionState({
                    companySelects: [
                        {
                            ...res,
                            companyScope: COMPANY_SCOPE_CUSTOM,
                            locations: [
                                { ...location, locationScope: LOCATION_SCOPE_CUSTOM, tenantScope: TENANT_SCOPE_ALL },
                            ],
                        },
                    ],
                });
                props.methods.setValue('scopeType', SCOPE_TYPE_CUSTOM, { shouldValidate: true });
            })
            .finally(() => {
                setLocationLoading(false);
            });
    };
    return { locationLoading };
}
