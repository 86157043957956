/* eslint-disable no-new-func */
import { PublicClientApplication } from '@azure/msal-browser';
import { MsalProvider } from '@azure/msal-react';
import { CssBaseline, ThemeProvider } from '@mui/material';
import React, { ReactNode } from 'react';
import * as ReactDOM from 'react-dom/client';
import { BrowserRouter } from 'react-router-dom';
import reactToWebComponent from 'react-to-webcomponent';
import { ToastContainer } from 'react-toastify';
import App from './App';
import { msalConfig } from './auth/authConfig';
import { Vehicle } from './controllers/vehicle/VehicleController';
import { AuthLayoutWithChildren, AuthPermission } from './layout/AuthLayout';
import { VehicleList as VehicleListModel } from './models/vehicleList/VehicleList';
import { EventLogAction, EventLogPage } from './pages/event-logs';
import VehicleListPCNPage from './pages/vehicle-list-pcn';
import { WrapVehicleListPageEmbed } from './pages/vehicleList';
import { VehicleListItemAction } from './pages/vehicleList/components/VehicleListItem';
import { VehicleListLayoutWithChildren } from './pages/vehicleList/VehicleListLayout';
import { VehicleListDetailAction, VehicleListDetails, WrapVehicleListComponent } from './pages/vehicleListDetails';
import { VehicleListUpsert, VehicleListUpsertAction } from './pages/vehicleListUpsert';
import { VRNPage, VRNPageAction } from './pages/vrn';
import { AddVRNAction, AddVRNPage, WrapEditVRNComponent } from './pages/vrn/AddVRN';
import './styles/daterangepicker.css';
import './styles/index.css';
import './styles/ReactToastify.css';
import theme from './theme/Theme';
import { ServiceProvider } from './providers/ServiceProvider';

const msalInstance = new PublicClientApplication(msalConfig);

const rootNode = document.getElementById('root') as HTMLElement;

export const isLib = process.env.REACT_APP_BUILD_LIB === 'true';

if (!isLib && rootNode) {
    const root = ReactDOM.createRoot(rootNode);

    root.render(
        <MsalProvider instance={msalInstance}>
            <ThemeProvider theme={theme}>
                <BrowserRouter>
                    <CssBaseline />
                    <App />
                    <ToastContainer
                        autoClose={2000}
                        hideProgressBar
                        closeButton={false}
                        position={'bottom-right'}
                        newestOnTop
                        pauseOnFocusLoss={false}
                    />
                </BrowserRouter>
            </ThemeProvider>
        </MsalProvider>
    );
}

type WrapProviderProps = {
    children: ReactNode;
    jwt: string;
    permission: AuthPermission;
};

const WrapProvider = ({ children, jwt, permission }: WrapProviderProps) => {
    return (
        <ThemeProvider theme={theme}>
            <BrowserRouter>
                <CssBaseline />
                <AuthLayoutWithChildren jwt={jwt} permission={permission}>
                    <ServiceProvider>{children}</ServiceProvider>
                </AuthLayoutWithChildren>
                <ToastContainer
                    autoClose={2000}
                    hideProgressBar
                    closeButton={false}
                    position={'bottom-right'}
                    newestOnTop
                    pauseOnFocusLoss={false}
                />
            </BrowserRouter>
        </ThemeProvider>
    );
};

const WrapVehicleListLayout = ({ children, jwt, permission }: WrapProviderProps) => {
    return (
        <WrapProvider jwt={jwt} permission={permission}>
            <VehicleListLayoutWithChildren>{children}</VehicleListLayoutWithChildren>
        </WrapProvider>
    );
};

type VehicleWrapProps = {
    'vehicle-list-id': string;
    'location-id': string;
    'zone-id': string;
    onadd: string;
    jwt: string;
    permission: AuthPermission;
};

const getFn = (fnName: string, ..._args: any): any => {
    if (!fnName) return () => {};
    return (window[fnName.split('(')[0] as any] as any)(_args);
};

const convertBaseProps = (props: VehicleWrapProps) => {
    return {
        zoneId: props['zone-id'],
        locationId: props['location-id'],
        vehicleListId: props['vehicle-list-id'],
        jwt: props.jwt,
        permission: props.permission,
    };
};

type VehicleListPageComponentProps = VehicleWrapProps & Record<Lowercase<keyof VehicleListItemAction>, string> & {};

const VehicleListPageComponent = (props: VehicleListPageComponentProps) => {
    const _props = {
        ...convertBaseProps(props),
        onAddVehicleList: () => getFn(props.onadd),
        vehicleListItemAction: {
            onAddPermit(vehicleList: VehicleListModel) {
                return getFn(props.onaddpermit, vehicleList.id, vehicleList.name);
            },
            onEdit(vehicleList: VehicleListModel) {
                return getFn(props.onedit, vehicleList.id, vehicleList.name);
            },
            onViewDetail(vehicleList: VehicleListModel) {
                return getFn(props.onviewdetail, vehicleList.id, vehicleList.name);
            },
            onViewVRN(vehicleList: VehicleListModel) {
                return getFn(props.onviewvrn, vehicleList.id, vehicleList.name);
            },
        },
    };
    // console.log(_props, _props.jwt);

    return (
        <WrapVehicleListLayout jwt={_props.jwt} permission={_props.permission}>
            <WrapVehicleListPageEmbed {..._props} />
        </WrapVehicleListLayout>
    );
};

type VehicleListUpsertComponentProps = VehicleWrapProps & Record<Lowercase<keyof VehicleListUpsertAction>, string> & {};

const VehicleListUpsertEmbed = (props: VehicleListUpsertComponentProps) => {
    // console.log('_props upsert', props);
    const _props = {
        ...convertBaseProps(props),
        action: {
            onCanceled() {
                getFn(props.oncanceled);
            },
            onSave() {
                getFn(props.onsave);
            },
            onSaveAndAddPermit(vehicleList: VehicleListModel) {
                getFn(props.onsaveandaddpermit, vehicleList.id, vehicleList.name);
            },
        } as VehicleListUpsertAction,
    };
    return (
        <WrapProvider jwt={_props.jwt} permission={_props.permission}>
            <VehicleListUpsert {..._props} action={_props.action} />
        </WrapProvider>
    );
};

type VehicleListDetailComponentProps = VehicleWrapProps & Record<Lowercase<keyof VehicleListDetailAction>, string> & {};

const VehicleListDetailComponent = (props: VehicleListDetailComponentProps) => {
    const _props = {
        ...convertBaseProps(props),
        action: {
            onAddVRN: (vehicleList: VehicleListModel) => getFn(props.onaddvrn, vehicleList.id, vehicleList.name),
            onEdit: (vehicleList: VehicleListModel) => {
                return getFn(props.onedit, vehicleList.id, vehicleList.name);
            },
            onViewVRN: (vehicleList: VehicleListModel) => {
                return getFn(props.onviewvrn, vehicleList.id, vehicleList.name);
            },
            onBack: (vehicleList: VehicleListModel) => getFn(props.onback, vehicleList.id, vehicleList.name),
            onViewEventLog: (vehicleList: VehicleListModel) => {
                return getFn(props.onvieweventlog, vehicleList.id, vehicleList.name);
            },
        } as VehicleListDetailAction,
    };

    return (
        <WrapVehicleListLayout jwt={_props.jwt} permission={_props.permission}>
            <WrapVehicleListComponent {..._props}>
                {(vehicleList, handleChangeActive, locationId, zoneId) => (
                    <VehicleListDetails
                        vehicleList={vehicleList}
                        locationId={locationId}
                        zoneId={zoneId}
                        action={_props.action}
                        onChangeActive={handleChangeActive}
                    />
                )}
            </WrapVehicleListComponent>
        </WrapVehicleListLayout>
    );
};

type VRNPageComponentProps = VehicleWrapProps & Record<Lowercase<keyof VRNPageAction>, string>;

const VRNPageComponent = (props: VRNPageComponentProps) => {
    const _props = {
        ...convertBaseProps(props),
        action: {
            onAddVRN: (vehicleList: VehicleListModel) => getFn(props.onaddvrn, vehicleList.id, vehicleList.name),
            onEdit: (vehicleList: VehicleListModel, vrnId: number) =>
                getFn(props.onedit, vehicleList.id, vrnId, vehicleList.name),
            onBack: (vehicleList: VehicleListModel) => getFn(props.onback, vehicleList.id, vehicleList.name),
            onEditVL: (vehicleList: VehicleListModel) => getFn(props.oneditvl, vehicleList.id, vehicleList.name),
        },
    };
    return (
        <WrapVehicleListLayout jwt={_props.jwt} permission={_props.permission}>
            <WrapVehicleListComponent {..._props}>
                {(vehicleList) => <VRNPage vehicleList={vehicleList} action={_props.action} />}
            </WrapVehicleListComponent>
        </WrapVehicleListLayout>
    );
};

type AddVRNComponentProps = VehicleWrapProps & Record<Lowercase<keyof AddVRNAction>, string>;

const AddVRNComponent = (props: AddVRNComponentProps) => {
    const _props = {
        ...convertBaseProps(props),
        action: {
            onCanceled: (vehicleList: VehicleListModel) => getFn(props.oncanceled, vehicleList.id, vehicleList.name),
            onSaveSuccess: (vehicleList: VehicleListModel) =>
                getFn(props.onsavesuccess, vehicleList.id, vehicleList.name),
        } as AddVRNAction,
    };

    return (
        <WrapVehicleListLayout jwt={_props.jwt} permission={_props.permission}>
            <WrapVehicleListComponent {..._props}>
                {(vehicleList) => <AddVRNPage vehicleList={vehicleList} action={_props.action} />}
            </WrapVehicleListComponent>
        </WrapVehicleListLayout>
    );
};

const EditVRNComponent = (props: AddVRNComponentProps & { 'vrn-id': string }) => {
    const _props = {
        ...convertBaseProps(props),
        vrnId: props['vrn-id'],
        action: {
            onCanceled: (vehicleList: VehicleListModel) => getFn(props.oncanceled, vehicleList.id, vehicleList.name),
            onSaveSuccess: (vehicleList: VehicleListModel) =>
                getFn(props.onsavesuccess, vehicleList.id, vehicleList.name),
        } as AddVRNAction,
    };
    return (
        <WrapVehicleListLayout jwt={_props.jwt} permission={_props.permission}>
            <WrapEditVRNComponent {..._props} />
        </WrapVehicleListLayout>
    );
};

const VehicleListPCNComponent = (
    props: {
        jwt: string;
        'location-id': number;
        permission: AuthPermission;
        'zone-reference': string;
        isaddpermission: number;
    } & Record<Lowercase<keyof Vehicle>, string>
) => {
    const _props = {
        jwt: props.jwt,
        vrn: {
            vrnNumber: props.vrnnumber,
            firstName: props.firstname,
            lastName: props.lastname,
            bayNumber: props.baynumber,
            type: props.type,
        } as Vehicle,
        isAddPermission: Boolean(Number(props.isaddpermission)),
    };

    return (
        <WrapProvider jwt={_props.jwt} permission={props.permission || AuthPermission.EDITABLE}>
            <VehicleListPCNPage
                vrn={_props.vrn}
                locationId={props['location-id']}
                zoneReference={props['zone-reference']}
                isAddPermission={_props.isAddPermission}
            />
        </WrapProvider>
    );
};

type EventLogsComponentProps = VehicleWrapProps & Record<Lowercase<keyof EventLogAction>, string>;

const EventLogsComponent = (props: EventLogsComponentProps) => {
    const _props = {
        ...convertBaseProps(props),
        action: {
            onEdit(vehicleList: VehicleListModel) {
                return getFn(props.onedit, vehicleList.id, vehicleList.name);
            },
        },
    };
    return (
        <WrapVehicleListLayout jwt={_props.jwt} permission={_props.permission}>
            <WrapVehicleListComponent {..._props}>
                {(vehicleList) => <EventLogPage vehicleList={vehicleList} action={_props.action} />}
            </WrapVehicleListComponent>
        </WrapVehicleListLayout>
    );
};

const baseEmbedProps = {
    'zone-id': String,
    'location-id': String,
    jwt: String,
    permission: Number,
    onback: String,
};

const VehicleListPageComponentPropTypes = {
    ...baseEmbedProps,
    onadd: String,
    onedit: String,
    onviewdetail: String,
    onaddpermit: String,
    onviewvrn: String,
};

const VehicleListUpsertComponentPropTypes = {
    ...baseEmbedProps,
    'vehicle-list-id': String,
    oncanceled: String,
    onsave: String,
    onsaveandaddpermit: String,
};

const VehicleListDetailComponentPropTypes = {
    ...baseEmbedProps,
    'vehicle-list-id': String,
    onaddvrn: String,
    onviewvrn: String,
    onedit: String,
    onvieweventlog: String,
};

const VRNPageComponentPropTypes = {
    ...baseEmbedProps,
    'vehicle-list-id': String,
    onaddvrn: String,
    onedit: String,
    oneditvl: String,
};

const AddVRNComponentPropTypes = {
    ...baseEmbedProps,
    'vehicle-list-id': String,
    oncanceled: String,
    onsavesuccess: String,
};

const EditVRNComponentPropTypes = {
    ...AddVRNComponentPropTypes,
    'vrn-id': String,
};

const VehicleListPCNProps = {
    ...baseEmbedProps,
    permitsid: String,
    firstname: String,
    lastname: String,
    type: String,
    vrnnumber: String,
    baynumber: String,
    'zone-reference': String,
    isaddpermission: String,
};

const EventLogsComponentPropTypes = {
    ...baseEmbedProps,
    'vehicle-list-id': String,
    onedit: String,
};

const VehicleList = reactToWebComponent(VehicleListPageComponent as any, React as any, ReactDOM as any, {
    props: VehicleListPageComponentPropTypes,
});
const VehicleListDetail = reactToWebComponent(VehicleListDetailComponent as any, React as any, ReactDOM as any, {
    props: VehicleListDetailComponentPropTypes,
});
const VehicleListUpsertTag = reactToWebComponent(VehicleListUpsertEmbed as any, React as any, ReactDOM as any, {
    props: VehicleListUpsertComponentPropTypes,
});
const VRNList = reactToWebComponent(VRNPageComponent as any, React as any, ReactDOM as any, {
    props: VRNPageComponentPropTypes,
});
const AddVRN = reactToWebComponent(AddVRNComponent as any, React as any, ReactDOM as any, {
    props: AddVRNComponentPropTypes,
});
const EditVRN = reactToWebComponent(EditVRNComponent as any, React as any, ReactDOM as any, {
    props: EditVRNComponentPropTypes,
});
const VehicleListPCN = reactToWebComponent(VehicleListPCNComponent as any, React as any, ReactDOM as any, {
    props: VehicleListPCNProps,
});
const VehicleListEventLogs = reactToWebComponent(EventLogsComponent as any, React as any, ReactDOM as any, {
    props: EventLogsComponentPropTypes,
});

customElements.define('vehicle-list', VehicleList as any);
customElements.define('vehicle-list-detail', VehicleListDetail as any);
customElements.define('vehicle-list-upsert', VehicleListUpsertTag as any);
customElements.define('vrn-list', VRNList as any);
customElements.define('add-vrn', AddVRN as any);
customElements.define('edit-vrn', EditVRN as any);
customElements.define('vehicle-list-pcn', VehicleListPCN as any);
customElements.define('event-logs', VehicleListEventLogs as any);
