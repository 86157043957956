/* eslint-disable jsx-a11y/anchor-is-valid */
import React, { ReactNode } from 'react';

type Props = {
    to: string;
    children: ReactNode;
    isDisable?: boolean;
};

export default function LinkTo(props: Props) {
    return (
        <a
            href={props.to}
            style={{ color: 'inherit', textDecoration: 'none', pointerEvents: props.isDisable ? 'none' : 'auto' }}
        >
            {props.children}
        </a>
    );
}
