import { BaseHttpController } from 'ale-base-model';
import { AxiosInstance } from 'axios';
import Zone from '../../models/scope/Zone';

export interface GetAllProps { }
export class ZoneController extends BaseHttpController<Zone> {
    constructor(serviceUrl: string, client: AxiosInstance) {
        super(serviceUrl, 'Zone', client);
    }

    async getAll(props: GetAllProps): Promise<Zone[]> {
        const res = await this.doGet({ path: 'getAll', config: {} });
        return res.data;
    }

    async getZonesByVehicleList(LocationId: number, VehicleListId: number): Promise<Zone[]> {
        const res = await this.doPost({ path: 'MyZoneByVehicleListId', body: { LocationId, VehicleListId }, config: {} })
        return res.data;
    }

    async getZones(idLocation: number): Promise<Zone[]> {
        const res = await this.client.get(`${this.serviceURL}/Zone/myZones/${idLocation}`);
        return res.data;
    }
    async getZonesDetail(idLocation: number) {
        const res = await this.client.get(`${this.serviceURL}/Zone/myZones/${idLocation}`);
        return res.data;
    }

    async permitWithAllocated(
        zoneIds: number[],
        tenantId: number
    ): Promise<{ maxAllocated: number; allocatedPermits: number }> {
        const res = await this.doPost({ path: 'permitWithAllocated', body: { zoneIds, tenantId }, config: {} });
        return res.data;
    }
}
