/* eslint-disable react-hooks/exhaustive-deps */
import { useMsal } from '@azure/msal-react';
import React, { useEffect } from 'react';

export default function LogoutPage() {
    const { instance } = useMsal();

    useEffect(() => {
        instance.logout({});
    }, []);

    return <div>LogoutPage</div>;
}
