import { Box, Grid, styled, Tooltip, tooltipClasses, TooltipProps, Typography } from '@mui/material';
import color from '../config/Colors';

export const FCenter = styled(Grid)({
    display: 'flex',
    flexDirection: 'column',
});

export const FTypographyRow = styled(Typography)({
    display: 'flex',
    flexDirection: 'row',
});

export const BoxRadiusGrey = styled(Box)({
    width: '100%',
    background: color.grey100,
    borderRadius: 10,
    padding: 16,
});

export const GFlexRow = styled(Grid)({
    display: 'flex',
    flexDirection: 'row',
    alignItems: 'center',
});

export const GFlexColumn = styled(Grid)({
    display: 'flex',
    flexDirection: 'column',
});

export const GFlexRowCenter = styled(Grid)({
    display: 'flex',
    flexDirection: 'row',
    alignItems: 'center',
});

export const GRadiusItem = styled(Grid)({
    borderRadius: 10,
    background: color.white,
    padding: '16px 24px',
    border: '1px solid #DDDDDD',
});

export const DangerTooltip = styled(({ className, ...props }: TooltipProps) => (
    <Tooltip {...props} classes={{ popper: className }} />
))(({ theme }) => ({
    [`& .${tooltipClasses.tooltip}`]: {
        backgroundColor: color.danger,
        color: color.white,
    },
    [`& .${tooltipClasses.arrow}`]: {
        '&:before': {
            // border: `1px solid ${color.danger}`,
            color: color.danger,
        },
        color,
    },
}));

export const TitleWithBackButton = styled(Typography)({
    display: 'flex',
    flexDirection: 'row',
    alignItems: 'center',
    gap: '10px',
});
