import { BaseHttpController } from 'ale-base-model';
import { AxiosInstance } from 'axios';
import { VehicleList } from '../../models/vehicleList/VehicleList';
import { WhiteList, WhiteListFilterResponse } from '../../models/whiteList/WhiteList';
import { PermitTypeVl } from '../../models/permit/Permit';

export interface FilterWhiteListProps {
    page: number;
    pageSize: number;
    search: Search;
    filter: Partial<FilterDetail>;
}

export interface Search {
    fields: string[];
    value: string;
}

export interface FilterDetail {
    vehicleListId: number;
    from: Date | null;
    to: Date | null;
    zoneIds: any[];
    locationId: number;
    regionId: number;
    companyId: number;
    tenantIds: any[];
    permitTypeVl?: PermitTypeVl;
    serviceId?: number;
    serviceType?: number;
}

export class WhiteListController extends BaseHttpController<WhiteList> {
    constructor(serviceUrl: string, client: AxiosInstance) {
        super(serviceUrl, 'WhiteList', client);
    }

    // async getAll(): Promise<Service[]> {
    //     const res = await this.doGet({ path: 'getAll', config: {} })
    //     return res.data
    // }

    getById(id: number) {
        return this.doGet({ path: `${id}`, config: {} }).then((res) => res.data);
    }

    async filter(props: FilterWhiteListProps): Promise<WhiteListFilterResponse> {
        const res = await this.doPost({ path: 'filter', body: props, config: {} });
        return res.data;
    }

    async changeStatus(id: number) {
        const res = await this.doPost({ path: `ChangeStatus/${id}`, config: {} });
        return res.data;
    }

    async upSert(vehicleList: VehicleList) {
        const res = await this.doPost({ path: `Upsert`, body: vehicleList, config: {} });
        return res.data;
    }
}
