import { AddBoxOutlined } from '@material-ui/icons';
import { ButtonBase, Tooltip, Typography } from '@mui/material';
import moment from 'moment';
import { useContext, useState } from 'react';
import color from '../../config/Colors';
import { vehicleController } from '../../controllers';
import { Vehicle } from '../../controllers/vehicle/VehicleController';
import { AuthContext, AuthPermission } from '../../layout/AuthLayout';
import { VehicleListWithZone } from '../../models/vehicleList/VehicleList';
import { GetVehicleListDescription } from '../vehicleList/helpers';
import { cx } from './components/BPagination';
import { formatDate } from '../../helpers/moment';

const VItem = (props: {
    item: VehicleListWithZone;
    onView(item: VehicleListWithZone): void;
    onAddSuccess(): void;
    vrn: Vehicle;
    isAdd: boolean;
}) => {
    const { permission } = useContext(AuthContext);
    const readOnly = permission === AuthPermission.READ_ONLY;

    const item = props.item;
    const [loading, setLoading] = useState(false);

    const handleAddVRN = () => {
        setLoading(true);
        vehicleController
            .upSert([
                {
                    permitsId: item.permits![0].id!,
                    vrnNumber: props.vrn.vrnNumber || '',
                    firstName: props.vrn.firstName || '',
                    lastName: props.vrn.lastName || '',
                    type: props.vrn.type || '',
                    bayNumber: props.vrn.bayNumber || '',
                } as Vehicle,
            ])
            .then((res) => {
                setLoading(false);
                props.onAddSuccess();
            });
    };

    return (
        <tr>
            <td className={`${cx('text-success', item.isExists)}`}>
                <Tooltip
                    title={GetVehicleListDescription(item.permits?.[0], item.serviceId, true)}
                    placement="top"
                    arrow
                >
                    <Typography sx={{ '&:hover': { cursor: 'pointer', color: color.success } }}>{item.name}</Typography>
                </Tooltip>
            </td>

            <td className={`${cx('text-success', item.isExists)}`}>
                {formatDate.getLocalDateString(item.created, 'DD.MM.YYYY')}
            </td>
            <td className={`${cx('text-success', item.isExists)}`}>
                {item.isExists ? 'VRN exists within this V.list' : 'VRN does not exist within this V.list'}
            </td>
            <td>
                {!item.isExists ? (
                    <ButtonBase
                        className="text-success font-weight-bold"
                        disabled={!props.isAdd || readOnly || loading}
                        sx={{
                            padding: '0px',
                            borderRadius: '5px',
                            '&.Mui-disabled': {
                                opacity: 0.5,
                            },
                        }}
                        onClick={handleAddVRN}
                    >
                        <AddBoxOutlined style={{ marginBottom: -1, marginRight: '4px' }} />
                        Add VRN to V.list
                    </ButtonBase>
                ) : (
                    <ButtonBase
                        className="text-primary font-weight-bold"
                        sx={{ padding: '0px', borderRadius: '5px' }}
                        onClick={() => props.onView(item)}
                    >
                        View
                    </ButtonBase>
                )}
            </td>
        </tr>
    );
};

export default VItem;
