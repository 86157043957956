/* eslint-disable eqeqeq */
import * as React from 'react';
import TextField from '@mui/material/TextField';
import Autocomplete, { autocompleteClasses } from '@mui/material/Autocomplete';
import useMediaQuery from '@mui/material/useMediaQuery';
import ListSubheader from '@mui/material/ListSubheader';
import Popper from '@mui/material/Popper';
import { useTheme, styled } from '@mui/material/styles';
import { VariableSizeList, ListChildComponentProps } from 'react-window';
import Typography from '@mui/material/Typography';
import { Box, CircularProgress } from '@mui/material';
import SearchIcon from '../../../components/svg/SearchIcon';
import color from '../../../config/Colors';

const LISTBOX_PADDING = 8; // px

function renderRow(props: ListChildComponentProps) {
    const { data, index, style } = props;
    const dataSet = data[index];
    const inlineStyle = {
        ...style,
        top: (style.top as number) + LISTBOX_PADDING,
    };

    if (dataSet.hasOwnProperty('group')) {
        return (
            <ListSubheader key={dataSet.key} component="div" style={inlineStyle}>
                {dataSet.group}
            </ListSubheader>
        );
    }

    return (
        <Typography
            component="li"
            {...dataSet[0]}
            noWrap
            style={inlineStyle}
            sx={{ borderBottom: '1px solid #ddd', borderRadius: '2px' }}
        >
            {dataSet[1].name}
        </Typography>
    );
}

const OuterElementContext = React.createContext({});

const OuterElementType = React.forwardRef<HTMLDivElement>((props, ref) => {
    const outerProps = React.useContext(OuterElementContext);
    return <div ref={ref} {...props} {...outerProps} />;
});

function useResetCache(data: any) {
    const ref = React.useRef<VariableSizeList>(null);
    React.useEffect(() => {
        if (ref.current != null) {
            ref.current.resetAfterIndex(0, true);
        }
    }, [data]);
    return ref;
}

// Adapter for react-window
const ListboxComponent = React.forwardRef<HTMLDivElement, React.HTMLAttributes<HTMLElement>>(function ListboxComponent(
    props,
    ref
) {
    const { children, ...other } = props;
    const itemData: React.ReactChild[] = [];
    (children as React.ReactChild[]).forEach((item: React.ReactChild & { children?: React.ReactChild[] }) => {
        itemData.push(item);
        itemData.push(...(item.children || []));
    });

    const theme = useTheme();
    const smUp = useMediaQuery(theme.breakpoints.up('sm'), {
        noSsr: true,
    });
    const itemCount = itemData.length;
    const itemSize = smUp ? 36 : 48;

    const getChildSize = (child: React.ReactChild) => {
        if (child.hasOwnProperty('group')) {
            return 48;
        }

        return itemSize;
    };

    const getHeight = () => {
        if (itemCount > 8) {
            return 8 * itemSize;
        }
        return itemData.map(getChildSize).reduce((a, b) => a + b, 0);
    };

    const gridRef = useResetCache(itemCount);

    return (
        <Box
            ref={ref}
            style={{ padding: '0 0 0 5px' }}
            sx={{
                '& .MuiAutocomplete-listbox::-webkit-scrollbar': {
                    width: '6px',
                },
                '& .MuiAutocomplete-listbox::-webkit-scrollbar-track': {
                    background: '#f1f1f178',
                    padding: '0 2px',
                },
                '& .MuiAutocomplete-listbox::-webkit-scrollbar-thumb': {
                    background: '#888',
                    borderRadius: '5px',
                },
                '& .MuiAutocomplete-listbox::-webkit-scrollbar-thumb:hover': {
                    background: '#555',
                },
            }}
        >
            <OuterElementContext.Provider value={other}>
                <VariableSizeList
                    itemData={itemData}
                    height={getHeight() + 2 * LISTBOX_PADDING}
                    width="100%"
                    ref={gridRef}
                    outerElementType={OuterElementType}
                    innerElementType="ul"
                    itemSize={(index) => getChildSize(itemData[index])}
                    overscanCount={5}
                    itemCount={itemCount}
                    className="styled-scroll"
                >
                    {renderRow}
                </VariableSizeList>
            </OuterElementContext.Provider>
        </Box>
    );
});

const StyledPopper = styled(Popper)({
    [`& .${autocompleteClasses.listbox}`]: {
        boxSizing: 'border-box',
        '& ul': {
            padding: 0,
            margin: 0,
        },
    },
});

type Props<T> = {
    listData: T[];
    itemValue?: T | null;
    keyEqual: keyof T;
    keyLabel: keyof T;
    label: string | JSX.Element;
    disabled?: boolean;
    setFilter: (option: T) => void;
    defaultData?: T;
    key?: any;
    isLoading?: boolean;
};

export default function VLSelectPortfolio<T>(props: Props<T>) {
    const [text, setText] = React.useState<string>('');
    return (
        <BoxIAutoComplete>
            <Autocomplete
                fullWidth
                id="virtualize-demo"
                disableListWrap
                PopperComponent={StyledPopper}
                noOptionsText={'No option'}
                ListboxComponent={ListboxComponent}
                options={props.listData}
                getOptionLabel={(option) => (option[props.keyLabel] as string) ?? ''}
                isOptionEqualToValue={(option, value) => option[props.keyEqual] == value[props.keyEqual]}
                inputValue={text}
                renderOption={(props, option) => [props, option] as React.ReactNode}
                value={null}
                size="small"
                renderInput={(params) => (
                    <TextField
                        {...params}
                        InputProps={{ ...params.InputProps, disableUnderline: true }}
                        onChange={(e) => setText(e.target.value)}
                        variant="standard"
                        placeholder="Please enter portfolio name and select to add"
                    />
                )}
                popupIcon={props.isLoading ? <CircularProgress size={'14px'} /> : <SearchIcon />}
                sx={{
                    [`& .${autocompleteClasses.popupIndicator}`]: {
                        transform: 'none',
                    },
                }}
                onChange={(event, value) => {
                    if (value !== null) {
                        props.setFilter(value);
                        setText('');
                    }
                }}
                disabled={props.isLoading}
                // onChange={(event, value) => props.setFilter(value)}
                // TODO: Post React 18 update - validate this conversion, look like a hidden bug
                //   renderGroup={(params) => params as unknown as React.ReactNode}
            />
        </BoxIAutoComplete>
    );
}

export const BoxIAutoComplete = styled(Box)({
    '& #combo-box-demo-listbox': {
        padding: '5px',
    },
    '& .MuiAutocomplete-listbox': {
        padding: '5px',
    },
    '& .MuiAutocomplete-option': {
        minHeight: 'auto !important',
        mb: '2px',
        borderBottom: `1px solid ${color.grey200}`,
        borderRadius: '4px',
    },
    //css first item chosen
    '& .MuiAutocomplete-groupLabel': {
        fontSize: '14px',
        lineHeight: 'normal',
        padding: '10px 20px',
        color: 'black',
        // fontWeight: 600,
        background: '#E5F2F8',
        my: '2px',
    },
    width: '100%',
});
