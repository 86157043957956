/* eslint-disable react-hooks/exhaustive-deps */
import { Box, TextField } from '@mui/material';
import { DatePicker, PickersDay } from '@mui/x-date-pickers';
import { addDays } from 'date-fns';
import moment from 'moment';
import React, { useEffect } from 'react';
import { Controller, useFormContext } from 'react-hook-form';
import CalendarIcon from '../../../../components/svg/CalendarIcon';
import LabelWithRequired from '../../../../components/textfield/LabelWithRequired';
import { RecurringType } from '../../../../models/permit/PermitRecurring';
import { FormData } from '../model/UpsertVehicleList';
import { useStylesPicker } from './InternalPermitDateRange';
import { DurationProps } from './InternalPermitTimeDuration';

export default function StartDateSelect(props: DurationProps) {
    const {
        formState: { errors },
        control,
        watch,
        setValue,
        setError,
    } = useFormContext<FormData>();
    const { internalPermitData } = watch();

    const { validateFrom, recurringType, validateTo } = internalPermitData;
    const classes = useStylesPicker();

    useEffect(() => {
        if (internalPermitData.validateFrom === null) {
            setError('internalPermitData.validateFrom', { type: 'required', message: 'This field is required' });
        }
    }, [internalPermitData.validateFrom]);

    return (
        <Controller
            name="internalPermitData.validateFrom"
            control={control}
            render={({ field, fieldState, formState }) => {
                return (
                    <DatePicker
                        label={<LabelWithRequired label={'Start date'} isShowRequired={true} />}
                        value={validateFrom}
                        disabled={props.isEdit}
                        onChange={(newValue, _) => {
                            if (newValue != null) {
                                field.onChange(newValue);
                                if (recurringType === Number(RecurringType.once)) {
                                    setValue('internalPermitData.validateTo', addDays(newValue, 1), {
                                        shouldTouch: true,
                                    });
                                } else {
                                    if (!props.isEdit) {
                                        setValue('internalPermitData.validateTo', null, {
                                            shouldTouch: true,
                                            shouldValidate: true,
                                        });
                                    } else {
                                        if (moment(validateTo).diff(moment(newValue)) <= 0) {
                                            setValue('internalPermitData.validateTo', null, {
                                                shouldTouch: true,
                                                shouldValidate: true,
                                            });
                                        }
                                    }
                                }
                            }
                        }}
                        components={{
                            OpenPickerIcon: CalendarIcon,
                        }}
                        OpenPickerButtonProps={{ className: classes.inputStyle }}
                        renderInput={(params) => (
                            <TextField
                                {...params}
                                error={Boolean(errors.internalPermitData?.validateFrom)}
                                helperText={errors.internalPermitData?.validateFrom?.message}
                                fullWidth={true}
                                sx={{
                                    '& .MuiInputBase-input': {
                                        caretColor: 'transparent',
                                    },
                                }}
                                onKeyDown={(e) => {
                                    e.preventDefault();
                                }}
                            />
                        )}
                        renderDay={(day, _value, DayComponentProps) => {
                            return (
                                <Box className={classes.inputStyle} key={JSON.stringify(day)}>
                                    <PickersDay {...DayComponentProps} />
                                </Box>
                            );
                        }}
                        inputFormat="dd/MM/yyyy"
                        minDate={new Date()}
                        PopperProps={{
                            placement: 'bottom-start',
                        }}
                    />
                );
            }}
        />
    );
}
