import { Stack, TextField, Typography } from '@mui/material';
import React, { useContext } from 'react';
import { UseFormReturn } from 'react-hook-form';
import color from '../../../../config/Colors';
import { VehicleListUpsertContext } from '../../hooks/useVehicleListUpsert';
import { FormData } from '../model/UpsertVehicleList';

export type PropsRecurring = {
    recurringEvery: number;
    setREcurringEvery: (val: number) => void;
    methods: UseFormReturn<FormData, any>;
    isEdit: boolean;
};

export default function DayRecurring(props: PropsRecurring) {
    const { handleChangeValidateFromWhenEdit } = useContext(VehicleListUpsertContext);

    return (
        <Stack
            flex={1}
            direction={'row'}
            padding={'16px 8px'}
            sx={{ background: color.grey100, borderRadius: '5px' }}
            alignItems="center"
        >
            <Typography variant="body1" component={'span'}>
                {'Recurring every'}
            </Typography>

            <TextField
                sx={{ minWidth: '100px', mx: 2 }}
                type="number"
                size="small"
                inputProps={{ min: 1, max: 365 }}
                disabled={props.isEdit}
                value={props.recurringEvery}
                onChange={(e) => {
                    const value = Number(e.target!.value);
                    const val = value < 1 ? 1 : value > 365 ? 365 : value;
                    props.setREcurringEvery(val);
                    handleChangeValidateFromWhenEdit();
                }}
            />

            <Typography variant="body1" component={'span'}>
                {'day(s)'}
            </Typography>
        </Stack>
    );
}
