/* eslint-disable react-hooks/exhaustive-deps */
import { DependencyList, useEffect, useState } from 'react';
import { StyledAutocompleteProps } from '.';
import { FilterRequest, Pagination } from '../../models/filter/BaseFilter';
import { pushError } from '../toast';
import { omit } from 'lodash';

export default function useStyledAutocomplete<T, P extends FilterRequest<T>>(
    props: UseAutocomplete<T, P>
): TypeReturn<T, P> {
    const { listProps } = props;

    const [state, setState] = useState<State<T>>({
        initLoading: !props.disabled,
        searchLoading: false,
        hasNextPage: true,
        isNextPageLoading: true,
        paging: {
            page: 1,
            pageSize: props.listProps?.pageSize || 20,
            data: [],
            total: 0,
            totalPage: 1,
        },
    });

    const getList = async (_props?: Partial<P>, reset?: boolean) => {
        if (props.list) {
            setState((prev) => {
                const _state: State<T> = {
                    hasNextPage: false,
                    isNextPageLoading: false,
                    paging: {
                        page: 1,
                        pageSize: 100000,
                        data: props.list!.options.filter((o) => props.list?.isFiltered(o, _props?.search?.value || '')),
                        total: props.list!.options.length,
                        totalPage: 1,
                    },
                    searchLoading: false,
                    initLoading: false,
                };

                return _state;
            });
        } else {
            setState((prev) => ({ ...prev, isNextPageLoading: true }));

            try {
                const _filter: P = {
                    page: 1,
                    pageSize: 20,
                    filter: {
                        ...listProps?.filter,
                        ..._props?.filter,
                    },
                    ...omit(listProps, 'filter'),
                    ...omit(_props, 'filter'),
                    search: { fields: listProps?.search?.fields ?? [], value: _props?.search?.value ?? '' },
                } as P;
                if (reset) {
                    _filter.filter = {};
                    _filter.search.value = '';
                    _filter.page = 1;
                }

                const res = await props.getAllList!(_filter);

                setState((prev) => ({
                    initLoading: false,
                    searchLoading: false,
                    isNextPageLoading: false,
                    paging: {
                        ...res,
                        data: _props?.page === 1 ? res.data : prev.paging.data.concat(res.data),
                    },
                    hasNextPage: res.page < res.totalPage,
                }));
            } catch (error) {}
        }
    };

    const reset = () => {
        setState((prev) => ({ ...prev, initLoading: true }));
        return getList(undefined, true);
    };

    const getListFn = (_props?: Partial<P>) => {
        setState((prev) => ({ ...prev, initLoading: true }));
        return getList(_props);
    };

    const fetchMore = (searchValue: string) => {
        getList({
            page: state.paging.page + 1,
            search: { value: searchValue },
        } as P);
    };
    const handleChangeSearch = (text: string) => {
        setState((prev) => ({ ...prev, searchLoading: true }));
        getList({ page: 1, search: { value: text } } as P);
    };

    useEffect(() => {
        if (!props.disabled) {
            setState((prev) => ({ ...prev, initLoading: true }));
            getList({ page: 1 } as any);
        }
    }, [...(props.dependencyList || [])]);

    return {
        ...props,
        loadNextPage: fetchMore,
        handleChangeSearch,
        paging: state.paging,
        hasNextPage: state.hasNextPage,
        isNextPageLoading: state.isNextPageLoading,
        searchLoading: state.searchLoading,
        loading: props.loading || state.initLoading,
        getList: getListFn,
        reset,
    };
}

export type UseAutocomplete<T, P extends FilterRequest<T>> = Partial<StyledAutocompleteProps<T>> & {
    list?: {
        options: T[];
        isFiltered(option: T, searchText: string): boolean;
    };
    getAllList?(props: P): Promise<Pagination<T>>;
    listProps?: Partial<P>;
    dependencyList?: DependencyList;
};

type State<T> = {
    initLoading?: boolean;
    searchLoading: boolean;
    hasNextPage: boolean;
    isNextPageLoading: boolean;
    paging: Pagination<T>;
};

type TypeReturn<T, P> = Pick<
    StyledAutocompleteProps<T>,
    | 'loadNextPage'
    | 'handleChangeSearch'
    | 'paging'
    | 'hasNextPage'
    | 'isNextPageLoading'
    | 'searchLoading'
    | 'loading'
    | 'value'
    | 'onChange'
    | 'isSync'
> & { getList(props?: P): Promise<void>; reset(): void };
