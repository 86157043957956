import React, { SVGProps } from 'react';

type Props = {} & SVGProps<SVGSVGElement>;

export default function MenuDeleteIcon(props: Props) {
    return (
        <svg {...props} width="24" height="24" viewBox="0 0 24 24" fill="none" xmlns="http://www.w3.org/2000/svg">
            <path
                d="M12.5 19.5C13.3284 19.5 14 18.8284 14 18C14 17.1716 13.3284 16.5 12.5 16.5C11.6716 16.5 11 17.1716 11 18C11 18.8284 11.6716 19.5 12.5 19.5Z"
                fill="#85858A"
            />
            <path
                d="M14 12C14 12.8284 13.3284 13.5 12.5 13.5C11.6716 13.5 11 12.8284 11 12C11 11.1716 11.6716 10.5 12.5 10.5C13.3284 10.5 14 11.1716 14 12Z"
                fill="#85858A"
            />
            <path
                d="M12.5 7.5C13.3284 7.5 14 6.82843 14 6C14 5.17157 13.3284 4.5 12.5 4.5C11.6716 4.5 11 5.17157 11 6C11 6.82843 11.6716 7.5 12.5 7.5Z"
                fill="#85858A"
            />
        </svg>
    );
}
