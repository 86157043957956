import { Box, Stack, TextField, Typography } from '@mui/material';
import React, { useContext } from 'react';
import color from '../../../../config/Colors';
import { VehicleListUpsertContext } from '../../hooks/useVehicleListUpsert';
import { PropsRecurring } from './DayRecurring';

export default function MonthRecurring({ recurringEvery, setREcurringEvery, methods, isEdit }: PropsRecurring) {
    const getIndicator = (num: Number) => {
        return num === 1 || num === 21 || num === 31
            ? 'st'
            : num === 2 || num === 22
            ? 'nd'
            : num === 3 || num === 23
            ? 'rd'
            : 'th';
    };

    const isValidDateOfAllMonth = () => {
        return ![29, 30, 31].includes(recurringEvery);
    };
    const { handleChangeValidateFromWhenEdit } = useContext(VehicleListUpsertContext);

    return (
        <Stack
            flex={1}
            direction={'column'}
            padding={'16px 8px'}
            sx={{ background: color.grey100, borderRadius: '5px' }}
            alignItems="flex-start"
        >
            <Stack direction={'row'} alignItems="center">
                <Typography variant="body1" component={'span'} mr={2}>
                    {'on the'}
                </Typography>
                <TextField
                    placeholder={''}
                    sx={{ minWidth: '120px', mr: 2 }}
                    type="number"
                    size="small"
                    inputProps={{ min: 1, max: 31 }}
                    value={recurringEvery}
                    disabled={isEdit}
                    onChange={(e) => {
                        const value = Number(e.target!.value);
                        const val = value < 1 ? 1 : value > 31 ? 31 : value;
                        setREcurringEvery(val);
                        handleChangeValidateFromWhenEdit();
                    }}
                />
                <Typography variant="body1" component={'span'}>
                    {getIndicator(recurringEvery || 1)} {'of every month'}
                </Typography>
            </Stack>

            {isValidDateOfAllMonth() === false && (
                <Box mt={3}>
                    <Typography fontSize={10} color={color.textPrimary}>
                        {
                            'The scheduled permit will not recur for the months that do not have the date specified. You can schedule permits with the "One day permit" function for the days missing.'
                        }
                    </Typography>
                </Box>
            )}
        </Stack>
    );
}
