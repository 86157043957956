import { Permit, PermitType, ValidateToType } from './../../../models/permit/Permit';
import { PermitRecurring, RecurringType } from '../../../models/permit/PermitRecurring';
import { BaseSelect } from '../../vehicleList/hooks/useVehicleList';
import * as Yup from 'yup';
import moment from 'moment';
import { FormData } from '../components/model/UpsertVehicleList';

export const REGIS_TYPE_FIXED_TIME = 1;
export const REGIS_TYPE_SPECIFIC_DURATION = 2;
export const IPARK_PERMIT_TIME_REGEX = /^(?:\d+d(?:( [1-9][0-9]*)+h?)|([1-9][0-9]*)+[dh]?)$/;
export const ALL_DAY_SECONDS = 24 * 60 * 60

export const ListRecurring: BaseSelect[] = [
    {
        id: RecurringType.once,
        name: 'One day permit',
        desc: 'Schedule permits for one day only, permits can be scheduled for a specific time period or all day',
    },
    {
        id: RecurringType.day,
        name: 'Daily',
        desc: "Schedule permits to recur every 'x' number of days",
    },
    {
        id: RecurringType.week,
        name: 'Weekly',
        desc: 'Schedule permits to recur on specified days of the week',
    },
    {
        id: RecurringType.month,
        name: 'Monthly',
        desc: 'Schedule permits to recur on a particular date of the month',
    },
    {
        id: RecurringType.indefinite,
        name: 'Indefinite',
        desc: 'Schedule permits to recur indefinitely at all times',
    },
];

export const VehicleListSchema = Yup.object().shape({
    nameVehicleList: Yup.string().required('Name of vehicle list is required'),
    scopeType: Yup.number(),
    serviceType: Yup.number(),
    internalPermitData: Yup.object().when('serviceType', {
        is: (serviceType: Number) => serviceType === SERVICE_TYPE_INTERNAL_PERMIT,
        then: Yup.object({
            permitType: Yup.number().required(),
            endStatus: Yup.number(),
            validateFrom: Yup.date().typeError('This field is required').required('This field is required'),
            recurringType: Yup.number(),
            validateTo: Yup.date()
                .nullable()
                .when('endStatus', {
                    is: 0,
                    then: Yup.date().when('validateFrom', (validateFrom, schema) => {
                        return schema
                            .required('This field is required')
                            .min(
                                moment(validateFrom).add(-1, 'day').toDate(),
                                'End date must be greater than or equal to the start date'
                            )
                            .typeError('This field is required');
                    }),
                }),
            fromHour: Yup.date().typeError('This field is required').required('This field is required'),
            toHour: Yup.date()
                .when('fromHour', (fromHour, schema) => {
                    if (moment(fromHour, 'YYYY-MM-DD').isValid()) {
                        const fromTime = new Date(fromHour.getTime());
                        const validateTime = moment(fromTime).add(1, 'minutes').toDate();
                        return schema
                            .typeError('This field is required')
                            .min(validateTime, 'Must be greater than start time')
                            .required('This field is required');
                    } else {
                        return schema;
                    }
                })
                .required('This field is required')
                .typeError('This field is required'),
        }),
    }),
    iParkData: Yup.object().when('serviceType', {
        is: (serviceType: Number) => serviceType === SERVICE_TYPE_IPARK,
        then: Yup.object({
            regisType: Yup.number(),
            fixedTime: Yup.string()
                .nullable()
                .when('regisType', {
                    is: REGIS_TYPE_FIXED_TIME,
                    then: Yup.string()
                        .matches(IPARK_PERMIT_TIME_REGEX, 'Use the format: 4d 6h')
                        .typeError('This field is required'),
                    otherwise: Yup.string().nullable(),
                }),
            specificTime: Yup.number()
                .nullable()
                .when('regisType', {
                    is: REGIS_TYPE_SPECIFIC_DURATION,
                    then: Yup.number().min(1, 'Minimum day must be 1').typeError('This field is required'),
                    otherwise: Yup.number().nullable(),
                }),
            endStatus: Yup.number(),
            validateFrom: Yup.date().typeError('This field is required').required('This field is required'),
            validateTo: Yup.date()
                .nullable()
                .when('endStatus', {
                    is: 0,
                    then: Yup.date().when('validateFrom', (validateFrom, schema) => {
                        return schema
                            .required('This field is required')
                            .min(
                                moment(validateFrom).add(-1, 'day').toDate(),
                                'End date must be greater than or equal to the start date'
                            )
                            .typeError('This field is required');
                    }),
                }),
        }),
    }),
    isAllowTenant: Yup.boolean()
});

export const SCOPE_TYPE_CUSTOM = 1;
export const SCOPE_TYPE_GLOBAL = 2;
export const scopeTypes: BaseSelect[] = [
    {
        name: 'Global',
        id: SCOPE_TYPE_GLOBAL,
    },
    {
        name: 'Custom',
        id: SCOPE_TYPE_CUSTOM,
    },
];
export const SERVICE_TYPE_IPARK = 1;
export const SERVICE_TYPE_INTERNAL_PERMIT = 3;

export const serviceTypes: BaseSelect[] = [
    // {
    //     name: 'iPark',
    //     id: SERVICE_TYPE_IPARK,
    // },
    {
        name: 'Internal permit',
        id: SERVICE_TYPE_INTERNAL_PERMIT,
    },
];

export const regisTypes: BaseSelect[] = [
    {
        name: 'Fixed time',
        id: REGIS_TYPE_FIXED_TIME,
    },
    {
        name: 'Specific duration',
        id: REGIS_TYPE_SPECIFIC_DURATION,
    },
];

export const LOCATION_SCOPE_ALL = 2;
export const LOCATION_SCOPE_CUSTOM = 1;
export const locationSRadio: BaseSelect[] = [
    {
        id: LOCATION_SCOPE_ALL,
        name: 'All zones',
    },
    {
        id: LOCATION_SCOPE_CUSTOM,
        name: 'Custom',
    },
];

export const TENANT_SCOPE_ALL = 2;
export const TENANT_SCOPE_CUSTOM = 1;
export const tenantSRadio: BaseSelect[] = [
    {
        id: TENANT_SCOPE_ALL,
        name: 'All tenants',
    },
    {
        id: TENANT_SCOPE_CUSTOM,
        name: 'Custom',
    },
];

export const defaultFormDataInternal = {
    permitType: 2,
    endStatus: 1,
    validateFrom: new Date(),
    validateTo: new Date(),
    fromHour: new Date(),
    toHour: new Date(),
    recurringType: 5,
    recurringEvery: 1,
    weekRecurringDays: [moment(new Date()).weekday()],
    checkedAllDay: false,
    validateToType: Number(ValidateToType.infinite),
    endAfterValue: 1,
    isCheckedAllDay: false,
};

export const defaultFormDataIPark = {
    regisType: 2,
    fixedTime: '',
    specificTime: 1,
    endStatus: 2,
    validateFrom: new Date(),
    validateTo: new Date(),
};

export const defaultFormData: FormData = {
    nameVehicleList: '',
    serviceType: SERVICE_TYPE_INTERNAL_PERMIT,
    scopeType: SCOPE_TYPE_GLOBAL,
    internalPermitData: defaultFormDataInternal,
    iParkData: defaultFormDataIPark,
    comment: '',
    isAllowTenant: false
};

export const DefaultPermit: Permit = {
    id: 0,
    permitType: PermitType.schedule,
    vehicleListId: 0,
    duration: 0,
    maxDuration: 0,
    permitRecurring: [],
    validateFrom: undefined,
    validateTo: undefined,
    validateToAfterRecurringTime: 1,
    validateToType: ValidateToType.infinite,
    scopes: [],
};

export const defaultRecur: PermitRecurring = {
    id: 0,
    fromHour: null,
    toHour: null,
    recurringEvery: 1,
    recurringType: 0,
    permitRecurringDays: [],
};
