import { BaseModel } from '../BaseModel';

export enum ServiceId {
    IPark = 1,
    IPermit = 2,
    InternalPermit = 3,
    RingGo = 4,
    ParkBee = 5,
    Metric = 6,
    PayByPhone = 7,
    NewPark = 8,
    IPS = 9,
    YPS = 10,
}
export interface Service extends BaseModel<ServiceId> {
    name: string;
    description?: string;
}

// export type ServicePermission = 'addPermit' | 'edit' | 'delete' | 'changeActive' | 'addVRN';

// export interface ServicePermit extends Service {
//     permission?: ServicePermission[];
// }

// export const services: ServicePermit[] = [
//     {
//         id: ServiceId.IPark,
//         name: 'iPark',
//         permission: ['edit', 'delete', 'changeActive'],
//     },
//     {
//         id: ServiceId.IPermit,
//         name: 'iPermit',
//     },
//     {
//         id: ServiceId.InternalPermit,
//         name: 'Internal permit',
//         permission: ['addPermit', 'edit', 'delete', 'changeActive', 'addVRN'],
//     },
//     {
//         id: ServiceId.RingGo,
//         name: 'RingGo',
//     },
//     {
//         id: ServiceId.ParkBee,
//         name: 'Park Bee',
//     },
//     {
//         id: ServiceId.Metric,
//         name: 'Metric',
//     },
//     {
//         id: ServiceId.PayByPhone,
//         name: 'Pay By Phone',
//     },
//     {
//         id: ServiceId.NewPark,
//         name: 'New Park',
//     },
//     {
//         id: ServiceId.IPS,
//         name: 'IPS',
//     },
//     {
//         id: ServiceId.YPS,
//         name: 'YPS',
//     },
// ];

// export const getService = (id: number) => {
//     return services.find((s) => s.id === id);
// };

// export const getPermission = (serviceId: number): Record<ServicePermission, boolean> => {
//     const _servicePermission = getService(serviceId)?.permission || [];

//     return {
//         addPermit: _servicePermission.includes('addPermit'),
//         addVRN: _servicePermission.includes('addVRN'),
//         changeActive: _servicePermission.includes('changeActive'),
//         delete: _servicePermission.includes('delete'),
//         edit: _servicePermission.includes('edit'),
//     };
// };
