/* eslint-disable array-callback-return */
/* eslint-disable react-hooks/exhaustive-deps */
import {
    Box,
    Divider,
    FormControl,
    FormControlLabel,
    Grid,
    IconButton,
    Menu,
    MenuItem,
    Radio,
    RadioGroup,
    SxProps,
    Theme,
    Typography,
} from '@mui/material';
import _ from 'lodash';
import React, { useContext, useEffect, useState } from 'react';
import MenuDeleteIcon from '../../../../components/svg/MenuDeleteIcon';
import WarningAddAtLeast from '../../../../components/WarningAddAtLeast';
import color from '../../../../config/Colors';
import useMenuELPopUp from '../../../../hooks/useMenuELPopUp';
import { Company } from '../../../../models/scope/Company';
import { LocationWithZones } from '../../../../models/scope/Location';
import { GFlexRow, GRadiusItem } from '../../../../styles/style';
import { BaseSelect } from '../../../vehicleList/hooks/useVehicleList';
import { LOCATION_SCOPE_ALL, TENANT_SCOPE_ALL } from '../../constant';
import { isCompany } from '../../helper';
import { VehicleListUpsertContext } from '../../hooks/useVehicleListUpsert';
import { UnionScope } from '../model/UpsertVehicleList';
import LocationAndZoneSelectItem from './LocationAndZoneSelectItem';
import LocationAndZoneSelectItemFake from './LocationAndZoneSelectItemFake';

type Props = {
    company: Company;
    companySelect: UnionScope;
    index: number;
    handleRemoveCompany: (company: Company) => void;
    isShowGlobalItem: boolean;
};

export default function PermitScopeItem(props: Props) {
    const { selectionState, setPartialSelectionState, setPartialDataState, vehicleListSelection, dataState } =
        useContext(VehicleListUpsertContext);
    const { companySelects } = selectionState;
    const { locations } = dataState;
    const { getCompanyScope, setCompanyScope } = vehicleListSelection;

    const getAllLocationSelect = () => {
        const allLocation = [...props.company.locations!];
        if (
            isCompany(props.companySelect) &&
            props.companySelect.locations &&
            props.companySelect.locations.length > 0
        ) {
            const locationIds = props.companySelect.locations.map((location) => location.id);
            return allLocation.filter((location) => !locationIds.includes(location.id));
        }
        return allLocation;
    };

    const getLocationsTemp = () => {
        if (isCompany(props.companySelect)) return props.companySelect.locations;
        else return [];
    };

    const [locationSelectState, setLocationSelectState] = useState<LocationWithZones[]>(getLocationsTemp() || []);
    const [allLocationSelect, setAllLocationSelect] = useState<LocationWithZones[]>(getAllLocationSelect());

    useEffect(() => {
        setAllLocationSelect(getAllLocationSelect());
        setLocationSelectState(getLocationsTemp() || []);
    }, [props.companySelect]);

    const handleAddLocation = (location: LocationWithZones) => {
        const cloneCompanySelect = _.cloneDeep(companySelects);
        const indexCompSelect = cloneCompanySelect.findIndex((item) => item.id === props.company.id);
        if (indexCompSelect >= 0) {
            const union = cloneCompanySelect[indexCompSelect];
            if (isCompany(union)) {
                const locationSelects = union.locations!;

                const index = locationSelects.findIndex((item) => item.id === location.id);
                if (index < 0) {
                    //add to
                    const arrTemp = _.cloneDeep(locationSelectState);
                    arrTemp.push(location);
                    setLocationSelectState(arrTemp);

                    //Add location with none zone to parent state
                    const arr = _.cloneDeep(locationSelects);
                    const locationClone = _.cloneDeep(location);
                    arr.push({
                        ...locationClone,
                        zones: [],
                        locationScope: LOCATION_SCOPE_ALL,
                        shops: [],
                        tenantScope: TENANT_SCOPE_ALL,
                    });
                    union.locations = arr;
                    setPartialSelectionState({ companySelects: cloneCompanySelect });

                    //remove from all locations
                    const allLocation = _.cloneDeep(locations).filter((l) => l.id !== location.id);
                    //set all Location again
                    setPartialDataState({ locations: allLocation });

                    //remove location from list
                    const locationsSelect = _.cloneDeep(allLocationSelect).filter((item) => item.id !== location.id);
                    setAllLocationSelect(locationsSelect);
                }
            }
        }
    };

    const handleRemoveLocation = (location: LocationWithZones) => {
        const cloneCompanySelect = _.cloneDeep(companySelects);
        const indexCompSelect = cloneCompanySelect.findIndex((item) => item.id === props.company.id);
        if (indexCompSelect >= 0) {
            const union = cloneCompanySelect[indexCompSelect];
            if (isCompany(union)) {
                //remove location from parent
                const locationSelects = union.locations?.filter((item) => item.id !== location.id);
                union.locations = locationSelects;
                setPartialSelectionState({ companySelects: cloneCompanySelect });

                //remove from current select
                const arr = _.cloneDeep(locationSelectState).filter((item) => item.id !== location.id);
                setLocationSelectState(arr);

                //add to location to all locations
                const allLocation = _.cloneDeep(locations);
                allLocation.push(location);
                //set all Location again
                setPartialDataState({ locations: allLocation });

                //add to all location select again
                const locationsSelect = _.cloneDeep(allLocationSelect);
                locationsSelect.push(location);
                locationsSelect.sort((x, y) => x.name.localeCompare(y.name));
                setAllLocationSelect(locationsSelect);
            }
        }
    };

    const handleRemoveAllLocation = () => {
        setLocationSelectState([]);
        setAllLocationSelect(props.company.locations!);

        const cloneCompanySelect = _.cloneDeep(companySelects);
        const indexCompSelect = cloneCompanySelect.findIndex((item) => item.id === props.company.id);
        if (indexCompSelect >= 0) {
            const union = cloneCompanySelect[indexCompSelect];
            if (isCompany(union)) {
                union.locations = [];
                setPartialSelectionState({ companySelects: cloneCompanySelect });

                //remove all location of this company
                const allLocation = _.cloneDeep(locations).filter((l) => l.companyId !== props.company.id);
                //add all location again
                props.company.locations!.map((l) => allLocation.push(l));
                //set all Location again
                setPartialDataState({ locations: allLocation });
            }
            //remove location from parent
        }
    };

    const getCurrentLocation = (location: LocationWithZones) => {
        const company = companySelects.find((c) => {
            if (isCompany(c)) return c.id === location.companyId;
        })!;
        if (isCompany(company)) {
            const locationFind = company.locations!.find((l) => l.id === location.id)!;
            return locationFind;
        } else {
            return {} as LocationWithZones;
        }
    };

    const divider = (isFirst: boolean, sx?: SxProps<Theme>) => {
        return (
            <Box width={'100%'} display={'flex'} justifyContent={'center'} sx={{ ...sx }}>
                <Divider sx={{ width: isFirst ? 'calc(100% + 30px)' : 'calc(100% - 5px)' }} />
            </Box>
        );
    };

    // console.log(getCompanyScope(props.companySelect));
    const getSelectLocation = () => {
        switch (getCompanyScope(props.companySelect)) {
            case COMPANY_SCOPE_CUSTOM:
                return (
                    <Grid item mt={2}>
                        <Grid container spacing={1.5}>
                            {locationSelectState.map((item, index) => {
                                const isLastItem = index === locationSelectState.length - 1;
                                return (
                                    <Box width={'100%'} key={item.id} pl={'10px'}>
                                        {index === 0 && divider(true, { mt: 1.5 })}
                                        <LocationAndZoneSelectItem
                                            allLocation={allLocationSelect}
                                            company={props.company}
                                            currentLocation={item}
                                            locationSelect={getCurrentLocation(item)}
                                            key={item.id}
                                            handleAddLocation={handleAddLocation}
                                            handleRemoveLocation={handleRemoveLocation}
                                            idParentCompany={props.company.id}
                                            index={index + 1}
                                            isShowGlobalItem={props.isShowGlobalItem}
                                        />
                                        {!isLastItem && divider(false)}
                                    </Box>
                                );
                            })}

                            {locationSelectState.length === 0 && (
                                <Grid item xs={12}>
                                    <WarningAddAtLeast text="Please add at least one location" />
                                </Grid>
                            )}
                        </Grid>
                    </Grid>
                );
            //COMPANY_SCOPE_ALL
            default:
                return <></>;
        }
    };

    const { openAnchorEl, handleClick, handleClose, anchorEl } = useMenuELPopUp();
    return (
        <React.Fragment>
            <GRadiusItem sx={{ padding: locationSelectState.length > 0 ? '16px 16px 0px 16px' : 2 }} gap={2}>
                <GFlexRow justifyContent="space-between" mb={locationSelectState.length > 0 ? 1 : 0}>
                    <GFlexRow item xs={6} justifyContent="space-between">
                        <GFlexRow item xs={7}>
                            <Typography
                                fontWeight={500}
                                sx={{ overflow: 'hidden', textOverflow: 'ellipsis', whiteSpace: 'nowrap' }}
                            >
                                {props.isShowGlobalItem && props.index + 1 + '. '}
                                {props.company.displayName}
                            </Typography>
                        </GFlexRow>
                        {props.isShowGlobalItem && (
                            <FormControl sx={{ width: '210px' }}>
                                <RadioGroup
                                    row
                                    aria-labelledby="demo-row-radio-buttons-group-label"
                                    name="row-radio-buttons-group"
                                    value={getCompanyScope(props.company)}
                                    sx={{
                                        '& input': {},
                                        justifyContent: 'space-between',
                                    }}
                                >
                                    {companyScopes.map((item, index) => {
                                        return (
                                            <FormControlLabel
                                                key={index}
                                                value={item.id}
                                                control={<Radio />}
                                                label={item.name}
                                                onClick={() => {
                                                    setLocationSelectState([]);
                                                    setAllLocationSelect(props.company.locations!);
                                                    setCompanyScope(props.company, item.id);
                                                }}
                                            />
                                        );
                                    })}
                                </RadioGroup>
                            </FormControl>
                        )}
                    </GFlexRow>
                    <GFlexRow item xs={6} gap={2} justifyContent={'end'}>
                        {getCompanyScope(props.company) === COMPANY_SCOPE_CUSTOM && props.isShowGlobalItem && (
                            <Grid item xs={9}>
                                {' '}
                                <LocationAndZoneSelectItemFake
                                    allLocation={allLocationSelect}
                                    handleAddLocation={handleAddLocation}
                                    disabled={allLocationSelect.length === 0}
                                />
                            </Grid>
                        )}

                        {props.isShowGlobalItem && (
                            <GFlexRow item width={'65px'} justifyContent="end">
                                <IconButton
                                    // onClick={() => props.handleRemove(props.company)}
                                    sx={{ padding: 0 }}
                                    id="basic-button"
                                    aria-controls={openAnchorEl ? 'basic-menu' : undefined}
                                    aria-haspopup="true"
                                    aria-expanded={openAnchorEl ? 'true' : undefined}
                                    onClick={handleClick}
                                >
                                    <MenuDeleteIcon />
                                </IconButton>
                            </GFlexRow>
                        )}
                    </GFlexRow>
                    <Menu
                        id="basic-menu"
                        anchorEl={anchorEl}
                        open={openAnchorEl}
                        onClose={handleClose}
                        MenuListProps={{
                            'aria-labelledby': 'basic-button',
                        }}
                        transformOrigin={{ horizontal: 'right', vertical: 'top' }}
                        anchorOrigin={{ horizontal: 'right', vertical: 'bottom' }}
                        PaperProps={{ sx: { padding: 1 } }}
                        sx={{
                            '& .MuiPaper-root': {
                                padding: '0 5px ',
                                boxShadow: 'none',
                                border: '1px solid #dddddd',
                                borderRadius: '2px',
                            },
                            '& .MuiList-root': {
                                py: '5px',
                            },
                        }}
                        transitionDuration={100}
                    >
                        <MenuItem
                            sx={{ color: color.danger, p: '6px 6px' }}
                            onClick={() => {
                                props.handleRemoveCompany(props.company);
                                handleClose();
                            }}
                            disableRipple
                        >
                            Delete company
                        </MenuItem>
                        {getCompanyScope(props.company) === COMPANY_SCOPE_CUSTOM && locationSelectState.length > 0 && (
                            <Divider sx={{ my: '2px !important' }} />
                        )}
                        {getCompanyScope(props.company) === COMPANY_SCOPE_CUSTOM && locationSelectState.length > 0 && (
                            <MenuItem
                                sx={{ color: color.danger, p: '6px 6px' }}
                                onClick={() => {
                                    handleRemoveAllLocation();
                                    handleClose();
                                }}
                                disableRipple
                            >
                                Delete all locations
                            </MenuItem>
                        )}
                    </Menu>
                </GFlexRow>
                {getSelectLocation()}
            </GRadiusItem>
        </React.Fragment>
    );
}

export const COMPANY_SCOPE_ALL = 1;
export const COMPANY_SCOPE_CUSTOM = 2;

const companyScopes: BaseSelect[] = [
    {
        id: COMPANY_SCOPE_ALL,
        name: 'All locations',
    },
    {
        id: COMPANY_SCOPE_CUSTOM,
        name: 'Custom',
    },
];
