import { Typography } from '@mui/material';
import React from 'react';
import color from '../../config/Colors';

export default function Required() {
    return (
        <Typography component={'span'} color={color.danger}>
            *
        </Typography>
    );
}
