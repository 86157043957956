/* eslint-disable react-hooks/exhaustive-deps */
import { Stack, Typography } from '@mui/material';
import { Box } from '@mui/system';
import { useEffect, useState } from 'react';
import { vehicleListController } from '../../controllers';
import { Vehicle } from '../../controllers/vehicle/VehicleController';
import useDbSearch from '../../hooks/useDbSearch';
import { Pagination } from '../../models/filter/BaseFilter';
import { VehicleListWithZone } from '../../models/vehicleList/VehicleList';
import BootstrapInput from './components/BootstrapInput';
import BPagination from './components/BPagination';
import PopUpViewVrn from './PopUpViewVrn';
import VItem from './VItem';
import { ServiceId } from '../../models/vehicleList/Service';

type VehicleListPCNProps = {
    locationId: number;
    vrn: Vehicle;
    zoneReference: string;
    isAddPermission: boolean;
};

type GetListProps = {
    page?: number;
    searchText?: string;
    vrnNumber?: string;
};

export default function VehicleListPCNPage(props: VehicleListPCNProps) {
    const [query, setQuery] = useState({ page: 1, search: '' });

    const [selectedVehicleList, setSelectedVehicleList] = useState<any>();

    const [paging, setPaging] = useState<Pagination<VehicleListWithZone>>({
        page: 1,
        pageSize: 10,
        data: [],
        total: 0,
        totalPage: 0,
    });

    const getList = ({ page, searchText, vrnNumber = props.vrn.vrnNumber }: GetListProps) => {
        vehicleListController
            .filter({
                page: page || 1,
                pageSize: 100,
                search: { value: searchText || '', fields: ['name'] },
                filter: {
                    companyId: 0,
                    locationId: props.locationId,
                    regionId: 0,
                    scopeType: -1,
                    serviceId: ServiceId.InternalPermit,
                    status: 1,
                    zoneIds: [],
                    vrn: vrnNumber,
                    zoneReference: props.zoneReference,
                    tenantIds: [],
                },
            })
            .then((res) => {
                setPaging(res);
            });
    };

    const _useDbSearch = useDbSearch({ fn: (text: string) => getList({ page: 1, searchText: text }) });

    useEffect(() => {
        getList({ page: query.page });
    }, [query.page]);

    const handleAddSuccess = () => {
        getList({ page: paging.page, searchText: query.search });
    };

    return (
        <Box className="card">
            <Box className="card-header">Vehicle List within this location</Box>

            <Box className="card-body p-1">
                <Stack direction={'row'} justifyContent="space-between">
                    <Typography></Typography>

                    <BootstrapInput
                        value={query.search}
                        onChange={(e) => {
                            const _value = e.target.value;
                            setQuery((prev) => ({ ...prev, search: _value }));
                            _useDbSearch(_value);
                        }}
                        id="bootstrap-input"
                        placeholder="Search Vehicle list"
                    />
                </Stack>

                <Box mt={1}>
                    <table className="table table-sm table-bordered m-0" style={{ tableLayout: 'fixed' }}>
                        <thead>
                            <tr>
                                <th scope="col">Vehicle list name (UKPC internal only)</th>
                                <th scope="col">Date create</th>
                                <th scope="col">Status</th>
                                <th scope="col">Action</th>
                            </tr>
                        </thead>
                        <tbody>
                            {paging.data.map((row) => (
                                <VItem
                                    key={row.id}
                                    item={row}
                                    onView={setSelectedVehicleList}
                                    onAddSuccess={handleAddSuccess}
                                    vrn={props.vrn}
                                    isAdd={props.isAddPermission}
                                />
                            ))}
                        </tbody>
                    </table>

                    {paging.totalPage > 1 && (
                        <Box className="mt-2">
                            <BPagination
                                page={paging.page}
                                totalPage={paging.totalPage}
                                onChangePage={(page) => setQuery((prev) => ({ ...prev, page: page }))}
                            />
                        </Box>
                    )}

                    {selectedVehicleList && (
                        <PopUpViewVrn
                            open={!!selectedVehicleList}
                            onClose={() => setSelectedVehicleList(undefined)}
                            vehicleList={selectedVehicleList}
                        />
                    )}
                </Box>
            </Box>
        </Box>
    );
}
