import { Divider, FormControl, MenuItem, Select, SelectChangeEvent } from '@mui/material';
import React from 'react';
import color from '../../../config/Colors';

type Props<T> = {
    listData: T[];
    value: string;
    keyEqual: keyof T;
    keyLabel: keyof T;
    handleChange: (val: string) => void;
    label: string;
};

export default function VLSelectCompanyScope<T>(props: Props<T>) {
    const handleChange = (event: SelectChangeEvent<any>, child: React.ReactNode) => {
        props.handleChange(event.target.value);
    };

    return (
        <FormControl
            fullWidth
            size="small"
            sx={{
                '& svg': {
                    width: '20px',
                    height: '20px',
                },
                '& .MuiSvgIcon-root': {
                    top: 'calc(50% - 0.4em)',
                },

                '& .MuiInputBase-root': {
                    border: '',
                },
                width: '150px',
            }}
            variant="standard"
        >
            <Select
                labelId="demo-simple-select-standard-label"
                id="demo-simple-select-standard"
                onChange={handleChange}
                value={props.value}
                disableUnderline
                autoFocus={false}
            >
                {props.listData.map((item, index) => {
                    return [
                        <MenuItem value={item[props.keyEqual] as any} key={index}>
                            {item[props.keyLabel] as string}
                        </MenuItem>,
                        index === props.listData.length - 1 ? (
                            ''
                        ) : (
                            <Divider
                                sx={{
                                    my: '2px !important',
                                    borderColor: color.grey200,
                                    mx: '3px',
                                }}
                            />
                        ),
                    ];
                })}
            </Select>
        </FormControl>
    );
}
