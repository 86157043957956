/* eslint-disable react-hooks/exhaustive-deps */
import { Box, Button, Chip, IconButton, Pagination as MuiPagination, Stack, Tooltip, Typography } from '@mui/material';
import { useContext, useEffect, useState } from 'react';
import { useNavigate } from 'react-router-dom';
import FilterRoot from '../../components/filter/FilterRoot';
import LinkTo from '../../components/LinkTo';
import LoadingWrap from '../../components/LoadingWrap';
import PopUpWarning from '../../components/PopUpWarning';
import EditIcon3 from '../../components/svg/EditIcon3';
import { pushError, pushSuccess } from '../../components/toast';
import color from '../../config/Colors';
import { vehicleController, vehicleListController } from '../../controllers';
import { Vehicle, VehicleFilter } from '../../controllers/vehicle/VehicleController';
import { FilterDetail } from '../../controllers/vehicleList/WhiteListController';
import { AuthContext, AuthPermission } from '../../layout/AuthLayout';
import { FilterRequest, Pagination } from '../../models/filter/BaseFilter';
import { VehicleList, VehicleListWithZone } from '../../models/vehicleList/VehicleList';
import { VLLogAction } from '../../models/vehicleListAction/VLLogAction';
import { TitleWithBackButton } from '../../styles/style';
import { VehicleListPageProps } from '../vehicleList';
import { ZoneList } from '../vehicleList/components/VehicleListItem';
import { GetVehicleListDescription } from '../vehicleList/helpers';
import { Search, searchTypes } from '../vehicleList/model/SearchType';
import { useSearch, useVehicleListParams, WrapVehicleList } from '../vehicleListDetails';
import { SearchField } from '../vehicleListDetails/StartAdornment';
import VRNItem from './VRNItem';
import { ServiceContext } from '../../providers/ServiceProvider';
import { ServiceId } from '../../models/vehicleList/Service';

const initState: State = {
    page: 1,
    pageSize: 10,
    filter: {},
    search: {
        type: searchTypes[0],
        value: '',
    },
};

type State = Omit<FilterRequest<Pick<FilterDetail, 'vehicleListId'>>, 'search'> & {
    search: Search;
};

export type VRNPageAction = {
    onAddVRN(vehicleList: VehicleList): void;
    onEdit(vehicleList: VehicleList, vrnId: number): void;
    onBack(vehicleList: VehicleList): void;
    onEditVL(vehicleList: VehicleList): string;
};

type Props = VehicleListPageProps & {
    vehicleList: VehicleListWithZone;
    action?: VRNPageAction;
};

export function VRNPage(props: Props) {
    const { vehicleList, locationId, zoneId } = props;
    const { permission: p } = useContext(AuthContext);
    const { services, getPermission, getService } = useContext(ServiceContext);

    const readOnly = p === AuthPermission.READ_ONLY;
    const isGlobal = !locationId && !zoneId;

    const [state, setState] = useState(initState);

    const [pagingVRN, setPagingVRN] = useState<Pagination<Vehicle>>({
        page: 1,
        data: [],
        pageSize: 10,
        total: 0,
        totalPage: 0,
    });

    const [deletingItem, setDeletingItem] = useState<Vehicle>();
    const permission = getPermission?.(vehicleList.serviceId);
    const [loadingList, setLoadingList] = useState(true);

    const _useSearch = useSearch({
        searchFilter: state.search.value,
        onChangeText: (text) => setState((prev) => ({ ...prev, page: 1, search: { ...prev.search, value: text } })),
    });

    const getList = (props: Partial<VehicleFilter>) => {
        setLoadingList(true);
        vehicleController
            .filter({
                page: 1,
                filter: {
                    vehicleListId: vehicleList.id,
                },
                pageSize: 10,
                search: {
                    fields: [state.search.type.id],
                    value: state.search.value,
                },
                ...props,
            })
            .then((res) => {
                setPagingVRN(res);
                window.scrollTo({ top: 0, behavior: 'smooth' });
            })
            .finally(() => {
                setLoadingList(false);
            });
    };

    useEffect(() => {
        getList({ page: state.page });
    }, [state.page, state.search.type.id, state.search.value, vehicleList.id]);

    //set action view first time
    useEffect(() => {
        vehicleListController.viewAction({
            vehicleListId: props.vehicleList.id,
            action: VLLogAction['View permit list'],
        });
    }, []);

    return (
        <Box sx={{ px: '12px', pt: isGlobal ? 0 : 3 }}>
            <Stack direction={'row'} alignItems="center" justifyContent={'space-between'}>
                <Stack spacing={1}>
                    <Stack direction={'row'} alignItems="center">
                        <TitleWithBackButton variant="h4" sx={{ maxWidth: '650px' }}>
                            {`View VRNs of ${props.vehicleList?.name}`}
                        </TitleWithBackButton>
                        <Chip
                            size="small"
                            label={getService?.(vehicleList.serviceId)?.name}
                            sx={{ borderRadius: '16px !important', mx: 1 }}
                        />
                        <Tooltip
                            title={vehicleList.isDefault ? 'Unable to edit default vehicle list' : 'Edit vehicle list'}
                            placement="top"
                            arrow
                        >
                            <Box>
                                {[ServiceId.IPark, ServiceId.InternalPermit].includes(vehicleList.serviceId) && (
                                    <LinkTo
                                        isDisable={vehicleList.isDefault}
                                        to={`${props.action?.onEditVL(props.vehicleList)}`}
                                    >
                                        <IconButton color="primary" disabled={readOnly || vehicleList.isDefault}>
                                            <EditIcon3 />
                                        </IconButton>
                                    </LinkTo>
                                )}
                            </Box>
                        </Tooltip>
                    </Stack>
                    <ZoneList zoneName={vehicleList.zoneName} scopeType={vehicleList.permits?.[0].scopes[0].scopeType} totalZone={vehicleList.totalZone}/>
                </Stack>

                {permission?.addVRN && (
                    <Button
                        variant="contained"
                        color="primary"
                        sx={{ minWidth: 200 }}
                        onClick={() => props.action?.onAddVRN(props.vehicleList)}
                        disabled={readOnly}
                    >
                        Add Permit
                    </Button>
                )}
            </Stack>

            <Stack mt={3}>
                <FilterRoot
                    baseStyle={{ mt: 2 }}
                    rightTitleContent={
                        <Stack direction={'row'} spacing={2}>
                            <SearchField
                                searchTypes={[searchTypes[0]]}
                                onFocus={() => _useSearch.setSearchFocused(true)}
                                onBlur={() => _useSearch.setSearchFocused(false)}
                                value={_useSearch.searchValue || ''}
                                selectType={state.search.type}
                                onChangeType={(type) => {}}
                                onChange={(e) => {
                                    const _value = e.target.value;
                                    _useSearch.setSearchValue(_value);
                                    _useSearch.searchFocused && _useSearch.handleChangeSearch(_value);
                                }}
                            />
                        </Stack>
                    }
                    handleResetFilter={() => {
                        setState(initState);
                    }}
                />
            </Stack>

            <LoadingWrap loading={loadingList} rows={pagingVRN.data}>
                <Box mt={3} mb={10}>
                    <Stack direction={'row'} justifyContent="space-between">
                        <Stack direction={'row'} alignItems="center" spacing={1}>
                            <Typography>
                                Description:{' '}
                                <Typography component={'i'}>
                                    {GetVehicleListDescription(
                                        vehicleList.permits?.[0],
                                        vehicleList.serviceId,
                                        isGlobal
                                    )}
                                </Typography>
                            </Typography>
                            {vehicleList.isExpired && (
                                <Chip
                                    size="small"
                                    sx={{
                                        borderRadius: '16px',
                                        height: 22,
                                        fontSize: 12,
                                        '&.MuiChip-root': {
                                            background: 'rgba(224, 27, 0, 0.1)',
                                            color: color.danger,
                                        },
                                        ml: 1,
                                    }}
                                    label={'Expired'}
                                />
                            )}
                        </Stack>
                        <Stack direction={'row'} alignItems="center" spacing={2}>
                            <Typography>Total VRNs: {pagingVRN.total}</Typography>
                        </Stack>
                    </Stack>

                    <Stack mt={2} spacing={2}>
                        {pagingVRN.data.map((vrn, index) => {
                            return (
                                <VRNItem
                                    key={vrn.id}
                                    vehicleListId={vehicleList.id}
                                    item={vrn}
                                    onDelete={(item) => setDeletingItem(item)}
                                    onEdit={(vrnId) => {
                                        return props.action?.onEdit(props.vehicleList, vrnId);
                                    }}
                                />
                            );
                        })}
                    </Stack>

                    <PopUpWarning
                        open={!!deletingItem}
                        title="Confirm"
                        message="Are you sure to remove vehicle? The whitelist will stop generating tomorrow."
                        onClose={() => setDeletingItem(undefined)}
                        onConfirm={() => {
                            vehicleController
                                .Delete({ vrnNumber: deletingItem!.vrnNumber!, vehicleListId: vehicleList.id })
                                .then((res) => {
                                    getList({});
                                    pushSuccess('Delete successfully');
                                })
                                .catch((err) => {
                                    pushError(err?.response?.data?.message || 'Delete fail');
                                })
                                .finally(() => {
                                    setDeletingItem(undefined);
                                });
                        }}
                    />

                    {pagingVRN.totalPage > 1 && (
                        <Stack mt={3} direction={'row'} justifyContent="center">
                            <MuiPagination
                                count={pagingVRN.totalPage || 1}
                                page={pagingVRN.page}
                                onChange={(_, page) => setState((prev) => ({ ...prev, page: page }))}
                                variant="outlined"
                                shape="rounded"
                            />
                        </Stack>
                    )}
                </Box>
            </LoadingWrap>
        </Box>
    );
}

export function WrapVRNPage() {
    const { path } = useVehicleListParams();
    const navigate = useNavigate();

    return (
        <WrapVehicleList>
            {(vehicleList) => {
                return (
                    <VRNPage
                        vehicleList={vehicleList}
                        action={{
                            onAddVRN: () => {
                                navigate('add');
                            },
                            onEdit: (vehicleListA, vrnId) => {
                                return `/${path}/vehicle-list/${vehicleListA.id}/vrn/${vrnId}`;
                            },
                            onBack: () => navigate(-1),
                            onEditVL: (vehicleListA) => {
                                return `/${path}/vehicle-list/edit/${vehicleListA.id}`;
                            },
                        }}
                    />
                );
            }}
        </WrapVehicleList>
    );
}

export default function WrapVRN(props: VehicleListPageProps & { action: VRNPageAction }) {
    return <WrapVehicleList>{(vehicleList) => <VRNPage vehicleList={vehicleList} {...props} />}</WrapVehicleList>;
}
