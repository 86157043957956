import humanizeDuration from 'humanize-duration';
import moment from 'moment';
import { convertHour } from '../../../helpers/ConvertHour';
import { Permit } from '../../../models/permit/Permit';
import { RecurringType } from '../../../models/permit/PermitRecurring';
import { VehicleListWithZone } from '../../../models/vehicleList/VehicleList';
import { ALL_DAY_SECONDS } from '../../vehicleListUpsert/constant';
import { ServiceId } from '../../../models/vehicleList/Service';

export const CheckVehicleListExpired = (vehicle: VehicleListWithZone) => {
    if (vehicle.permits!.length <= 0) return false;
    if (vehicle.permits?.[0].validateTo === null) return false;
    if (moment(vehicle.permits?.[0].validateTo).isAfter(new Date())) return false;
    return true;
};

const show = (text: any, condition: boolean) => {
    return condition ? text : '';
};

export const GetVehicleListDescription = (permit: Permit = {} as Permit, serviceId: number, global?: boolean) => {
    if ([ServiceId.RingGo, ServiceId.ParkBee].includes(serviceId)) {
        return global ? `Flexible permit durations` : 'Various permit durations';
    }

    if (permit.duration) {
        return `Permit is valid within ${humanizeDuration(permit.duration * 1000, { conjunction: ' ' })}`;
    }

    if (permit.maxDuration) {
        const days = permit.maxDuration / 60 / 60 / 24;
        const s = days > 1 ? 's' : '';
        return `Able to register at most ${days} permit day${s}`;
    }

    const { validateTo } = permit;

    const permitRecurring = permit.permitRecurring?.[0];
    if (!permitRecurring) return '';

    const { recurringEvery, permitRecurringDays, fromHour, toHour } = permitRecurring;
    let text = `Permit recurs every `;
    const qtt = show(recurringEvery, recurringEvery > 1);
    const s = show('s', recurringEvery > 1);
    const isAllDay = fromHour === 0 && toHour === ALL_DAY_SECONDS;
    const timeRange = `${convertHour.SecondsToHoursMinStringFormat(
        fromHour
    )} to ${convertHour.SecondsToHoursMinStringFormat(toHour)}`;

    const until = !!validateTo ? `until ${moment(validateTo).format('DD/MM/YYYY')}` : 'with no end date';

    switch (permitRecurring.recurringType) {
        case RecurringType.once:
            if (isAllDay) text = `Permit on ${moment(validateTo).format('DD/MM/YYYY')}`;
            else text = `Permit from ${timeRange} on ${moment(validateTo).format('DD/MM/YYYY')}`;
            break;
        case RecurringType.day:
            if (isAllDay) text += `${qtt} day${s} ${until}`;
            else text = `Permit from ${timeRange} recurs every ${qtt} day${s} ${until}`;
            break;
        case RecurringType.week:
            const days = permitRecurringDays
                .map((p) => moment(p.firstExecuteAt).isoWeekday())
                .sort()
                .map((p) => moment().isoWeekday(p).format('ddd'))
                .join(', ');
            const every = recurringEvery > 1 ? recurringEvery : '';
            if (isAllDay) text += `${every} week${s} on ${days} ${until}`;
            else text = `Permit from ${timeRange} recurs every ${qtt} week${s} on ${days} ${until}`;
            break;
        case RecurringType.month:
            const date = permitRecurring.recurringEvery;
            if (isAllDay) text += `month on day ${date} ${until}`;
            else text = `Permit from ${timeRange} recurs every month on day ${date} ${until}`;
            break;
        default:
            text = 'Permit recurs every day';
            break;
    }

    return text;
};
