import * as React from 'react';

function EditIcon2(props: React.SVGProps<SVGSVGElement>) {
    return (
        <svg width={14} height={14} viewBox="0 0 14 14" fill="none" xmlns="http://www.w3.org/2000/svg" {...props}>
            <path d="M8.262 13.098H13.5" stroke="currentColor" strokeLinecap="round" strokeLinejoin="round" />
            <path
                clipRule="evenodd"
                d="M7.563 1.074a1.621 1.621 0 012.25-.219l1.253.973c.75.454.984 1.419.52 2.155-.025.04-6.888 8.625-6.888 8.625a1.227 1.227 0 01-.946.457l-2.628.033L.53 10.59c-.083-.352 0-.723.229-1.007l6.803-8.51z"
                stroke="currentColor"
                strokeLinecap="round"
                strokeLinejoin="round"
            />
            <path d="M6.293 2.667l3.937 3.024" stroke="currentColor" strokeLinecap="round" strokeLinejoin="round" />
        </svg>
    );
}

export default EditIcon2;
