import React, { useEffect, useRef, useState } from 'react';
import { DateRangePicker } from 'react-bootstrap-daterangepicker';
// you will need the css that comes with bootstrap@3. if you are using
// a tool like webpack, you can do the following:
// you will also need the css that comes with bootstrap-daterangepicker
import { TextField } from '@mui/material';
import { IoCalendarOutline } from 'react-icons/io5';
import momentZ from 'moment';
import '../styles/daterangepicker.css';
var momentTimeZone = require('moment-timezone');
momentTimeZone.tz.setDefault('UTC');

interface DateRangeValue {
    startDate?: Date;
    endDate?: Date;
}

type Props = {
    value?: DateRangeValue;
    onChange?(value: Required<DateRangeValue>): void;
    initialSettings?: any;
    label?: string;
};

const moment = (inp?: momentZ.MomentInput) => {
    return momentZ(inp).utcOffset(0);
};

export default function StyledDateRangePicker(props: Props) {
    const [dateRange, setDateRange] = useState(props.value);
    const value =
        !dateRange?.startDate || !dateRange.endDate
            ? ''
            : moment(dateRange.startDate).format('DD/MM/YYYY') + ' - ' + moment(dateRange.endDate).format('DD/MM/YYYY');

    const keyRef = useRef(Date.now());
    const handleChangeRanges = () => {
        keyRef.current = Date.now();
    };

    useEffect(() => {
        setDateRange(props.value);
        if (!(props.value?.endDate && props.value.startDate)) {
            handleChangeRanges();
        }
    }, [props.value]);

    return (
        <DateRangePicker
            key={keyRef.current}
            initialSettings={{
                autoApply: true,
                ranges: {
                    Today: [moment(), moment()],
                    Yesterday: [moment().subtract(1, 'days'), moment().subtract(1, 'days')],
                    'Last 7 Days': [moment().subtract(6, 'days'), moment()],
                    'Last 30 Days': [moment().subtract(29, 'days'), moment()],
                    'This Month': [moment().startOf('month'), moment().endOf('month')],
                    'Last Month': [
                        moment().subtract(1, 'month').startOf('month'),
                        moment().subtract(1, 'month').endOf('month'),
                    ],
                },
                alwaysShowCalendars: true,
                startDate: dateRange?.startDate ?? undefined,
                endDate: dateRange?.endDate ?? undefined,
                ...props.initialSettings,
            }}
            onApply={(e, date) => {
                const { startDate, endDate } = date;
                const start = startDate.toDate();
                const end = endDate.toDate();

                setDateRange({ startDate: start, endDate: end });
                props.onChange?.({ startDate: start, endDate: end });
            }}
        >
            <TextField
                label={props.label ?? 'Permit date'}
                placeholder="Select date"
                value={value}
                InputProps={{
                    readOnly: true,
                    endAdornment: <IoCalendarOutline size={24} color="gray" />,
                }}
                sx={{
                    '& *': {
                        cursor: 'pointer',
                    },
                }}
            />
        </DateRangePicker>
    );
}
