import React from 'react';
import LinearProgress from '@mui/material/LinearProgress';
import Typography from '@mui/material/Typography';
import Box from '@mui/material/Box';
import { Grid, Stack } from '@mui/material';
import color from '../../../config/Colors';
import GeneralUploadPermitIcon from './GeneralUploadPermitIcon';

type Props = {
    progressPercent: number;
    fileName: string;
    handleCancelUpload: () => void;
};

export default function LinearWithValueLabel({ progressPercent, fileName, handleCancelUpload }: Props) {
    return (
        <Grid
            sx={{
                width: '100%',
                padding: '8px 16px',
                background: '#FFFFFF',
                boxShadow: '0px 2px 15px rgba(0, 0, 0, 0.1)',
                borderRadius: '5px',
                display: 'flex',
                flexDirection: 'row',
                alignItems: 'center',
                gap: '24px',
            }}
        >
            <Box>
                <GeneralUploadPermitIcon />
            </Box>
            <Box sx={{ display: 'flex', alignItems: 'center', width: '100%', flexDirection: 'column' }}>
                <Stack width={'100%'} flexDirection={'row'} justifyContent={'space-between'}>
                    <Typography
                        variant="body1"
                        noWrap
                        maxWidth={{
                            xs: '130px',
                            sm: '300px',
                            md: '500px',
                            overflow: 'hidden',
                            textOverflow: 'ellipsis',
                        }}
                    >
                        {fileName}
                    </Typography>
                    <Typography
                        variant="body1"
                        fontWeight={500}
                        color={color.danger}
                        sx={{ '&:hover': { cursor: 'pointer' } }}
                        component="span"
                        onClick={handleCancelUpload}
                    >
                        Cancel
                    </Typography>
                </Stack>
                <Box sx={{ width: '100%' }} my={0.5}>
                    <LinearProgress variant="determinate" value={progressPercent} sx={{ borderRadius: '3px' }} />
                </Box>

                {progressPercent === 100 ? (
                    <Typography variant="body2" color={color.success} width={'100%'}>
                        Uploaded successfully!
                    </Typography>
                ) : (
                    <Typography variant="body2" color={color.primary} width={'100%'}>
                        {`${Math.round(progressPercent)}% uploaded`}
                    </Typography>
                )}
            </Box>
        </Grid>
    );
}
