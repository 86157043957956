import { BaseModel } from '../BaseModel';
import { PermitRecurring } from './PermitRecurring';
import { Scope } from './Scope';

export interface Permit extends BaseModel {
    vehicleListId: number;
    tenantId?: number;
    validateFrom?: Date | undefined;
    validateTo?: Date | undefined;
    validateToAfterRecurringTime?: number;
    validateToType?: ValidateToType;
    permitType: PermitType;
    duration?: number;
    maxDuration?: number;
    permitRecurring?: PermitRecurring[];
    bayNumber?: number;
    scopes: Scope[];
}

export enum PermitType {
    duration = 0,
    schedule = 1,
    specific = 2,
}

export enum ValidateToType {
    absoluteAt = 0,
    recurringTime = 1,
    infinite = 2,
}

export enum PermitTypeVl {
    Once,
    Indefinite,
    Temporary,
}
