/* eslint-disable react-hooks/exhaustive-deps */
import _ from 'lodash';
import { useCallback } from 'react';

type Props = {
    timeout?: number;
    fn?: Function;
};

export default function useDbSearch(props: Props) {
    const handleChangeSearch = useCallback(
        _.debounce((text: string) => {
            props.fn?.(text);
        }, props.timeout ?? 350),
        [props.timeout]
    );
    return handleChangeSearch;
}
