import { ReactNode, createContext, useEffect, useState } from 'react';
import { serviceController } from '../controllers';
import { Service, ServiceId } from '../models/vehicleList/Service';

export type ServicePermission = 'addPermit' | 'edit' | 'delete' | 'changeActive' | 'addVRN';

export interface ServicePermit extends Service {
    permission?: ServicePermission[];
}

const _initServices: ServicePermit[] = [
    // {
    //     id: ServiceId.IPark,
    //     name: 'iPark',
    //     permission: ['edit', 'delete', 'changeActive'],
    // },
    {
        id: ServiceId.IPermit,
        name: 'iPermit',
    },
    {
        id: ServiceId.InternalPermit,
        name: 'Internal permit',
        permission: ['addPermit', 'edit', 'delete', 'changeActive', 'addVRN'],
    },
    // {
    //     id: ServiceId.RingGo,
    //     name: 'RingGo',
    // },
    // {
    //     id: ServiceId.ParkBee,
    //     name: 'Park Bee',
    // },
    // {
    //     id: ServiceId.Metric,
    //     name: 'Metric',
    // },
    // {
    //     id: ServiceId.PayByPhone,
    //     name: 'Pay By Phone',
    // },
    // {
    //     id: ServiceId.NewPark,
    //     name: 'New Park',
    // },
    // {
    //     id: ServiceId.IPS,
    //     name: 'IPS',
    // },
];

const useService = () => {
    const [services, setServices] = useState(_initServices);

    const getService = (id: number) => {
        return services.find((s) => s.id === id);
    };

    useEffect(() => {
        serviceController.getAll().then((res) => {
            const _newServices = [..._initServices];

            res.forEach((element) => {
                if (!_newServices.find((s) => s.id === element.id)) {
                    _newServices.push(element);
                }
            });

            setServices(_newServices);
        });
    }, []);

    const getPermission = (serviceId: number): Record<ServicePermission, boolean> => {
        const _servicePermission = getService(serviceId)?.permission || [];

        return {
            addPermit: _servicePermission.includes('addPermit'),
            addVRN: _servicePermission.includes('addVRN'),
            changeActive: _servicePermission.includes('changeActive'),
            delete: _servicePermission.includes('delete'),
            edit: _servicePermission.includes('edit'),
        };
    };

    return {
        services,
        getService,
        getPermission,
    };
};

export const ServiceContext = createContext<Partial<ReturnType<typeof useService>>>({});

export const ServiceProvider = (props: { children: ReactNode }) => {
    const context = useService();

    return <ServiceContext.Provider value={context}>{props.children}</ServiceContext.Provider>;
};
