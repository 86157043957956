import { ArrowDropDown } from '@material-ui/icons';
import { Stack, Typography, Fade, Paper, ClickAwayListener, TextField, TextFieldProps } from '@mui/material';
import { omit } from 'lodash';
import React, { useState } from 'react';
import { FiSearch } from 'react-icons/fi';
import color from '../../config/Colors';
import { activeStyles } from '../../helpers';
import { SearchType, searchTypes as _types } from '../vehicleList/model/SearchType';

type Props = {
    selectType: SearchType;
    onChangeType: (type: SearchType) => void;
    searchTypes?: SearchType[];
};

export default function StartAdornment({ selectType: select, onChangeType: onChange, searchTypes = _types }: Props) {
    const [open, setOpen] = useState(false);

    const isSingleSearch = searchTypes.length === 1;

    return (
        <ClickAwayListener onClickAway={() => setOpen(false)}>
            <Stack
                sx={{
                    width: 215 - (isSingleSearch ? 40 : 0),
                    cursor: isSingleSearch ? 'initial' : 'pointer',
                    borderRight: `1px solid ${color.grey600}`,
                    position: 'relative',
                }}
            >
                <Stack
                    direction={'row'}
                    alignItems="center"
                    onClick={(e) => {
                        e.preventDefault();
                        e.stopPropagation();
                        setOpen(!open);
                    }}
                >
                    <Typography mt="-1px" sx={{ minWidth: '104px', userSelect: 'none' }}>
                        {select.name}
                    </Typography>
                    {!isSingleSearch && <ArrowDropDown style={{ color: color.grey600 }} />}
                </Stack>

                <Fade in={!isSingleSearch && open}>
                    <Paper
                        sx={{
                            position: 'absolute',
                            top: '32px',
                            left: '-10px',
                            zIndex: 2,
                            p: '5px',
                            // width: 150,
                        }}
                    >
                        {searchTypes.map((type, index) => {
                            const selected = type.id === select.id;
                            return (
                                <Stack
                                    direction={'row'}
                                    alignItems={'center'}
                                    key={type.id}
                                    onClick={(e) => {
                                        e.stopPropagation();
                                        setOpen(false);
                                        onChange(type);
                                    }}
                                    sx={{
                                        borderRadius: '4px',
                                        px: 1,
                                        py: 1,
                                        ':hover': {
                                            ...(activeStyles({ backgroundColor: '#FAFAFA' }, !selected) as any),
                                        },
                                        backgroundColor: selected ? color.lightPrimary : undefined,
                                    }}
                                >
                                    <Typography sx={{ color: selected ? '#1A428A' : 'initial' }}>
                                        {type.name}
                                    </Typography>
                                </Stack>
                            );
                        })}
                    </Paper>
                </Fade>
            </Stack>
        </ClickAwayListener>
    );
}

export const SearchField = (props: TextFieldProps & Props) => {
    return (
        <TextField
            {...omit(props, ['onChangeType', 'selectType', 'searchTypes'])}
            sx={{
                width: {
                    sm: 325,
                    md: 425,
                    lg: 525,
                },
                '& svg': {
                    '&:hover': {
                        cursor: 'pointer',
                    },
                },
            }}
            autoComplete="off"
            placeholder="Search..."
            InputProps={{
                startAdornment: (
                    <StartAdornment
                        selectType={props.selectType}
                        searchTypes={props.searchTypes}
                        onChangeType={props.onChangeType}
                    />
                ),
                endAdornment: <FiSearch color="disabled" size={32} style={{ color: 'rgba(0, 0, 0, 0.38)' }} />,
            }}
        />
    );
};
