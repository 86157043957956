/* eslint-disable eqeqeq */
import _ from 'lodash';
import React, { useState } from 'react';
import useModal from '../../../hooks/useModal';
import { FVehicleListFilterData, FVehicleListFilterRaw } from '../../../models/filter/FVehicleList';

interface State {
    deleteId: number | null;
    deleteIds: number[];
}

const defaultFVehicleList: FVehicleListFilterRaw = {
    page: 1,
    pageSize: 6,
    search: { fields: ['name'], value: '' },
    filter: {},
};

const defaultFVehicleListData: FVehicleListFilterData = {
    listCompany: [],
    listLocation: [],
    listRegion: [],
    listZone: [],
};

export default function useVehicleList() {
    const [state, setState] = useState<State>({ deleteId: null, deleteIds: [] });
    const [filter, setFilter] = useState<FVehicleListFilterRaw>(defaultFVehicleList);
    const [filterData, setFilterData] = useState<FVehicleListFilterData>(defaultFVehicleListData);
    const stateDialogDelete = useModal();
    const stateDialogBulksDelete = useModal();

    const setPartialState = (partial: Partial<State>) => setState((prev) => ({ ...prev, ...partial }));
    const setPartialFilter = (partial: Partial<FVehicleListFilterRaw>) =>
        setFilter((prev) => ({ ...prev, ...partial }));
    const setPartialFilterData = (partial: Partial<FVehicleListFilterData>) =>
        setFilterData((prev) => ({ ...prev, ...partial }));

    const onCheckedAll = (e: any) => {
        const ids: number[] = [...state.deleteIds];
        //checked
        if (e.target.checked) {
            if (ids.length === 0) {
                // permitPagination.data.map((item) => ids.push(item.id));
            } else {
                //check id is exists in ids
                // permitPagination.data.map((item) => {
                //     const index = ids.findIndex((id) => id == item.id);
                //     if (index < 0) {
                //         ids.push(item.id);
                //     }
                // });
            }
        } else {
            //unChecked delete all id in page from ids
            // permitPagination.data.map((item) => {
            //     const index = ids.findIndex((id) => id == item.id);
            //     if (index >= 0) {
            //         ids.splice(index, 1);
            //     }
            // });
        }
        setPartialState({ deleteIds: ids });
    };

    const handleChangeChecked = (e: any) => {
        let arr = _.cloneDeep(state.deleteIds);
        if (e.target.checked) {
            arr.push(Number(e.target.value));
        } else {
            arr = arr.filter((f) => f != Number(e.target.value));
        }
        setPartialState({ deleteIds: arr });
    };

    const checkIdExists = (id: number) => {
        if (state.deleteIds.find((item) => item == id)) {
            return true;
        } else {
            return false;
        }
    };

    const isCheckedAll = () => {
        let isExistsAll = true;
        // if (permitPagination.data && permitPagination.data.length <= 0) {
        //     return false;
        // }
        // permitPagination.data &&
        //     permitPagination.data.length > 0 &&
        //     permitPagination.data.map((item) => {
        //         const index = deleteIds.findIndex((idDelete) => idDelete == item.id);
        //         if (index < 0) {
        //             isExistsAll = false;
        //         }
        //     });
        return isExistsAll;
    };

    const handleOpenConfirmDelete = (id: number) => {
        setPartialState({ deleteId: id });
        stateDialogDelete.handleOpen();
    };
    const handleOpenConfirmBulksDelete = () => {
        stateDialogBulksDelete.handleOpen();
    };

    const handleDelete = () => {
        //callApi
        // permitController.deletePermit([deleteId!]).then((res) => {
        //     // toast.error(t('notification:fail'));
        //     //popup
        //     // toast.success(t('notification:successfully'));
        // setPartialState({deleteId:null});
        //     //reload when delete done
        //     // reloadPermitsData();
        // });
        //closeDialog
        stateDialogDelete.handleClose();
    };

    const handleBulksDelete = () => {
        //callApi
        // permitController.deletePermit(deleteIds).then((res) => {
        //     //popup
        //     toast.success(t('notification:successfully'));
        //     setDeleteIds([]);
        //     //reload
        //     reloadPermitsData();
        // });
        //closeDialog
        stateDialogBulksDelete.handleClose();
    };

    const handleChangePagination = (event: React.ChangeEvent<unknown>, value: number) => {
        setPartialFilter({ page: value });
    };

    return {
        ...state,
        setState,
        onCheckedAll,
        handleChangeChecked,
        checkIdExists,
        handleBulksDelete,
        handleDelete,
        handleOpenConfirmBulksDelete,
        handleOpenConfirmDelete,
        isCheckedAll,
        filter,
        setFilter,
        setPartialFilter,
        filterData,
        setPartialFilterData,
        handleChangePagination,
    };
}

export const VehicleListContext = React.createContext<ReturnType<typeof useVehicleList>>({} as any);

export interface BaseSelect {
    name: string;
    id: number;
    desc?: string;
}
