export interface ColorConfig {
    primary: string
    secondary: string

    textPrimary: string
    textSecondary: string
    priText: string

    lightPrimary: string
    darkPrimary: string
    lightSecondary: string
    darkSecondary: string

    danger: string
    darkDanger: string

    warning: string
    success: string
    error: string

    appBarColor: string,

    white: '#FFFFFF'
    grey100: '#FAFAFA'
    grey200: '#EEEEEE'
    grey300: '#DDDDDD'
    grey400: '#C5C5C5'
    grey600: '#85858A'

    outlinedButton: string,
    containedButtonBg: string,
    containedButtonString: string,
    pink: '#E81F76'
}

const color: ColorConfig = {
    primary: "#3EB6E4",
    secondary: "#007BFF",

    textPrimary: '#000000',
    textSecondary: '#101010',
    priText: "#1A2B6D",

    lightPrimary: "#E6F2F9",
    darkPrimary: "#1A428A",
    lightSecondary: "#E8F5E9",
    darkSecondary: "#339790",

    danger: '#E01B00',
    darkDanger: '#BE1700',

    warning: '#3F95E7',
    success: '#009D4F',
    error: '#E9645B',

    appBarColor: "#1A2B6D",

    white: '#FFFFFF',
    grey100: '#FAFAFA',
    grey200: '#EEEEEE',
    grey300: '#DDDDDD',
    grey400: '#C5C5C5',
    grey600: '#85858A',

    outlinedButton: "#3EB6E4",
    containedButtonBg: "#3EB6E4",
    containedButtonString: "#FFFFFF",
    pink: '#E81F76'
}


export default color;
