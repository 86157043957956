/* eslint-disable eqeqeq */
import { Box, FormControlLabel, Grid, Radio, RadioGroup, TextField, Theme, Tooltip, Typography } from '@mui/material';
import { makeStyles } from '@mui/styles';
import { DatePicker, LocalizationProvider, PickersDay } from '@mui/x-date-pickers';
import { AdapterDateFns } from '@mui/x-date-pickers/AdapterDateFns';
import { addDays } from 'date-fns';
import { useContext } from 'react';
import { Controller, useFormContext } from 'react-hook-form';
import CalendarIcon from '../../../../components/svg/CalendarIcon';
import LabelWithRequired from '../../../../components/textfield/LabelWithRequired';
import color from '../../../../config/Colors';
import { ValidateToType } from '../../../../models/permit/Permit';
import { RecurringType } from '../../../../models/permit/PermitRecurring';
import { VehicleListUpsertContext } from '../../hooks/useVehicleListUpsert';
import { FormData } from '../model/UpsertVehicleList';
import { DurationProps } from './InternalPermitTimeDuration';
export const useStylesPicker = makeStyles((theme: Theme) => ({
    inputStyle: {
        '& .Mui-selected': {
            backgroundColor: `${color.primary} !important`,
        },
    },
}));

export default function InternalPermitDateRange(props: DurationProps) {
    const {
        formState: { errors },
        control,
        watch,
        setValue,
        clearErrors,
    } = useFormContext<FormData>();
    const { internalPermitData } = watch();

    const { validateFrom, recurringType, endStatus, validateTo, endAfterValue } = internalPermitData;
    const {
        editVehicleListData: { vehicleListEdit },
    } = useContext(VehicleListUpsertContext);

    const { permits } = vehicleListEdit;
    const classes = useStylesPicker();

    const isDisableEndAfter = () => {
        if (!props.isEdit) return false;
        const permit = permits![0];
        if ([ValidateToType.absoluteAt, ValidateToType.infinite].includes(permit.validateToType!)) return true;

        return false;
    };

    const isDisableEditingRecurTime = () => {
        if (!props.isEdit) return false;
        const permit = permits![0];
        if ([ValidateToType.absoluteAt, ValidateToType.infinite].includes(permit.validateToType!)) return true;
        if (vehicleListEdit.isExpired) return true;
        return false;
    };

    return (
        <LocalizationProvider dateAdapter={AdapterDateFns}>
            <Grid
                container
                item
                xs={12}
                // alignItems="center"
                // spacing={2}
                display={recurringType === Number(RecurringType.once) ? 'none' : 'flex'}
                justifyContent={'space-between'}
                alignItems="center"
                direction="row"
                mt={1}
            >
                <Grid xs={9} item>
                    {recurringType === Number(RecurringType.once) ||
                    recurringType === Number(RecurringType.indefinite) ? (
                        ''
                    ) : (
                        <Controller
                            name="internalPermitData.endStatus"
                            control={control}
                            render={({ field }) => {
                                return (
                                    <RadioGroup
                                        aria-labelledby="demo-radio-buttons-group-label"
                                        value={endStatus}
                                        name="radio-buttons-group"
                                        onChange={(e: any, val) => {
                                            const validTo = val != '0' ? addDays(validateFrom!, 1) : null;
                                            // setFieldTouched('validateTo');
                                            field.onChange(Number(val));
                                            setValue('internalPermitData.validateTo', validTo, { shouldTouch: true });
                                            if (Number(val) !== 0) {
                                                clearErrors('internalPermitData.validateTo');
                                            }
                                            if (props.isEdit && val !== ValidateToType.infinite.toString()) {
                                            }
                                        }}
                                        color={color.success}
                                        sx={{
                                            '& .MuiButtonBase-root': { padding: '5px' },
                                            '& .MuiFormControlLabel-root': {
                                                ml: 0,
                                            },
                                        }}
                                    >
                                        <Grid
                                            container
                                            item
                                            spacing={2}
                                            display={'flex'}
                                            direction="row"
                                            alignItems={'baseline'}
                                        >
                                            <Grid
                                                item
                                                container
                                                display={'flex'}
                                                direction={'row'}
                                                alignItems="center"
                                                xs={4.5}
                                                spacing={1}
                                                // mt={{ xs: 1, sm: 0 }}
                                            >
                                                <Grid xs={'auto'} item>
                                                    <FormControlLabel
                                                        sx={{
                                                            '&.MuiFormControlLabel-labelPlacementEnd': {
                                                                marginRight: '0px !important',
                                                            },
                                                        }}
                                                        value={ValidateToType.absoluteAt}
                                                        disabled={validateFrom === null}
                                                        control={<Radio />}
                                                        label={<Typography variant="body1">{'End date'}</Typography>}
                                                    />
                                                </Grid>
                                                <Grid item xs>
                                                    <DatePicker
                                                        label={
                                                            <LabelWithRequired
                                                                label={'End date'}
                                                                isShowRequired={true}
                                                            />
                                                        }
                                                        value={endStatus != 0 ? null : validateTo}
                                                        onChange={(newValue) => {
                                                            if (newValue != null) {
                                                                // setFieldTouched('validateTo');
                                                                setValue('internalPermitData.validateTo', newValue, {
                                                                    shouldTouch: true,
                                                                    shouldValidate: true,
                                                                });
                                                            }
                                                        }}
                                                        disabled={
                                                            endStatus != ValidateToType.absoluteAt ||
                                                            validateFrom == null
                                                        }
                                                        components={{
                                                            OpenPickerIcon: CalendarIcon,
                                                        }}
                                                        inputFormat="dd/MM/yyyy"
                                                        minDate={validateFrom}
                                                        renderInput={(params) => (
                                                            <TextField
                                                                {...params}
                                                                error={Boolean(errors.internalPermitData?.validateTo)}
                                                                helperText={
                                                                    errors.internalPermitData?.validateTo?.message
                                                                }
                                                                sx={{
                                                                    '& .MuiInputBase-input': {
                                                                        caretColor: 'transparent',
                                                                    },
                                                                    // maxWidth: '180px',
                                                                }}
                                                                onKeyDown={(e) => {
                                                                    e.preventDefault();
                                                                }}
                                                                fullWidth={true}
                                                            />
                                                        )}
                                                        renderDay={(day, _value, DayComponentProps) => {
                                                            return (
                                                                <Box
                                                                    className={classes.inputStyle}
                                                                    key={JSON.stringify(day)}
                                                                >
                                                                    <PickersDay {...DayComponentProps} />
                                                                </Box>
                                                            );
                                                        }}
                                                        PopperProps={{
                                                            placement: 'bottom-start',
                                                        }}
                                                    />
                                                </Grid>
                                            </Grid>

                                            <Grid
                                                display={'flex'}
                                                direction={'row'}
                                                alignItems="center"
                                                item
                                                container
                                                xs={4.5}
                                                mt={{
                                                    xs: 1,
                                                    md: 0,
                                                }}
                                                spacing={1}
                                            >
                                                <Grid xs={'auto'} item>
                                                    <FormControlLabel
                                                        sx={{
                                                            '&.MuiFormControlLabel-labelPlacementEnd': {
                                                                marginRight: '0px !important',
                                                            },
                                                        }}
                                                        value={ValidateToType.recurringTime}
                                                        control={<Radio />}
                                                        disabled={
                                                            props.isEdit ? isDisableEndAfter() : validateFrom === null
                                                        }
                                                        label={
                                                            <Typography
                                                                variant="body1"
                                                                sx={{
                                                                    color: isDisableEndAfter() ? color.grey600 : '',
                                                                }}
                                                            >
                                                                {'End after'}
                                                            </Typography>
                                                        }
                                                    />
                                                </Grid>

                                                <Grid item xs>
                                                    <TextField
                                                        disabled={
                                                            props.isEdit
                                                                ? isDisableEditingRecurTime()
                                                                : endStatus != ValidateToType.recurringTime
                                                        }
                                                        type={'number'}
                                                        inputProps={{ min: 1, max: 9999 }}
                                                        value={endAfterValue}
                                                        onChange={(e) => {
                                                            const value = Number(e.target!.value);
                                                            const val = value < 1 ? 1 : value > 9999 ? 9999 : value;
                                                            setValue('internalPermitData.endAfterValue', val);
                                                        }}
                                                        label={
                                                            <Tooltip
                                                                title="This is the number of times the set parameters will recur. For example, if a permit has been set to recur all day on Mondays, Tuesdays & Wednesdays and number of occurrences has been set to 3, this means that the permits will only be active all day on Monday, Tuesday and Wednesday from the start date for only week 1, week 2 and week 3. The permit will expire after week 3."
                                                                placement="top"
                                                                arrow
                                                            >
                                                                <span>
                                                                    <LabelWithRequired
                                                                        label={'Number of occurrences'}
                                                                        isShowRequired={true}
                                                                    />
                                                                </span>
                                                            </Tooltip>
                                                        }
                                                        sx={{ minWidth: '120px' }}
                                                        fullWidth
                                                        size="small"
                                                    />
                                                </Grid>
                                            </Grid>
                                            <Grid xs={3} item>
                                                <FormControlLabel
                                                    disabled={validateFrom === null}
                                                    value={ValidateToType.infinite}
                                                    control={<Radio />}
                                                    label={'No end date'}
                                                    // sx={{ paddingTop: 1 }}
                                                />
                                            </Grid>
                                        </Grid>
                                    </RadioGroup>
                                );
                            }}
                        />
                    )}
                </Grid>
            </Grid>
        </LocalizationProvider>
    );
}
