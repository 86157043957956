import { Box } from '@mui/material';
import React from 'react';
import color from '../config/Colors';

type Props = {
    text?: string;
};
export default function WarningAddAtLeast({ text }: Props) {
    return (
        <Box
            sx={{
                background: 'rgba(224, 27, 0, 0.1)',
                p: '8px 0',
                textAlign: 'center',
                color: color.danger,
                width: '100%',
                borderRadius: '3px',
            }}
        >
            {text}
        </Box>
    );
}
